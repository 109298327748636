import React, { FC } from 'react';
import { Modal } from 'react-bootstrap';
import { KTSVG } from '../../../../_metronic/helpers';

interface InfoLinkModalProps {
  isOpenTerms: boolean;
  setIsOpenTerms: (isOpen: boolean) => void;
  isOpenPlans: boolean;
  setIsOpenPlans: (isOpen: boolean) => void;
  isOpenContactUs: boolean;
  setIsOpenContactUs: (isOpen: boolean) => void;
}

const InfoLinkModal: FC<InfoLinkModalProps> = ({
  isOpenTerms,
  setIsOpenTerms,
  isOpenPlans,
  setIsOpenPlans,
  isOpenContactUs,
  setIsOpenContactUs,
}) => {
  return (
    <>
      {/* Modal for Terms */}
      <Modal aria-hidden='true' show={isOpenTerms} scrollable={true} animation={true} centered>
        <div className='modal-content'>
          <div className='modal-header pb-0 border-0 justify-content-end'>
            <div
              className='btn btn-sm btn-icon btn-active-color-primary'
              onClick={() => setIsOpenTerms(false)}
            >
              <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
            </div>
          </div>
          <div className='modal-body scroll-y mx-5 mx-xl-8 pt-0 pb-15'>
            <div className='text-center mb-0'>
              <div className='availableTpUpr'>
                <div className='availableTp'>
                  <h1 className='text-center'>Terms</h1>
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit...</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      {/* Modal for Plans */}
      
      <Modal aria-hidden='true' show={isOpenPlans} scrollable={true} animation={true} centered>
        <div className='modal-content'>
          <div className='modal-header pb-0 border-0 justify-content-end'>
            <div
              className='btn btn-sm btn-icon btn-active-color-primary'
              onClick={() => setIsOpenPlans(false)}
            >
              <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
            </div>
          </div>
          <div className='modal-body scroll-y mx-5 mx-xl-8 pt-0 pb-15'>
            <div className='text-center mb-0'>
              <div className='availableTpUpr'>
                <div className='availableTp'>
                  <h1 className='text-center'>Plans</h1>
                  <table className='table table-bordered'>
                    <thead className='text-center'>
                      <tr>
                        <th style={{ color: '#148584' }}>Silver</th>
                        <th style={{ color: '#148584' }}>Gold</th>
                        <th style={{ color: '#148584' }}>Diamond</th>
                      </tr>
                    </thead>
                    <tbody className='text-center'>
                      <tr>
                        <td>
                          <ul className='list-unstyled'>
                            <li>$20/Month Or $200/Year</li>
                          </ul>
                        </td>
                        <td>
                          <ul className='list-unstyled'>
                            <li>$30/Month Or $300/Year</li>
                          </ul>
                        </td>
                        <td>
                          <ul className='list-unstyled'>
                            <li>$90/Month Or $900/Year</li>
                          </ul>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      {/* Modal for Contact us */}
      <Modal aria-hidden='true' show={isOpenContactUs} scrollable={true} animation={true} centered>
        <div className='modal-content'>
          <div className='modal-header pb-0 border-0 justify-content-end'>
            <div
              className='btn btn-sm btn-icon btn-active-color-primary'
              onClick={() => setIsOpenContactUs(false)}
            >
              <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
            </div>
          </div>
          <div className='modal-body scroll-y mx-5 mx-xl-8 pt-0 pb-15'>
            <div className='text-center mb-0'>
              <div className='availableTpUpr'>
                <div className='availableTp'>
                  <h1 className='text-center'>Contact Us</h1>
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit...</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export { InfoLinkModal };
