/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useEffect} from 'react'
import {KTSVG} from '../../../helpers'
import {ThemeModeSwitcher} from '../../../partials'
import {useNavigate, useLocation} from 'react-router-dom'
import {
  getUserByToken,
  login,
  getfolderprojects,
  lastactive,
  addaccount,
} from '../../../../app/modules/auth/core/_requests'
import {useAuth} from '../../../../app/modules/auth/core/Auth'
import {UserModel} from '../../../../app/modules/auth'
import {Modal} from 'react-bootstrap'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'

const addaccountSchema = Yup.object().shape({
  company_name: Yup.string().required('Company name is required'),
})

const initialValues = {
  company_name: '',
}

const Topbar: React.FC = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const {saveAuth, currentUser, setCurrentUser, setfolder, auth, logout} = useAuth()
  const [selectuser, setselectuser] = useState<UserModel[]>([])
  const [companyType, setCompanyType] = useState('individual')
  const [loading, setLoading] = useState(false)

  const [isOpenRename, setIsOpenRename] = useState(false)
  const [addAccount, setAddAccount] = useState(false)

  const queryParams = new URLSearchParams(location.search)
  var project_infoid = queryParams.get('project_infoid')

  useEffect(() => {
    fetchUser()
  }, [])

  const formik = useFormik({
    initialValues,
    validationSchema: addaccountSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      console.log('values', values)
      setLoading(true)
      try {
        const tk = localStorage.getItem('kt-auth-react-v')

        const data = await addaccount(tk || '', values.company_name)
        if (data.data.hassucceeded === true) {
          if (auth && auth.api_token) {
            const api_token = auth.api_token // Store the value in a separate variable
            const updatedAuth = {
              api_token,
              company_name: data.data.data[0].company_name,
            }
            saveAuth(updatedAuth)
          }
          setCurrentUser(data.data.data[0])
          try {
            const {data: folder} = await getfolderprojects(data.data.data[0].company_name || '')
            setfolder(folder)
            navigate(`/dashboard`)
            window.location.reload()

            // setIsModalOpen(true);
          } catch (error) {
            console.error('Error fetching folder projects:', error)
          }
        } else {
          setStatus(data.data.message) // Throw an error to trigger the catch block
          setLoading(false)
        }
      } catch (error) {
        console.error(error)
        saveAuth(undefined)
        setStatus('The login detail is incorrect')
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  const handleCompanyTypeChange = (event: any) => {
    if (event.target.value == 'company') {
      formik.setFieldValue('company_name', '')
    }
    if (event.target.value == 'individual') {
      if (currentUser && currentUser.email) {
        formik.setFieldValue('company_name', currentUser.email || '')
      }
    }
    setCompanyType(event.target.value)
  }

  const fetchUser = async () => {
    if (auth && auth.api_token) {
      try {
        const response = await getUserByToken(auth.api_token)
        let data: UserModel[] = []
        if (Array.isArray(response.data)) {
          data = response.data // If response data is already an array
        } else {
          data = [response.data] // Wrap the single object in an array
        }
        setselectuser(data)
      } catch (error) {
        console.error('Error fetching user:', error)
      }
    }
  }
  const handleAccountClick = async (account: UserModel) => {
    if (auth && auth.api_token) {
      const msg: any = await lastactive(auth.api_token, account.teamid)
      if (msg.data.hassucceeded === true) {
        const api_token = auth.api_token // Store the value in a separate variable
        const updatedAuth = {
          api_token,
          company_name: account.company_name,
        }
        saveAuth(updatedAuth)
      }
    }
    setCurrentUser(account)
    try {
      const {data: folder} = await getfolderprojects(account.company_name || '')
      setfolder(folder)
      navigate(`/dashboard`)
      window.location.reload()
      // setIsModalOpen(true);
    } catch (error) {
      console.error('Error fetching folder projects:', error)
    }
  }

  const handleAddAccount = async (currentUser: any) => {
    setAddAccount(true)
    formik.setFieldValue('company_name', currentUser.email || '')
  }

  return (
    <>
      <div className='d-flex flex-shrink-0'>
        {/* begin::Invite user */}
        {project_infoid ? (
          ' '
        ) : (
          <>
            {/* <div className='d-flex ms-3'>
              <a
                href='#'
                className='btn btn-flex flex-center bg-body btn-color-gray-700 btn-active-color-primary w-40px w-md-auto h-40px px-0 px-md-6'
                // data-bs-toggle='modal'
                // data-bs-target='#kt_modal_invite_friends'
              >
                <i className='bi-folder-plus svg-icon-2 svg-icon-primary me-0 me-md-2 fs18'></i>
                <span className='d-none d-md-inline'>Create Folder</span>
              </a>
            </div> */}

            {/* end::Invite user */}

            {/* begin::Create app */}
            {/* <div className='d-flex ms-3'>
              <a
                href='#'
                className='btn btn-flex flex-center bg-body btn-color-gray-700 btn-active-color-primary w-40px w-md-auto h-40px px-0 px-md-6'
                id='kt_toolbar_primary_button'
                data-bs-toggle='modal'
                data-bs-target='#kt_modal_create_app'
              >
                <i className='bi-plus-circle-fill svg-icon-2 svg-icon-primary me-0 me-md-2 fs18'></i>
                <span className='d-none d-md-inline'>Create Project</span>
              </a>
            </div> */}
          </>
        )}
        {/* begin::Theme mode */}
        <div className='d-flex align-items-center  ms-3'>
          <ThemeModeSwitcher toggleBtnClass=' flex-center bg-body btn-color-gray-600 btn-active-color-primary h-40px' />
        </div>
        {/* end::Theme mode */}

        <>
          {/* <button
              className="dropdown-item"
              onClick={() => handleAccountClick(account)}
            >
              {account.company_name}
            </button> */}

          <div className='me-0'>
            <button
              className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary ms-3 fs16 bg-body btn-color-gray-700'
              data-kt-menu-trigger='click'
              data-kt-menu-placement='bottom-end'
            >
              <i className='fa-solid fa-user'></i>
            </button>

            <div
              className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold w-200px py-3 alldashdrop'
              data-kt-menu='true'
            >
              {Array.isArray(selectuser) &&
                selectuser.map((account: UserModel) => (
                  <div className='menu-item px-3 cursor-pointer' key={account.company_name}>
                    <div
                      className={`menu-content fw-bold pb-2 px-3 fs-6 ${
                        account.team_status ? 'account_selected' : ''
                      }`}
                      onClick={() => handleAccountClick(account)}
                    >
                      {account.company_name}
                    </div>
                  </div>
                ))}

              <div className='menu-item px-3' onClick={() => handleAddAccount(currentUser)}>
                <a href='#' className='menu-link px-3 dreopdownIcon'>
                  <i
                    className='bi-person-plus-fill'
                    data-bs-toggle='tooltip'
                    title='Specify a target name for future usage and reference'
                  ></i>
                  <span className='px-4'>Add another account</span>
                </a>
              </div>

              <div className='menu-item px-3'>
                <a href='#' className='menu-link px-3 dreopdownIcon'>
                  <i
                    className='fa-sharp fa-solid fa-house'
                    data-bs-toggle='tooltip'
                    title='Specify a target name for future usage and reference'
                  ></i>
                  <span className='px-4'>My Account</span>
                </a>
              </div>

              <div className='menu-item px-3'>
                <a href='#' className='menu-link px-3 dreopdownIcon1'>
                  <i
                    className='fa-sharp fa-solid fa-building'
                    data-bs-toggle='tooltip'
                    title='Specify a target name for future usage and reference'
                  ></i>
                  <span className='px-5'>Workspace Setting</span>
                </a>
              </div>

            
              <div className='menu-item px-3' onClick={() => {navigate(`/pricing`)}}>
                <a href='#' className='menu-link px-3 dreopdownIcon1'>
                  <i
                    className='fa-sharp fa-solid fa-shield-halved'
                    data-bs-toggle='tooltip'
                    title='Specify a target name for future usage and reference'
                  ></i>
                  <span className='px-4'>Pricing</span>
                </a>
              </div>

              <div className='PriceDropdown'></div>

              <div className='menu-item px-3'>
                <a href='#' className='menu-link px-3 dreopdownIcon1'>
                  <i
                    className='fa-sharp fa-solid fa-arrow-right-from-bracket'
                    data-bs-toggle='tooltip'
                    title='Specify a target name for future usage and reference'
                  ></i>
                  <span className='px-4' onClick={logout}>
                    Logout
                  </span>
                </a>
              </div>
            </div>
          </div>
        </>
        {/* CHAT */}
        <div className='d-flex align-items-center ms-3'>
          {/* begin::Menu wrapper */}
          <div
            className='btn btn-icon btn-primary w-40px h-40px pulse pulse-white'
            id='kt_drawer_chat_toggle'
          >
            <KTSVG path='/media/icons/duotune/communication/com012.svg' className='svg-icon-2' />
            <span className='pulse-ring' />
          </div>
          {/* end::Menu wrapper */}
        </div>

        <Modal aria-hidden='true' show={addAccount} scrollable={true} animation={true} centered>
          <div className='mw-650px modal-dialog-centered'>
            <div className='modal-content'>
              <div className='modal-header pb-0 border-0 justify-content-end'>
                <div
                  className='btn btn-sm btn-icon btn-active-color-primary'
                  onClick={() => setAddAccount(false)}
                >
                  <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
                </div>
              </div>

              <div className='modal-body scroll-y mx-xl-8 pt-0'>
                <div className='renametxt mb-3'>
                  <h1 className='mb-3'>Add Another Account</h1>
                  <h6 className='text-muted fw-bold fs-5'>
                    Please add another account here, Here's a short description for another account.
                  </h6>
                </div>
                <form noValidate onSubmit={formik.handleSubmit}>
                  {formik.status && (
                    <div className='mb-lg-15 alert alert-success'>
                      <div className='alert-text font-weight-bold'>{formik.status}</div>
                    </div>
                  )}
                  <div className='fv-row mb-8 mt-10'>
                    <div className='row allradiobtn'>
                      <div className='col-5 col-sm-4 col-md-4 col-lg-3'>
                        <label className='indradiosection text-gray-500'>
                          <input
                            type='radio'
                            value='individual'
                            checked={companyType === 'individual'}
                            onChange={handleCompanyTypeChange}
                          />
                          Individual
                        </label>
                      </div>
                      <div className='col-5 col-sm-4 col-md-4 col-lg-3'>
                        <label className='indradiosection text-gray-500'>
                          <input
                            type='radio'
                            value='company'
                            checked={companyType === 'company'}
                            onChange={handleCompanyTypeChange}
                          />
                          Company
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className='fv-row mb-8'>
                    <div className='mb-5'>
                      {companyType === 'individual' ? null : (
                        <>
                          <input
                            placeholder='Company_name'
                            type='text'
                            autoComplete='off'
                            {...formik.getFieldProps('company_name')}
                            value={formik.values.company_name}
                            className={clsx(
                              'form-control bg-transparent',
                              {
                                'is-invalid':
                                  formik.touched.company_name && formik.errors.company_name,
                              },
                              {
                                'is-valid':
                                  formik.touched.company_name && !formik.errors.company_name,
                              }
                            )}
                          />
                          {formik.touched.company_name && formik.errors.company_name && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>
                                <span role='alert'>{formik.errors.company_name}</span>
                              </div>
                            </div>
                          )}
                        </>
                      )}
                    </div>

                    <div className='d-grid'>
                      <button
                        type='submit'
                        id='kt_sign_up_submit'
                        className='btn btn-lg btn-primary w-100'
                        // disabled={!formik.values.name || !formik.values.phone || !formik.values.company_name || !formik.values.email || !formik.values.password || !formik.values.acceptTerms}
                        // onClick={() => handleRegisterValidation(formik.values.email, formik.values.phone, formik.values.company_name)}
                      >
                        {!loading && <span className='indicator-label'>Submit</span>}
                        {loading && (
                          <span className='indicator-progress' style={{display: 'block'}}>
                            Please wait...{' '}
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                          </span>
                        )}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </>
  )
}
export {Topbar}
