import { useEffect, useState, useRef } from 'react'
import clsx from 'clsx'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useIntl } from 'react-intl'
import { PageLink, PageTitle } from '../../../_metronic/layout/core'
import { KTSVG, toAbsoluteUrl } from '../../../_metronic/helpers'
import { Dropdown1 } from '../../../_metronic/partials'
import { Modal } from 'react-bootstrap'
import { useNavigate, useLocation } from 'react-router-dom'
import { useAuth } from '../../../app/modules/auth/core/Auth'
import {
  getUserByToken,
  login,
  createproject,
  getfolderprojects,
  delrenamefolderproject,
} from '../../../app/modules/auth/core/_requests'
import { CardListOption } from '../../../_metronic/partials/content/dropdown/CardListOption'
import { CreateFolder } from '../../../_metronic/partials/modals/create-folder/CreateFolder'
import Pagination from '@mui/material/Pagination'

const dashboardBreadCrumbs: Array<PageLink> = [
  {
    title: 'Home',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
]

const CPSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Name is required'),
})

const initialValues = {
  name: '',
  folderid: '',
}

const DashboardPage = () => {
  const [isOpenRename, setIsOpenRename] = useState(false)
  const [isOpenDelete, setIsOpenDelete] = useState(false)
  const [folderRenameValue, setfolderRenameValue] = useState(false)
  const [projectRenameValue, setprojectRenameValue] = useState(false)
  const [newName, setnewName] = useState('')
  const [folderDeleteValue, setfolderDeleteValue] = useState(false)
  const [projectDeleteValue, setprojectDeleteValue] = useState(false)
  const [loading, setLoading] = useState(false)
  const { saveAuth, currentUser, folder, setfolder } = useAuth()
  const [createProj, setCreateProj] = useState<boolean>(false)
  const [createFolder, setCreateFolder] = useState<boolean>(false)
  const [folderName, setFolderName] = useState<boolean>(false)
  const [tabs, setTabs] = useState<number>(0)
  const [selectService, setSelectService] = useState<string>()
  const [comesfrom, setcomesfrom] = useState<string>('')

  const [hidecreatefolder, sethidecreatefolder] = useState<boolean>(false)
  const [matchingFolder, setmatchingFolder] = useState<any[]>([])
  const [permission, setPermission] = useState<String>('Select Folder')
  const navigate = useNavigate()
  const location = useLocation()

  interface mainvalue {
    value: boolean
  }

  const formik = useFormik({
    initialValues,
    validationSchema: CPSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      try {
        const tk = localStorage.getItem('kt-auth-react-v')
        const { data: msg } = await createproject(
          values.name,
          tk || '',
          values.folderid ? values.folderid : matchingFolder[0]?.folderid
        )
        const { data: folder } = await getfolderprojects(currentUser?.company_name || '')
        setfolder(folder)
        if (msg.hassucceeded == true) {
          setStatus(msg.message)
          setSubmitting(false)
          setLoading(false)
          setTimeout(() => {
            navigate(`/workspace/?project_infoid=${msg.project_infoid}`)
          }, 1000)
        } else {
          setStatus(msg.message)
          setSubmitting(false)
          setLoading(false)
        }
      } catch (error) {
        console.error(error)
        saveAuth(undefined)
        setStatus('The detail is incorrect')
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search)
    const folderId = queryParams.get('folderid')
    if (folderId) {
      const filteredData: any = folder?.folderProject?.filter(
        (obj: any) => obj.folderid === folderId
      )
      setmatchingFolder(filteredData)
      setSelection(filteredData[0]?.folderid)
      setIsOpen(false)
      sethidecreatefolder(true)
    }

    // We have to show toolbar only for dashboard page
    document.getElementById('kt_layout_toolbar')?.classList.remove('d-none')
    return () => {
      document.getElementById('kt_layout_toolbar')?.classList.add('d-none')
    }
  }, [location.search])

  const selectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value
    setPermission(value)
  }

  const handleOpen = (folderId: any, projectId: any) => {
    if (folderId) {
      navigate(`/ProjectSpace/?folderid=${folderId}`)
    } else if (projectId) {
      const filteredData: any = folder?.AllProjectInfoData?.filter(
        (obj: any) => obj.projectid === projectId
      )
      navigate(`/workspace/?project_infoid=${filteredData[0]?.project_infoid}`)
    }
  }

  const handlesetIsOpenRename = (folderId: any, projectId: any, name: any) => {
    if (folderId) {
      setIsOpenRename(true)
      setfolderRenameValue(folderId)
      setnewName(name)
    } else if (projectId) {
      setIsOpenRename(true)
      setprojectRenameValue(projectId)
      setnewName(name)
    }
  }

  const handlesetIsOpenDelete = (folderId: any, projectId: any) => {
    if (folderId) {
      setIsOpenDelete(true)
      setfolderDeleteValue(folderId)
    } else if (projectId) {
      setIsOpenDelete(true)
      setprojectDeleteValue(projectId)
    }
  }

  const delrenamefolproject = async (folderId: any, projectId: any, name: any, purpose: any) => {
    try {
      const { data: msg } = await delrenamefolderproject(folderId, projectId, name, purpose)
      const { data: folder } = await getfolderprojects(currentUser?.company_name || '')
      setfolder(folder)
      setIsOpenDelete(false)
      setIsOpenRename(false)
    } catch (error) {
      // Handle any errors that occurred during the API requests
      console.error('Error:', error)
      // Add your error handling code here
    }
  }

  const inputRef = useRef<HTMLInputElement>(null)

  const [isOpen, setIsOpen] = useState(false)
  const [searchText, setSearchText] = useState('')
  const [selection, setSelection] = useState<number | null>(null)

  const handleToggle = () => {
    setIsOpen(!isOpen)
  }

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (inputRef.current && !inputRef.current.contains(event.target)) {
        setIsOpen(false)
      }
    }

    document.addEventListener('click', handleClickOutside)
    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [])

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value)
  }

  const handleItemClick = (folderid: any) => {
    formik.setFieldValue('folderid', folderid)
    setSelection(folderid)
    setIsOpen(false)
    setSearchText('')
  }

  const filteredOptions =
    folder?.folderProject?.filter((folderData: any) =>
      folderData.name.includes(searchText.trim())
    ) ?? []

  const handlecreatefolder = () => {
    setCreateFolder(!createFolder)
    setcomesfrom('newProject')
  }

  const handlewithoutfolder = () => {
    formik.setFieldValue('folderid', '')
    setSelection(null)
    setIsOpen(false)
  }


  const [currentPage, setCurrentPage] = useState(1);
  const [projectsPerPage] = useState(12);
  const [searchTerm, setSearchTerm] = useState('');

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const handleSearchChange2 = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
    setCurrentPage(1); // Reset to first page when search term changes
  };

  const indexOfLastProject = currentPage * projectsPerPage;
  const indexOfFirstProject = indexOfLastProject - projectsPerPage;
  const filteredProjects = (folder?.project || [])
    .filter((projectData: any) =>
      projectData.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
  console.log("filteredProjects", filteredProjects.length)


  const currentProjects = filteredProjects.slice(indexOfFirstProject, indexOfLastProject);

  const pageNumbers = Math.ceil(filteredProjects.length / projectsPerPage);

  const pagination = Array.from({ length: pageNumbers }, (_, index) => index + 1);

  const [currentPageFolders, setCurrentPageFolders] = useState(1);
  const [foldersPerPage] = useState(12);
  const [searchTermFolders, setSearchTermFolders] = useState('');

  const handlePageChangeFolders = (pageNumber: number) => {
    setCurrentPageFolders(pageNumber);
  };

  const handleSearchChange1 = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTermFolders(event.target.value);
    setCurrentPageFolders(1); // Reset to first page when search term changes
  };

  const indexOfLastFolder = currentPageFolders * foldersPerPage;
  const indexOfFirstFolder = indexOfLastFolder - foldersPerPage;

  const filteredFolders = (folder?.folderProject || [])
    .filter((folderData: any) =>
      folderData.name.toLowerCase().includes(searchTermFolders.toLowerCase())
    )
    const currentFolders =  filteredFolders.slice(indexOfFirstFolder, indexOfLastFolder);

  const pageNumbersFolders = Math.ceil(
    filteredFolders.length / foldersPerPage
  );

  const paginationFolders = Array.from({ length: pageNumbersFolders }, (_, index) => index + 1);


  return (
    <>
      <div>
        <div>
          {hidecreatefolder && <h1 className='emp_f m-5'>You're on an empty folder</h1>}
          <div className='card'>
            <div className='card-body'>
              <div className='getStartedtitle'>
                <p>Getting Started</p>
              </div>
              <div className='row' data-kt-menu-dismiss='true'>
                <div className='col-sm-12 col-md-4 col-lg-3'>
                  <button
                    onClick={() => setCreateProj(true)}
                    type='button'
                    className='btn btn-primary fw-bold px-6 w mt-5'
                  >
                    <div className='createProjectBtn mb-2'>
                      <i className='bi-plus-circle-fill fs28 mb-3 mt-3'></i>
                      <div className='fs18'>Create Project</div>
                      <div className='fs14 fw400 mt-1'>
                        You are just few minutes away from creating a stunning voiceover.
                      </div>
                    </div>
                  </button>
                </div>
                <div className='col-sm-12 col-md-4 col-lg-3'>
                  {hidecreatefolder ? (
                    <button
                      type='button'
                      disabled
                      style={{ background: 'slategray' }}
                      className='btn btn-primary fw-bold px-6 w mt-5'
                      data-kt-menu-dismiss='true'
                      data-kt-user-table-filter='filter'
                      onClick={() => setCreateFolder(!createFolder)}
                    >
                      <div className='createProjectBtn mb-2'>
                        <i className='bi-folder-plus fs28 mb-3 mt-3'></i>
                        <div className='fs18'>Create Folder</div>
                        <div className='fs14 fw400 mt-1'>
                          Start by creating a folder. Folders are great way to organise your work.
                        </div>
                      </div>
                    </button>
                  ) : (
                    <button
                      type='button'
                      className='btn btn-primary fw-bold px-6 w mt-5'
                      data-kt-menu-dismiss='true'
                      data-kt-user-table-filter='filter'
                      onClick={() => setCreateFolder(!createFolder)}
                    >
                      <div className='createProjectBtn mb-2'>
                        <i className='bi-folder-plus fs28 mb-3 mt-3'></i>
                        <div className='fs18'>Create Folder</div>
                        <div className='fs14 fw400 mt-1'>
                          Start by creating a folder. Folders are great way to organise your work.
                        </div>
                      </div>
                    </button>
                  )}
                </div>
                <div className='col-sm-12 col-md-4 col-lg-3'>
                  <button
                    type='button'
                    className='btn btn-primary fw-bold px-6 w mt-5'
                    data-bs-toggle='modal'
                    data-bs-target='#kt_modal_invite_friends'
                  >
                    <div className='createProjectBtn mb-2'>
                      <i className='bi-person-plus-fill fs28 mb-3 mt-3'></i>
                      <div className='fs18'>Invite Teammates</div>
                      <div className='fs14 fw400 mt-1'>
                        Add teammates to your workspace for faster collaboration & feedback
                      </div>
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='mt-8 mb-5' />

        {hidecreatefolder == false ? (
          <div>
            <div className='m-0 mb-12'>
              <div
                className='d-flex align-items-center collapsible toggle collapsed mb-0 btn-primary'
                data-bs-toggle='collapse'
                data-bs-target='#kt'
              >
                <div className='card w-100 px-8 py-4'>
                  <div className='dashboardPrjctSec'>
                    <div className='btn btn-sm btn-icon mw-20px btn-active-color-dark me-3 removeBaground'>
                      <i className='bi-caret-right-fill toggle-off '></i>
                      <i className='bi-caret-down-fill toggle-on'></i>
                    </div>
                    <div className='rcntLines'>
                      <h4 className='text-gray-700 fw-bold cursor-pointer mb-0'>
                        Recent Projects{' '}
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
              <div id='kt' className='collapse fs-6 ms-1 mt-5 allDashbordBodr'>
                <div className='mb-2 mt-1'>
                  <div className='mb-n1'>
                    <div className='tab-content w'>
                      <div id='kt_project_users_card_panes' className='tab-pane fade show active'>
                        <div className='row g-6 px-8'>
                          {folder &&
                            folder.project &&
                            Array.isArray(folder.project) &&
                            folder.project.slice(0, 4).map((projectData: any) => (
                              <div key={projectData.projectid} className='col-12 col-sm-6 col-md-4 col-lg-3'>
                                <div className='card card-xl-stretch custCardPad allRecntProject'>
                                  {/* begin::Header */}
                                  <div className='card-header border-0 px-5'>
                                    <h3 className='card-title align-items-start flex-column'>
                                      <span className='card-label fw-bold text-dark fs14'>
                                        {projectData?.name}
                                      </span>
                                    </h3>
                                    <div className='card-toolbar'>
                                      {/* begin::Menu */}
                                      <button
                                        type='button'
                                        className='btn btn-sm btn-icon btn-active-light-primary'
                                        data-kt-menu-trigger='click'
                                        data-kt-menu-placement='bottom-end'
                                        data-kt-menu-flip='top-end'
                                      >
                                        {/* <KTSVG path='/media/icons/duotune/general/gen024.svg' className='svg-icon-2' /> */}
                                        <i className='bi-three-dots-vertical svg-icon-2'></i>
                                      </button>
                                      <CardListOption
                                        folderId=''
                                        projectId={projectData.projectid}
                                        name={projectData.name}
                                        setIsOpenRename={handlesetIsOpenRename}
                                        setIsOpenDelete={handlesetIsOpenDelete}
                                      />

                                      {/* end::Menu */}
                                    </div>
                                  </div>
                                  {/* end::Header */}
                                  {/* begin::Body */}
                                  <div
                                    className='card-body p0 dashBordSection'
                                    onClick={() => handleOpen('', projectData.projectid)}
                                  >
                                    <div className='dashBoardRecent'>
                                      <img
                                        src={toAbsoluteUrl('/media/avatars/murf-icon-gray5.svg')}
                                        alt='img'
                                        style={{
                                          width: '100%',
                                          maxHeight: '150px',
                                          borderRadius: '0 0 10px 10px',
                                        }}
                                      />
                                    </div>
                                  </div>
                                  {/* end::Body */}
                                </div>
                              </div>
                            ))}
                        </div>
                      </div>
                      <div id='kt_project_users_table_paner' className='tab-pane fade'>
                        <div className='card px-10' id='kt_project_targets_table_pane'>
                          <div className='table-responsive'>
                            <table className='table mb-0 table-row-bordered table-row-dashed gy-4 align-middle fw-bold'>
                              <tbody className='fs-6'>
                                <tr>
                                  <td className='fw-bold'>
                                    <div className='d-flex align-items-center'>
                                      <div>
                                        <img
                                          src={toAbsoluteUrl('/media/avatars/murf-icon-gray5.svg')}
                                          alt='img'
                                          style={{
                                            width: '40px',
                                            maxHeight: '40px',
                                            objectFit: 'contain',
                                          }}
                                        />
                                      </div>
                                      <a
                                        href='#'
                                        className='text-gray-900 text-hover-primary ms-10'
                                      >
                                        Kedarnath Panjikaran
                                      </a>
                                    </div>
                                  </td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td>
                                    <span>4h 13min ago</span>
                                  </td>
                                  <td className='text-end'>
                                    <button
                                      type='button'
                                      className='btn btn-sm btn-icon btn-active-light-primary'
                                      data-kt-menu-trigger='click'
                                      data-kt-menu-placement='bottom-end'
                                      data-kt-menu-flip='top-end'
                                    >
                                      <i className='bi-three-dots-vertical svg-icon-2'></i>
                                    </button>
                                    <Dropdown1 />
                                  </td>
                                </tr>
                                <tr>
                                  <td className='fw-bold'>
                                    <div className='d-flex align-items-center'>
                                      <div>
                                        <img
                                          src={toAbsoluteUrl('/media/avatars/murf-icon-gray5.svg')}
                                          alt='img'
                                          style={{
                                            width: '40px',
                                            maxHeight: '40px',
                                            objectFit: 'contain',
                                          }}
                                        />
                                      </div>
                                      <a
                                        href='#'
                                        className='text-gray-900 text-hover-primary ms-10'
                                      >
                                        Kedarnath Panjikaran
                                      </a>
                                    </div>
                                  </td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td>
                                    <span>4h 13min ago</span>
                                  </td>
                                  <td className='text-end'>
                                    <button
                                      type='button'
                                      className='btn btn-sm btn-icon btn-active-light-primary'
                                      data-kt-menu-trigger='click'
                                      data-kt-menu-placement='bottom-end'
                                      data-kt-menu-flip='top-end'
                                    >
                                      <i className='bi-three-dots-vertical svg-icon-2'></i>
                                    </button>
                                    <Dropdown1 />
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='m-0  mb-12'>
              <div
                className='d-flex align-items-center collapsible toggle collapsed mb-0'
                data-bs-toggle='collapse'
                data-bs-target='#kt_job_2_2'
              >
                <div className='card w-100 px-8 py-4'>
                  <div className='dashboardPrjctSec'>
                    <div className='btn btn-sm btn-icon mw-20px btn-active-color-dark me-3'>
                      <i className='bi-caret-right-fill toggle-off '></i>
                      <i className='bi-caret-down-fill toggle-on'></i>
                    </div>
                    <h4 className='text-gray-700 fw-bold cursor-pointer mb-0 folderLines'>
                      Folders ({folder && folder.folderProject && folder?.folderProject.length})
                    </h4>
                  </div>
                </div>
              </div>
              <div id='kt_job_2_2' className='collapse fs-6 ms-1 mt-5 allDashbordBodr'>
                <div className='d-flex justify-content-end align-items-end mb-7 p-6'>
                  <div className='manageTopSrch'>
                    <i className='fa-solid fa-magnifying-glass manageFolderSrch'></i>
                    <input
                      type='text'
                      value={searchTermFolders}
                      onChange={handleSearchChange1}
                      placeholder='Search'
                      className='form-control bg-transparent mt-2'
                    />
                  </div>
                </div>
                <div className='mb-4'>
                  <div className='d-flex align-items-center mb-n1'>
                    <div className='container px-8'>
                      <div className='row g-6'>
                        {currentFolders.map((folderData: any) => (
                          <div
                            className='col-12 col-sm-6 col-md-4 col-lg-3'
                            key={folderData.folderid}
                          >
                            <div className='card allFolder'>
                              <div className='card-body d-flex align-items-center justify-content-between p20 w-100'>
                                <div className='d-flex align-items-center' onClick={() => handleOpen(folderData?.folderid, "")}>
                                  <i className='bi-folder-fill'></i>
                                  <span className='ms-2 fs14 fw-bold'>{folderData?.name}</span>
                                </div>
                                <div>
                                  <button
                                    type='button'
                                    className='btn btn-sm btn-icon btn-active-light-primary'
                                    data-kt-menu-trigger='click'
                                    data-kt-menu-placement='bottom-end'
                                    data-kt-menu-flip='top-end'
                                  >
                                    <i className='bi-three-dots-vertical svg-icon-2'></i>
                                  </button>
                                  <CardListOption
                                    folderId={folderData?.folderid}
                                    projectId=''
                                    name={folderData.name}
                                    setIsOpenRename={handlesetIsOpenRename}
                                    setIsOpenDelete={handlesetIsOpenDelete}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className='d-flex justify-content-end align-items-center'>
                  <div>
                    {paginationFolders.map((pageNumber) => (
                      <button
                        key={pageNumber}
                        onClick={() => handlePageChangeFolders(pageNumber)}
                        className={currentPageFolders === pageNumber ? 'active' : ''}
                      >
                        {pageNumber}
                      </button>
                    ))}
                  </div>
                </div> */}

                {/* <div className='bottom-0 end-0 mt-12'>
                  <ul className='pagination d-flex align-center justify-center'>
                    <li className='page-item previous'>
                      <a href='#' className='page-link'>
                        <i className='previous'></i>
                      </a>
                    </li>
                    <div className='prjctPagination'>
                      {paginationFolders.map((pageNumber) => (
                        <button
                          key={pageNumber}
                          onClick={() => handlePageChangeFolders(pageNumber)}
                          className={currentPageFolders === pageNumber ? 'active' : ''}
                        >
                          {pageNumber}
                        </button>
                      ))}
                    </div>
                    <li className='page-item next'>
                      <a href='#' className='page-link'>
                        <i className='next'></i>
                      </a>
                    </li>
                  </ul>
                </div> */}

                <div className='d-flex align-items-center justify-content-center roundPagination mt-12'>
                  <div>
                      <Pagination
                        count={paginationFolders?.length}
                        shape='rounded'
                        page={currentPageFolders}
                        onChange={(event, page) => handlePageChangeFolders( page)}
                      />
                    </div>
                </div>
              </div>

            </div>

            <div className='m-0 mb-12'>
              <div
                className='d-flex align-items-center collapsible toggle collapsed mb-0'
                data-bs-toggle='collapse'
                data-bs-target='#kts'
              >
                <div className='card w-100 px-8 py-4'>
                  <div className='dashboardPrjctSec'>
                    <div className='btn btn-sm btn-icon mw-20px btn-active-color-dark me-3'>
                      <i className='bi-caret-right-fill toggle-off '></i>
                      <i className='bi-caret-down-fill toggle-on'></i>
                    </div>
                    <h4 className='text-gray-700 fw-bold cursor-pointer mb-0 projectLines'>
                      Projects ({folder && folder.project && folder?.project.length})
                    </h4>
                  </div>
                </div>
              </div>
              <div id='kts' className='collapse fs-6 ms-1 mt-5 allDashbordBodr'>
                <div className='position-relative'>
                  <div className='d-flex justify-content-end align-items-end mb-7 p-6'>
                    <div className='manageTopSrch'>
                      <i className='fa-solid fa-magnifying-glass manageFolderSrch'></i>
                      <input
                        type='text'
                        value={searchTerm}
                        onChange={handleSearchChange2}
                        placeholder='Search'
                        className='form-control bg-transparent mt-2 w-auto'
                      />
                    </div>
                  </div>
                  <div className='mb-4'>
                    <div className='container px-8'>
                      <div className='row g-6'>
                        {currentProjects.map((projectData: any) => (
                          <div
                            className='col-12 col-sm-6 col-md-4 col-lg-3'
                            key={projectData.projectid}
                          >
                            <div className='card card-xl-stretch mb-xl-8 custCardPad allRecntProject'>
                              {/* begin::Header */}
                              <div className='card-header border-0 px-5'>
                                <h3 className='card-title align-items-start flex-column'>
                                  <span className='card-label fw-bold text-dark fs14'>
                                    {projectData?.name}
                                  </span>
                                </h3>
                                <div className='card-toolbar'>
                                  {/* begin::Menu */}
                                  <button
                                    type='button'
                                    className='btn btn-sm btn-icon btn-active-light-primary'
                                    data-kt-menu-trigger='click'
                                    data-kt-menu-placement='bottom-end'
                                    data-kt-menu-flip='top-end'
                                  >
                                    {/* <KTSVG path='/media/icons/duotune/general/gen024.svg' className='svg-icon-2' /> */}
                                    <i className='bi-three-dots-vertical svg-icon-2'></i>
                                  </button>
                                  <CardListOption
                                    folderId=''
                                    projectId={projectData.projectid}
                                    name={projectData.name}
                                    setIsOpenRename={handlesetIsOpenRename}
                                    setIsOpenDelete={handlesetIsOpenDelete}
                                  />
                                  {/* end::Menu */}
                                </div>
                              </div>
                              {/* end::Header */}
                              {/* begin::Body */}
                              <div
                                className='card-body p0 dashBordSection'
                                onClick={() => handleOpen('', projectData.projectid)}
                              >
                                <div className='dashBoardRecent'>
                                  <img
                                    src={toAbsoluteUrl('/media/avatars/murf-icon-gray5.svg')}
                                    alt='img'
                                    style={{
                                      width: '100%',
                                      maxHeight: '150px',
                                      borderRadius: '0 0 10px 10px',
                                    }}
                                  />
                                </div>
                              </div>
                              {/* end::Body */}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                  {/* <div className='bottom-0 end-0 mt-3'>
                    <ul className='pagination d-flex align-center justify-center'>
                      <li className='page-item previous'>
                        <a href='#' className='page-link'>
                          <i className='previous'></i>
                        </a>
                      </li>
                      <div className='prjctPagination'>
                        {pagination.map((pageNumber) => (
                          <button
                            key={pageNumber}
                            onClick={() => handlePageChange(pageNumber)}
                            className={currentPage === pageNumber ? 'active' : ''}
                          >
                            {pageNumber}
                          </button>
                        ))}
                      </div>
                      <li className='page-item next'>
                        <a href='#' className='page-link'>
                          <i className='next'></i>
                        </a>
                      </li>
                    </ul>
                  </div> */}

                  <div className='d-flex align-items-center justify-content-center roundPagination'>
                    <div>
                      <Pagination
                        count={pagination?.length}
                        shape='rounded'
                        page={currentPage}
                        onChange={(event, page) => handlePageChange( page)}
                      />
                    </div>
                  </div>
                  {/* <div className='position-absolute bottom-0 end-0'>
                    {pagination.map((pageNumber) => (
                      <button
                        key={pageNumber}
                        onClick={() => handlePageChange(pageNumber)}
                        className={currentPage === pageNumber ? 'active' : ''}
                      >
                        {pageNumber}
                      </button>
                    ))}
                  </div> */}
                </div>
              </div>

              {/* <div className="separator separator-dashed"></div> */}
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
      <Modal
        aria-hidden='true'
        dialogClassName='modal-fullscreen'
        show={createProj}
        scrollable={true}
        animation={true}
        fullscreen='xxl-down'
      >
        <div className='modal-content'>
          <div className='modal-header'>
            <div className='modal-title'></div>

            <div
              className='btn btn-icon btn-sm btn-active-light-primary ms-2'
              onClick={() => setCreateProj(!createProj)}
            >
              <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-2x' />
            </div>
          </div>
          <form
            className='form w-100 scroll-y'
            onSubmit={formik.handleSubmit}
            noValidate
            id='kt_login_signin_form'
          >
            <div className='createcont'>
              <div className='modal-body'>
                <div>
                  <div className='row justify-content-center'>
                    <div className='col-sm-12 col-md-11 col-lg-8'>
                      <h1 className='fs36 mb-7'>Create Project</h1>

                      <div className='row'>
                        <div className='col-sm-6 col-md-6 col-lg-6 col-12 mt-7'>
                          <div className='createProjContainer'>
                            {/* <i className='bi-folder-plus svg-icon-2 svg-icon-primary me-0 me-md-2 fs26 projectfieldIcon'></i> */}
                            <i className='bi-plus-circle-fill svg-icon-2 svg-icon-primary me-0 me-md-2 fs26 projectfieldIcon'></i>
                            <input
                              placeholder='Enter your Project Title name'
                              {...formik.getFieldProps('name')}
                              className={clsx(
                                'form-control bg-transparent',
                                { 'is-invalid': formik.touched.name && formik.errors.name },
                                {
                                  'is-valid': formik.touched.name && !formik.errors.name,
                                }
                              )}
                              type='text'
                              name='name'
                              autoComplete='off'
                            // type="text" placeholder="name" name="name" autoComplete="off" className="form-control bg-transparent"
                            />
                            {formik.touched.name && formik.errors.name && (
                              <div className='fv-plugins-message-container formError'>
                                <span role='alert'>{formik.errors.name}</span>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className='col-sm-6 col-lg-6 col-md-6 col-12 mt-7'>
                          <div
                            className='createProjContainer'
                            onClick={() => setFolderName(!folderName)}
                            ref={inputRef}
                          >
                            <div>
                              <div className='maincreateFolder'>
                                <i className='bi-folder-plus svg-icon-2 svg-icon-primary me-0 me-md-2 fs26 projectfieldIcon'></i>
                                <div onClick={handleToggle} className='createFolder'>
                                  {selection && folder?.folderProject ? (
                                    <span>
                                      {
                                        (
                                          folder.folderProject.find(
                                            (folderData: any) => folderData.folderid === selection
                                          ) as any
                                        )?.name
                                      }
                                    </span>
                                  ) : (
                                    <div className='findFolderTop'>
                                      <span>Find Folder</span>
                                      <i className='fa-solid fa-angle-down findFolderBotm'></i>
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div className='allNewFolder card'>
                                {isOpen && (
                                  <div className='mt-3 p-5 midNewFolder'>
                                    <div className=''>
                                      <button
                                        type='button'
                                        data-kt-menu-dismiss='true'
                                        data-kt-user-table-filter='filter'
                                        className='btn btn-primary w-100'
                                        onClick={() => handlecreatefolder()}
                                      >
                                        <div
                                          className={createFolder ? 'modal-backdrop show' : ''}
                                        ></div>
                                        Create New Folder
                                      </button>

                                      <div className='manageTopSrch'>
                                        <i className='fa-solid fa-magnifying-glass manageBotmSrch'></i>
                                        <input
                                          className='form-control bg-transparent mt-2'
                                          type='text'
                                          placeholder='Search...'
                                          value={searchText}
                                          onChange={handleSearchChange}
                                        />
                                      </div>
                                    </div>

                                    <div className='mainslider createFolderSlider'>
                                      <div
                                        onClick={() => handlewithoutfolder()}
                                        className='folderData btn'
                                      >
                                        Continue without folder...
                                      </div>
                                      {filteredOptions.map((folderData: any) => (
                                        <div
                                          key={folderData.folderid}
                                          onClick={() => handleItemClick(folderData.folderid)}
                                          className='folderData btn'
                                        >
                                          {folderData.name}
                                        </div>
                                      ))}
                                    </div>
                                  </div>
                                )}
                                {/* {selection && <div>Selected: {selection}</div>} */}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='col-12 mt-7 d-flex align-item-center justify-content-center'>
                          <div className='nav bg-light rounded-pill ms-9 w-170px'>
                            {/* <button className={`nav-link btn  fw-bold btn-color-gray-600 active py-3 px-5 m-1 rounded-pill ${tabs === 0 ? 'btn-active btn-active-dark' : null}`} onClick={() => { setTabs(0) }}>Audio</button> */}
                            {/* <button className={`nav-link active btn  fw-bold btn-color-gray-600 py-3 px-5 m-1 rounded-pill ${tabs === 1 ? 'btn-active btn-active-dark' : null}`} onClick={() => { setTabs(1) }}>Video</button> */}
                          </div>
                        </div>
                        <div className='col-sm-12 col-md-12 col-lg-12'>
                          <div className='createheading'>
                            <p>Please select the type of project</p>
                          </div>
                        </div>
                        <div className='col-12 mb-10 mainslider'>
                          {tabs === 0 && (
                            <div className='row g-6 '>
                              <div className='col-6 col-sm-4 col-md-4 col-lg-3'>
                                <div
                                  className={`card serviceEffect ${selectService === 'start_blank' ? 'bg-primary' : null
                                    }`}
                                  onClick={() => setSelectService('start_blank')}
                                >
                                  <div className='card-body p0 d-flex align-items-center justify-content-center flex-column'>
                                    <i className='bi-file-earmark fs28 mb-3 mt-3'></i>
                                    <p className='fs-14 fw600'>Start Blank</p>
                                  </div>
                                </div>
                              </div>
                              <div className='col-6 col-sm-4 col-md-4 col-lg-3'>
                                <div
                                  className={`card serviceEffect ${selectService === 'elearn_modul' ? 'bg-primary' : null
                                    }`}
                                  onClick={() => setSelectService('elearn_modul')}
                                >
                                  <div className='card-body p0 d-flex align-items-center justify-content-center flex-column'>
                                    <i className='bi-plus-circle-fill fs28 mb-3 mt-3'></i>
                                    <p className='fs-14 fw600'>E-Learning Module</p>
                                  </div>
                                </div>
                              </div>
                              <div className='col-6 col-sm-4 col-md-4 col-lg-3'>
                                <div
                                  className={`card serviceEffect ${selectService === 'audio_ad' ? 'bg-primary' : null
                                    }`}
                                  onClick={() => setSelectService('audio_ad')}
                                >
                                  <div className='card-body p0 d-flex align-items-center justify-content-center flex-column'>
                                    <i className='bi-broadcast fs28 mb-3 mt-3'></i>
                                    <p className='fs-14 fw600'>Audio Ad</p>
                                  </div>
                                </div>
                              </div>
                              <div className='col-6 col-sm-4 col-md-4 col-lg-3'>
                                <div
                                  className={`card serviceEffect ${selectService === 'ivr_system' ? 'bg-primary' : null
                                    }`}
                                  onClick={() => setSelectService('ivr_system')}
                                >
                                  <div className='card-body p0 d-flex align-items-center justify-content-center flex-column'>
                                    <i className='bi-telephone fs28 mb-3 mt-3'></i>
                                    <p className='fs-14 fw600'>IVR System</p>
                                  </div>
                                </div>
                              </div>
                              <div className='col-6 col-sm-4 col-md-4 col-lg-3'>
                                <div
                                  className={`card serviceEffect ${selectService === 'audioBook' ? 'bg-primary' : null
                                    }`}
                                  onClick={() => setSelectService('audioBook')}
                                >
                                  <div className='card-body p0 d-flex align-items-center justify-content-center flex-column'>
                                    <i className='bi-book fs28 mb-3 mt-3'></i>
                                    <p className='fs-14 fw600'>Audiobook</p>
                                  </div>
                                </div>
                              </div>
                              <div className='col-6 col-sm-4 col-md-4 col-lg-3'>
                                <div
                                  className={`card serviceEffect ${selectService === 'public_anno' ? 'bg-primary' : null
                                    }`}
                                  onClick={() => setSelectService('public_anno')}
                                >
                                  <div className='card-body p0 d-flex align-items-center justify-content-center flex-column'>
                                    <i className='bi-megaphone fs28 mb-3 mt-3'></i>
                                    <p className='fs-14 fw600'>Public Announcement</p>
                                  </div>
                                </div>
                              </div>
                              <div className='col-6 col-sm-4 col-md-4 col-lg-3'>
                                <div
                                  className={`card serviceEffect ${selectService === 'podcast' ? 'bg-primary' : null
                                    }`}
                                  onClick={() => setSelectService('podcast')}
                                >
                                  <div className='card-body p0 d-flex align-items-center justify-content-center flex-column'>
                                    <i className='bi-broadcast-pin fs28 mb-3 mt-3'></i>
                                    <p className='fs-14 fw600'>Podcast</p>
                                  </div>
                                </div>
                              </div>
                              <div className='col-6 col-sm-4 col-md-4 col-lg-3'>
                                <div
                                  className={`card serviceEffect ${selectService === 'personal_learn' ? 'bg-primary' : null
                                    }`}
                                  onClick={() => setSelectService('personal_learn')}
                                >
                                  <div className='card-body p0 d-flex align-items-center justify-content-center flex-column'>
                                    <i className='bi-headphones fs28 mb-3 mt-3'></i>
                                    <p className='fs-14 fw600'>Personal Listening</p>
                                  </div>
                                </div>
                              </div>
                              <div className='col-6 col-sm-4 col-md-4 col-lg-3'>
                                <div
                                  className={`card serviceEffect ${selectService === 'Presentation' ? 'bg-primary' : null
                                    }`}
                                  onClick={() => setSelectService('Presentation')}
                                >
                                  <div className='card-body p0 d-flex align-items-center justify-content-center flex-column'>
                                    <i className='bi-easel fs28 mb-3 mt-3'></i>
                                    <p className='fs-14 fw600'>Presentation</p>
                                  </div>
                                </div>
                              </div>
                              <div className='col-6 col-sm-4 col-md-4 col-lg-3'>
                                <div
                                  className={`card serviceEffect ${selectService === 'artical_blog' ? 'bg-primary' : null
                                    }`}
                                  onClick={() => setSelectService('artical_blog')}
                                >
                                  <div className='card-body p0 d-flex align-items-center justify-content-center flex-column'>
                                    <i className='bi-volume-up fs28 mb-3 mt-3'></i>
                                    <p className='fs-14 fw600'>Article & Blog</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                          {tabs === 1 && (
                            <div className='row g-6'>
                              <div className='col-sm-12 col-md-6 col-lg-3'>
                                <div
                                  className={`card serviceEffect ${selectService === 'start_blank_video' ? 'bg-primary' : null
                                    }`}
                                  onClick={() => setSelectService('start_blank_video')}
                                >
                                  <div className='card-body p0 d-flex align-items-center justify-content-center flex-column'>
                                    {/* <i className="bi-plus-circle-fill fs28 mb-3 mt-3"></i> */}
                                    <i className='bi-file-earmark fs28 mb-3 mt-3'></i>
                                    <p className='fs-14 fw600'>Start Blank</p>
                                  </div>
                                </div>
                              </div>
                              <div className='col-sm-12 col-md-6 col-lg-3'>
                                <div
                                  className={`card serviceEffect ${selectService === 'elearn_video' ? 'bg-primary' : null
                                    }`}
                                  onClick={() => setSelectService('elearn_video')}
                                >
                                  <div className='card-body p0 d-flex align-items-center justify-content-center flex-column'>
                                    <i className='bi-plus-circle-fill fs28 mb-3 mt-3'></i>
                                    <p className='fs-14 fw600'>E-Learning Video</p>
                                  </div>
                                </div>
                              </div>
                              <div className='col-sm-12 col-md-6 col-lg-3'>
                                <div
                                  className={`card serviceEffect ${selectService === 'training_video' ? 'bg-primary' : null
                                    }`}
                                  onClick={() => setSelectService('training_video')}
                                >
                                  <div className='card-body p0 d-flex align-items-center justify-content-center flex-column'>
                                    <i className='bi-file-play fs28 mb-3 mt-3'></i>
                                    <p className='fs-14 fw600'>Training Video</p>
                                  </div>
                                </div>
                              </div>
                              <div className='col-sm-12 col-md-6 col-lg-3'>
                                <div
                                  className={`card serviceEffect ${selectService === 'explainer_video' ? 'bg-primary' : null
                                    }`}
                                  onClick={() => setSelectService('explainer_video')}
                                >
                                  <div className='card-body p0 d-flex align-items-center justify-content-center flex-column'>
                                    <i className='bi-lightbulb fs28 mb-3 mt-3'></i>
                                    <p className='fs-14 fw600'>Explainer Video</p>
                                  </div>
                                </div>
                              </div>
                              <div className='col-sm-12 col-md-6 col-lg-3'>
                                <div
                                  className={`card serviceEffect ${selectService === 'product_demo' ? 'bg-primary' : null
                                    }`}
                                  onClick={() => setSelectService('product_demo')}
                                >
                                  <div className='card-body p0 d-flex align-items-center justify-content-center flex-column'>
                                    <i className='bi-phone fs28 mb-3 mt-3'></i>
                                    <p className='fs-14 fw600'>Product Demo</p>
                                  </div>
                                </div>
                              </div>
                              <div className='col-sm-12 col-md-6 col-lg-3'>
                                <div
                                  className={`card serviceEffect ${selectService === 'motivation_video' ? 'bg-primary' : null
                                    }`}
                                  onClick={() => setSelectService('motivation_video')}
                                >
                                  <div className='card-body p0 d-flex align-items-center justify-content-center flex-column'>
                                    <i className='bi-plus-circle-fill fs28 mb-3 mt-3'></i>
                                    <p className='fs-14 fw600'>Motivational Video</p>
                                  </div>
                                </div>
                              </div>
                              <div className='col-sm-12 col-md-6 col-lg-3'>
                                <div
                                  className={`card serviceEffect ${selectService === 'enterain_video' ? 'bg-primary' : null
                                    }`}
                                  onClick={() => setSelectService('enterain_video')}
                                >
                                  <div className='card-body p0 d-flex align-items-center justify-content-center flex-column'>
                                    <i className='bi-film fs28 mb-3 mt-3'></i>
                                    <p className='fs-14 fw600'>Entertainment Video</p>
                                  </div>
                                </div>
                              </div>
                              <div className='col-sm-12 col-md-6 col-lg-3'>
                                <div
                                  className={`card serviceEffect ${selectService === 'social_media' ? 'bg-primary' : null
                                    }`}
                                  onClick={() => setSelectService('social_media')}
                                >
                                  <div className='card-body p0 d-flex align-items-center justify-content-center flex-column'>
                                    <i className='bi-hand-thumbs-up fs28 mb-3 mt-3'></i>
                                    <p className='fs-14 fw600'>Social Media</p>
                                  </div>
                                </div>
                              </div>
                              <div className='col-sm-12 col-md-6 col-lg-3'>
                                <div
                                  className={`card serviceEffect ${selectService === 'video_game' ? 'bg-primary' : null
                                    }`}
                                  onClick={() => setSelectService('video_game')}
                                >
                                  <div className='card-body p0 d-flex align-items-center justify-content-center flex-column'>
                                    <i className='bi-controller fs28 mb-3 mt-3'></i>
                                    <p className='fs-14 fw600'>Video Games</p>
                                  </div>
                                </div>
                              </div>
                              <div className='col-sm-12 col-md-6 col-lg-3'>
                                <div
                                  className={`card serviceEffect ${selectService === 'video_ad' ? 'bg-primary' : null
                                    }`}
                                  onClick={() => setSelectService('video_ad')}
                                >
                                  <div className='card-body p0 d-flex align-items-center justify-content-center flex-column'>
                                    <i className='bi-play-btn fs28 mb-3 mt-3'></i>
                                    <p className='fs-14 fw600'>Video Ad</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='modal-footer'>
                <button
                  type='button'
                  className='btn btn-light-primary'
                  onClick={() => setCreateProj(!createProj)}
                >
                  Go to Home
                </button>
                <button
                  id='submit'
                  type='submit'
                  className='btn btn-primary'
                  disabled={formik.isSubmitting || !formik.isValid || !selectService}
                >
                  {!loading && <span className='indicator-label'>Create Project</span>}
                  {loading && (
                    <span className='indicator-progress' style={{ display: 'block' }}>
                      Please wait...
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </div>
            </div>
          </form>
        </div>
      </Modal>

      <Modal aria-hidden='true' show={isOpenDelete} scrollable={true} animation={true} centered>
        <div className='modal-content'>
          <div className='modal-header pb-0 border-0 justify-content-end'>
            <div
              className='btn btn-sm btn-icon btn-active-color-primary'
              onClick={() => setIsOpenDelete(false)}
            >
              <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
            </div>
          </div>
          <div className='modal-body scroll-y mx-xl-8 pt-0 pb-10'>
            <div className='renametxt mb-3'>
              <h1 className='mb-3'>Confirm Deletion</h1>
              <h6 className='text-muted fw-bold fs-5'>Are you sure you want to delete?</h6>
            </div>
            <div className='allrename'>
              <div className='primarybtn'>
                <button
                  className='btn btn-lg btn-primary w-40 mb-3'
                  onClick={() =>
                    delrenamefolproject(folderDeleteValue, projectDeleteValue, newName, 'delete')
                  }
                >
                  Yes
                </button>
              </div>
              <div className='primarybtn'>
                <button
                  className='btn btn-lg btn-primary w-40 mb-3'
                  onClick={() => setIsOpenDelete(false)}
                >
                  No
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <Modal aria-hidden='true' show={isOpenRename} scrollable={true} animation={true} centered>
        <div className='mw-650px modal-dialog-centered'>
          <div className='modal-content'>
            <div className='modal-header pb-0 border-0 justify-content-end'>
              <div
                className='btn btn-sm btn-icon btn-active-color-primary'
                // data-bs-dismiss='modal'
                onClick={() => setIsOpenRename(false)}
              >
                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
              </div>
            </div>

            <div className='modal-body scroll-y mx-xl-8 pt-0 pb-15'>
              <div className='renametxt mb-3'>
                <h1 className='mb-3'>Folders Rename</h1>
                <h6 className='text-muted fw-bold fs-5'>
                  Please provide new name you would like to assign.
                </h6>
              </div>
              <div className='allrename'>
                <div className='form-control renameinpt'>
                  <input
                    type='text'
                    value={newName}
                    onChange={(e) => setnewName(e.target.value)}
                  ></input>
                </div>
                <div className='primarybtn'>
                  <button
                    className='btn btn-lg btn-primary w-40 mb-3'
                    onClick={() =>
                      delrenamefolproject(folderRenameValue, projectRenameValue, newName, 'rename')
                    }
                  >
                    Update
                  </button>
                  {/* <button
                  className='btn btn-lg btn-primary w-50 mb-5'
                  onClick={() => setIsOpenRename(false)}
                >
                  No
                </button> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <CreateFolder
        mainvalue={createFolder}
        setCreateFolder={setCreateFolder}
        comesfrom={comesfrom}
      />
    </>
  )
}

const DashboardWrapper = () => {
  const intl = useIntl()
  console.log('useIntl>>laguage3', intl)
  return (
    <>
      <PageTitle breadcrumbs={dashboardBreadCrumbs}>
        {intl.formatMessage({ id: 'MENU.DASHBOARD' })}
      </PageTitle>
      <DashboardPage />
    </>
  )
}

export { DashboardWrapper }
