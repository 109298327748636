import {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {useNavigate, useLocation} from 'react-router-dom'
import {useAuth} from '../../modules/auth/core/Auth'

const dashboardBreadCrumbs: Array<PageLink> = [
  {
    title: 'Home',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
]

const Terms = () => {
  const {saveAuth, currentUser, folder, setfolder} = useAuth()
  // const [isMonthly, setIsMonthly] = useState(true)

  const [matchingProjects, setmatchingProjects] = useState<any[]>([])

  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search)
    const folderId = queryParams.get('folderid')
    const filteredData: any[] = (folder?.AllProjectInfoData || []).filter(
      (obj: any) => obj.folderid === folderId
    )
    const projectIds = filteredData.map((obj: any) => obj.projectid)
    const matchingProjects = (folder?.project || []).filter((obj: any) =>
      projectIds.includes(obj.projectid)
    )
    if (matchingProjects.length === 0) {
      navigate(`/dashboard/?folderid=${folderId}`)
    }
    setmatchingProjects(matchingProjects)
  }, [folder])

  return (
    <>
      <div>
        <div className='card terms-container text-center px-20 py-20'>
          <div className='row px-20'>
            <div className='col-sm-offset-2 allTermsPage'>
              <div className='terms-title mb-10'>
                <h1>Terms &amp; Conditions</h1>
              </div>
              <hr></hr>

              <div className='terms-body'>
                <h4>
                  <strong className='text-danger'>
                    Welcome to The Main Label. Please review the following basic terms that govern
                    your use of, and purchase of, products from our site. Please note that your use
                    of our site constitutes your agreement to follow and be bound by those terms.
                  </strong>
                </h4>
                <hr></hr>
                <h3>Acceptance of Terms</h3>
                <p>
                  By accessing or using the Service, you agree to be bound by these Terms. If you
                  disagree with any part of the Terms, you may not access the Service.
                </p>
                <hr></hr>
                <h3>Description of Service</h3>
                <p>
                  Our Service allows users to convert text into speech using various available
                  voices and languages.
                </p>
                <hr></hr>
                <h3>User Responsibilities</h3>
                <p>
                  You must be at least 18 years old or have the legal authority to use the Service.
                  You are responsible for maintaining the confidentiality of your account
                  information and ensuring the security of your account. You are solely responsible
                  for the content you submit through the Service and the consequences of sharing or
                  distributing that content. You agree not to use the Service for any illegal or
                  unauthorized purpose.
                </p>
                <hr></hr>
                <h3>Intellectual Property Rights</h3>
                <p>
                  The Service and its original content (excluding user-generated content) are and
                  will remain the exclusive property of [Your Company Name] and its licensors.{' '}
                  <a href='email'>naveen.webnexus@gmail.com</a> You may not reproduce, modify, or
                  distribute the content of the Service without explicit written permission from
                  [Your Company Name].
                </p>
                <hr></hr>
                <h3>Limitations of Liability</h3>
                <p>
                  The Service is provided on an "as is" and "as available" basis, without any
                  warranties, expressed or implied. We shall not be liable for any direct, indirect,
                  incidental, special, or consequential damages resulting from your use of or
                  inability to use the Service. We do not guarantee the accuracy, reliability, or
                  availability of the Service, including any voice outputs or translations.
                </p>
                <hr></hr>
                <h3>Privacy</h3>
                <p>
                  Our Privacy Policy explains how we collect, use, and protect your personal
                  information. By using the Service, you consent to the collection and use of your
                  information as described in the Privacy Policy.
                </p>
                <hr></hr>
                <h3>Termination</h3>
                <p>
                  We may suspend or terminate your access to the Service at any time without prior
                  notice or liability. All provisions of the Terms which by their nature should
                  survive termination shall survive, including, without limitation, ownership
                  provisions, warranty disclaimers, indemnity, and limitations of liability.
                </p>
                <hr></hr>
                <h3>Governing Law</h3>
                <p>
                  These Terms shall be governed and construed in accordance with the laws of [Your
                  Country/State], without regard to its conflict of law provisions.
                </p>
                <hr></hr>
                <h3>Changes to Terms and Conditions</h3>
                <p>
                  We reserve the right to modify or replace these Terms at any time without prior
                  notice. Your continued use of the Service after any changes constitute your
                  acceptance of the new Terms. <a href='email'>naveen.webnexus@gmail.com</a>
                </p>
                .
                <div className='container terms_footer'>
                  <h3>
                    Can't find what you're looking for? <a href='www.themainalabel.com'>Email us</a>
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const TermsWrapper = () => {
  const intl = useIntl()
  console.log('useIntl>>laguage3', intl)
  return (
    <>
      <PageTitle breadcrumbs={dashboardBreadCrumbs}>
        {intl.formatMessage({id: 'MENU.DASHBOARD'})}
      </PageTitle>
      <Terms />
    </>
  )
}

export {TermsWrapper}
