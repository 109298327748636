import React, { useState } from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import { useFormik } from 'formik'
import { forgotPassword } from '../core/_requests'
import { InfoLinkModal } from '../../../../_metronic/partials/modals/terms-plan-contact-modal/InfoLinkModal';
const initialValues = {
  email: '',
}

const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
})

export function ForgotPassword() {
  const [isOpenTerms, setIsOpenTerms] = useState(false);
  const [isOpenPlans,setIsOpenPlans] = useState(false);
  const [isOpenContactUs,setIsOpenContactUs] = useState(false);

  const [loading, setLoading] = useState(false)
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      setHasErrors(undefined)

      try {

        const { data: msg } = await forgotPassword(values.email)
        if (msg.hassucceeded == true) {
          setStatus(msg.message)
          setSubmitting(false)
          setLoading(false)
        }
        else {
          setStatus(msg.message)
          setSubmitting(false)
          setLoading(false)
        }
      } catch (error) {
        console.error(error)
        setStatus('The detail is incorrect')
        setSubmitting(false)
        setLoading(false)
      }

    },
  })

  return (
    <>
      <div className="d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-2 order-lg-1 passwordResponse">

        <div className="d-flex flex-center flex-column flex-lg-row-fluid passwordResponse">

          <div className="w-lg-500px p-10 managePading">

            <form className="form w-100" id="kt_password_reset_form" onSubmit={formik.handleSubmit}
              noValidate>

              <div className="text-center mb-10">

                <h1 className="text-dark fw-bolder mb-3">Forgot Password ?</h1>

                <div className="text-gray-500 fw-semibold fs-6">Enter your email to reset your password.</div>

              </div>

              {formik.status && (
                <div className='mb-lg-15 alert alert-success'>
                  <div className='alert-text font-weight-bold'>{formik.status}</div>
                </div>
              )}


              <div className="fv-row mb-8">

                <input
                  placeholder='Email'
                  {...formik.getFieldProps('email')}
                  className={clsx(
                    'form-control bg-transparent',
                    { 'is-invalid': formik.touched.email && formik.errors.email },
                    {
                      'is-valid': formik.touched.email && !formik.errors.email,
                    }
                  )}
                  type='email'
                  name='email'
                  autoComplete='off'
                // type="text" placeholder="Email" name="email" autoComplete="off" className="form-control bg-transparent"
                />
                {formik.touched.email && formik.errors.email && (
                  <div className='fv-plugins-message-container'>
                    <span role='alert'>{formik.errors.email}</span>
                  </div>
                )}
              </div>

              <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
                <button
                  type='submit'
                  id='kt_password_reset_submit'
                  className='btn btn-lg btn-primary fw-bolder me-4'
                >
                  <span className='indicator-label'>Submit</span>
                  {loading && (
                    <span className='indicator-progress'>
                      Please wait...
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
                <Link to='/auth/login'>
                  <button
                    type='button'
                    id='kt_login_password_reset_form_cancel_button'
                    className='btn btn-lg btn-light-primary fw-bolder'
                    disabled={formik.isSubmitting || !formik.isValid}
                  >
                    Cancel
                  </button>
                </Link>{' '}
              </div>

            </form>

          </div>

        </div>


        <div className="w-lg-500px d-flex flex-stack px-10 mx-auto managePading">

          <div className="me-10">

            <button className="btn btn-flex btn-link btn-color-gray-700 btn-active-color-primary rotate fs-base" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-start" data-kt-menu-offset="0px, 0px">
              <img data-kt-element="current-lang-flag" className="w-20px h-20px rounded me-3" src="assets/media/flags/united-states.svg" alt="" />
              <span data-kt-element="current-lang-name" className="me-1">English</span>
              <i className="ki-duotone ki-down fs-5 text-muted rotate-180 m-0"></i>
            </button>

            <div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold w-200px py-4 fs-7" data-kt-menu="true" id="kt_auth_lang_menu">

              <div className="menu-item px-3">
                <a href="#" className="menu-link d-flex px-5" data-kt-lang="English">
                  <span className="symbol symbol-20px me-4">
                    <img data-kt-element="lang-flag" className="rounded-1" src="assets/media/flags/united-states.svg" alt="" />
                  </span>
                  <span data-kt-element="lang-name">English</span>
                </a>
              </div>

              <div className="menu-item px-3">
                <a href="#" className="menu-link d-flex px-5" data-kt-lang="Spanish">
                  <span className="symbol symbol-20px me-4">
                    <img data-kt-element="lang-flag" className="rounded-1" src="assets/media/flags/spain.svg" alt="" />
                  </span>
                  <span data-kt-element="lang-name">Spanish</span>
                </a>
              </div>

              <div className="menu-item px-3">
                <a href="#" className="menu-link d-flex px-5" data-kt-lang="German">
                  <span className="symbol symbol-20px me-4">
                    <img data-kt-element="lang-flag" className="rounded-1" src="assets/media/flags/germany.svg" alt="" />
                  </span>
                  <span data-kt-element="lang-name">German</span>
                </a>
              </div>

              <div className="menu-item px-3">
                <a href="#" className="menu-link d-flex px-5" data-kt-lang="Japanese">
                  <span className="symbol symbol-20px me-4">
                    <img data-kt-element="lang-flag" className="rounded-1" src="assets/media/flags/japan.svg" alt="" />
                  </span>
                  <span data-kt-element="lang-name">Japanese</span>
                </a>
              </div>

              <div className="menu-item px-3">
                <a href="#" className="menu-link d-flex px-5" data-kt-lang="French">
                  <span className="symbol symbol-20px me-4">
                    <img data-kt-element="lang-flag" className="rounded-1" src="assets/media/flags/france.svg" alt="" />
                  </span>
                  <span data-kt-element="lang-name">French</span>
                </a>
              </div>

            </div>

          </div>

          <div className="d-flex fw-semibold text-primary fs-base gap-5">
            <a href='#' onClick={() => setIsOpenTerms(true)}>Terms</a>
            <a href='#' onClick={() => setIsOpenPlans(true)}>Plans</a>
            <a href='#'  onClick={() => setIsOpenContactUs(true)}>Contact Us</a>
          </div>

        </div>
        <InfoLinkModal
        isOpenTerms={isOpenTerms}
        setIsOpenTerms={setIsOpenTerms}
        isOpenPlans={isOpenPlans}
        setIsOpenPlans={setIsOpenPlans}
        isOpenContactUs={isOpenContactUs}
        setIsOpenContactUs={setIsOpenContactUs}
      />
      </div>
      {/* <form
        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
        noValidate
        id='kt_login_password_reset_form'
        onSubmit={formik.handleSubmit}
      >
        <div className='text-center mb-10'>
       
          <h1 className='text-dark mb-3'>Forgot Password ?</h1>
         
          <div className='text-gray-400 fw-bold fs-4'>Enter your email to reset your password.</div>
       
        </div>


        {hasErrors === true && (
          <div className='mb-lg-15 alert alert-danger'>
            <div className='alert-text font-weight-bold'>
              Sorry, looks like there are some errors detected, please try again.
            </div>
          </div>
        )}

        {hasErrors === false && (
          <div className='mb-10 bg-light-info p-8 rounded'>
            <div className='text-info'>Sent password reset. Please check your email</div>
          </div>
        )}
      
        <div className='fv-row mb-10'>
          <label className='form-label fw-bolder text-gray-900 fs-6'>Email</label>
          <input
            type='email'
            placeholder=''
            autoComplete='off'
            {...formik.getFieldProps('email')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {'is-invalid': formik.touched.email && formik.errors.email},
              {
                'is-valid': formik.touched.email && !formik.errors.email,
              }
            )}
          />
          {formik.touched.email && formik.errors.email && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.email}</span>
              </div>
            </div>
          )}
        </div>
    

   
        <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
          <button
            type='submit'
            id='kt_password_reset_submit'
            className='btn btn-lg btn-primary fw-bolder me-4'
          >
            <span className='indicator-label'>Submit</span>
            {loading && (
              <span className='indicator-progress'>
                Please wait...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
          <Link to='/auth/login'>
            <button
              type='button'
              id='kt_login_password_reset_form_cancel_button'
              className='btn btn-lg btn-light-primary fw-bolder'
              disabled={formik.isSubmitting || !formik.isValid}
            >
              Cancel
            </button>
          </Link>{' '}
        </div>
       
      </form> */}
    </>
  )
}
