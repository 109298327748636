import { useEffect, useState } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import clsx from 'clsx'
import { useIntl } from 'react-intl'
import { PageLink, PageTitle } from '../../../_metronic/layout/core'
import { Modal } from 'react-bootstrap'
import { useNavigate, useLocation } from 'react-router-dom'
import { useAuth } from '../../modules/auth/core/Auth'
import {
  packages_data,
  package_calc,
  payment,
  getUserByToken
} from '../../modules/auth/core/_requests'
import { PackageType, PackageCalc } from '../../modules/auth/core/_models'
import StripeCheckout from 'react-stripe-checkout';

import { KTSVG, toAbsoluteUrl } from '../../../_metronic/helpers'

const dashboardBreadCrumbs: Array<PageLink> = [
  {
    title: 'Home',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
]

const initialValues = {
  input_topup: '',
}

const forgotPasswordSchema = Yup.object().shape({
  input_topup: Yup.number()
    .typeError('Please enter a valid number')
    .required('This field is required')
    .min(0, 'Please enter a positive number'),
})


const Pricing = () => {

  const { saveAuth, currentUser, folder, setfolder, auth, setCurrentUser } = useAuth()
  const [isMonthly, setIsMonthly] = useState(true)
  const [payGateway, setpayGateway] = useState(false)
  const [matchingProjects, setmatchingProjects] = useState<any[]>([])

  const navigate = useNavigate()
  const location = useLocation()
  const [packages, setpackages] = useState<PackageType[]>([])
  const [active_pack, setactive_pack] = useState("")
  const [filteredpackages, setfilteredpackages] = useState<any[]>([])
  const [isOpenCalc, setisOpenCalc] = useState(false)
  const [pack_calc, setpack_calc] = useState<PackageCalc>()
  const [isOpenTopup, setisOpenTopup] = useState(false)
  const [isOpenTrans, setisOpenTrans] = useState(false)





  const [packagevalue, setpackagevalue] = useState('')

  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setisOpenTopup(false)
      handlepackage_calc(currentUser?.company_id, "", values.input_topup)

    },
  })



  useEffect(() => {
    async function fetchPackage() {
      try {
        const plans = await packages_data();
        const packagesData = plans?.data; // Assuming 'data' is the array of packages in the response
        const filactive_pack = packagesData?.find((pkg: PackageType) => pkg.packageid === currentUser?.packageid);
        setactive_pack(filactive_pack?.pack_name)

        // Filter packages based on pack_type
        const filPackage = packagesData?.filter((pkg: PackageType) => pkg.pack_type === 'month');

        // Sort filtered packages in increasing order of pack_order
        const sortedPackages = filPackage?.sort((a: PackageType, b: PackageType) => {
          // Convert pack_order to numbers before comparison
          const packOrderA = parseFloat(a.pack_order);
          const packOrderB = parseFloat(b.pack_order);
          return packOrderA - packOrderB;
        });

        setpackages(packagesData);
        setfilteredpackages(sortedPackages);
      } catch (error) {
        console.error('Error fetching packages:', error);
      }
    }
    async function getUser() {
      if (auth && auth.api_token) {
        const { data: user } = await getUserByToken(auth?.api_token);
        if (Array.isArray(user)) {
          const activeUser = user.find((userData) => userData.team_status === true);
          if (activeUser) {
            setCurrentUser(activeUser);
          } else {
            setCurrentUser(user[0]);
          }
        }
      }
    }
    
    getUser();
    fetchPackage();
  }, []);

  const handleMonthlyClick = () => {
    const filPackage = packages?.filter((pkg: PackageType) => pkg.pack_type === 'month');

    // Sort filtered packages in increasing order of pack_order
    const sortedPackages = filPackage?.sort((a: PackageType, b: PackageType) => {
      const packOrderA = parseFloat(a.pack_order);
      const packOrderB = parseFloat(b.pack_order);
      return packOrderA - packOrderB;
    });

    setfilteredpackages(sortedPackages);
    setIsMonthly(true);
  };

  const handleAnnualClick = () => {
    const filPackage = packages?.filter((pkg: PackageType) => pkg.pack_type === 'year');

    // Sort filtered packages in increasing order of pack_order
    const sortedPackages = filPackage?.sort((a: PackageType, b: PackageType) => {
      const packOrderA = parseFloat(a.pack_order);
      const packOrderB = parseFloat(b.pack_order);
      return packOrderA - packOrderB;
    });

    setfilteredpackages(sortedPackages);
    setIsMonthly(false);
  };

  const handlepackage_calc = async (company_id: any, packageid: any, topup_min: any) => {
    try {
      const msg = await package_calc(company_id, packageid, topup_min);
      if (msg.data.hassucceeded === true) {
        setpack_calc(msg.data)
        setisOpenCalc(true)
      }
      else {
        setpack_calc(msg.data)
        setisOpenCalc(true)

      }
      // Do further processing with the 'plans' data if needed
    } catch (error) {
      console.error('Error occurred:', error);
      // Handle any errors that occur during the API call
    }
  };

  const handleToken = async (token: any) => {
    // Send the token to your server for processing
    const msg = await payment(token, pack_calc)

    if (msg) {
      window.location.reload();
    }
  };




  return (
    <>
      <div className='content d-flex flex-column flex-column-fluid' id='kt_content'>
        <div className='mb-20' id='kt_content_container'>
          <div className='card' id='kt_pricing'>
            <div className='card-body p-lg-17'>
              <div className="activePlanUpr text-center">
                <div className="ActivePlan">
                  <h2>Active Plan Details</h2>
                <button className='btn btn-primary mb-5' type='button' onClick={() => setisOpenTrans(true)}>Account Transactions</button>

                  <div className="plan-details">
                    <label>Package Name:</label>
                    <span id="package-name">{active_pack}</span>
                  </div>
                  <div className="plan-details">
                    <label>Package Start Date:</label>
                    <span id="package-date">
  {currentUser?.pack_start ? new Date(currentUser.pack_start).toISOString().split('T')[0] : ''}
  
</span>
                  </div>
                  <div className="plan-details">
                    <label>Package End Date:</label>
                    <span id="available-minutes">{currentUser?.pack_end ? new Date(currentUser.pack_end).toISOString().split('T')[0] : ''}</span>
                  </div>
                  <div className="plan-details">
                    <label>Available Minutes:</label>
                    <span id="available-minutes">{parseFloat(currentUser?.available_minutes || "").toFixed(2)} minutes</span>
                  </div>
                  <br></br>
                  <div className="plan-details d-flex flex-wrap">
                    <label className="AvailTpup" data-bs-toggle='modal' data-bs-target='#ava_top_modal'>Available Topup:</label>
                    <button className='btn btn-primary text-center' type='button' onClick={() => setisOpenTopup(true)}>Add Topup</button>
                    <span id="available-topup">{parseFloat(currentUser?.available_topup || "").toFixed(2)} minutes</span>
                  </div>
                </div>

              </div>


              <div className='d-flex flex-column'>
                <div className='mb-13 text-center'>
                  <h1 className='fs-2hx fw-bold mb-5'>Choose Your Plan</h1>
                  <div className='text-gray-400 fw-semibold fs-5'>
                    If you need more info about our pricing, please check
                    <a href='#' className='link-primary fw-bold px-1'>
                      Pricing Guidelines
                    </a>
                    .
                  </div>
                </div>

                <div className='nav-group nav-group-outline mx-auto mb-15' data-kt-buttons='true'>
                  <button
                    className={`btn btn-color-gray-400 btn-active btn-active-secondary px-6 py-3 me-2 ${isMonthly ? 'active' : ''
                      }`}
                    onClick={handleMonthlyClick}
                  >
                    Monthly
                  </button>
                  <button
                    className={`btn btn-color-gray-400 btn-active btn-active-secondary px-6 py-3 ${!isMonthly ? 'active' : ''
                      }`}
                    onClick={handleAnnualClick}
                  >
                    Annual
                  </button>
                </div>

                <div className='row g-10'>
                  {filteredpackages?.map((pkg: PackageType) => (
                    <div key={pkg.packageid} className='col-xl-4'>
                      <div className='d-flex h-100 align-items-center'>
                        <div className='w-100 d-flex flex-column flex-center rounded-3 bg-light bg-opacity-75 py-15 px-10'>
                          <div className='mb-7 text-center'>
                            <h1 className='text-dark mb-5 fw-bolder'>{pkg.pack_name}</h1>

                            {/* <div className='text-gray-400 fw-semibold mb-5'>
                              Optimal for {pkg.pack_minutes}+ team size
                              <br />
                              and new startup
                            </div> */}

                            <div className='text-center'>
                              <span className='mb-2 text-primary'>$</span>
                              <span className='fs-3x fw-bold text-primary'>{pkg.pack_price}</span>
                              <span className='fs-7 fw-semibold opacity-50'>
                                /<span data-kt-element='period'>{isMonthly ? 'Mon' : 'Year'}</span>
                              </span>
                            </div>
                          </div>

                          <div className='d-flex align-items-center mb-5'>
                            <span className='fw-semibold fs-6 text-gray-800 flex-grow-1 pe-3'>
                              Valid for a {pkg.pack_type}
                            </span>

                            <span className='svg-icon svg-icon-1 svg-icon-success'>
                              <svg
                                width='24'
                                height='24'
                                viewBox='0 0 24 24'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <rect
                                  opacity='0.3'
                                  x='2'
                                  y='2'
                                  width='20'
                                  height='20'
                                  rx='10'
                                  fill='currentColor'
                                />
                                <path
                                  d='M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z'
                                  fill='currentColor'
                                />
                              </svg>
                            </span>
                          </div>

                          <div className='d-flex align-items-center mb-5'>
                            <span className='fw-semibold fs-6 text-gray-800 flex-grow-1 pe-3'>
                              Free {pkg.pack_minutes} minutes
                            </span>

                            <span className='svg-icon svg-icon-1 svg-icon-success'>
                              <svg
                                width='24'
                                height='24'
                                viewBox='0 0 24 24'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <rect
                                  opacity='0.3'
                                  x='2'
                                  y='2'
                                  width='20'
                                  height='20'
                                  rx='10'
                                  fill='currentColor'
                                />
                                <path
                                  d='M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z'
                                  fill='currentColor'
                                />
                              </svg>
                            </span>
                          </div>

                          <div className='d-flex align-items-center mb-5'>
                            <span className='fw-semibold fs-6 text-gray-800 flex-grow-1 pe-3'>
                              Topup price {pkg.pack_cost_permin} / minute
                            </span>

                            <span className='svg-icon svg-icon-1 svg-icon-success'>
                              <svg
                                width='24'
                                height='24'
                                viewBox='0 0 24 24'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <rect
                                  opacity='0.3'
                                  x='2'
                                  y='2'
                                  width='20'
                                  height='20'
                                  rx='10'
                                  fill='currentColor'
                                />
                                <path
                                  d='M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z'
                                  fill='currentColor'
                                />
                              </svg>
                            </span>
                          </div>

                          <button className='btn btn-sm btn-primary' onClick={() =>
                            handlepackage_calc(currentUser?.company_id, pkg.packageid, "")
                          }>
                            Select
                          </button>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal aria-hidden='true' show={isOpenCalc} scrollable={true} animation={true} centered>

        <div className='modal-content'>
          <div className='modal-header pb-0 border-0 justify-content-end'>
            <div
              className='btn btn-sm btn-icon btn-active-color-primary'
              // data-bs-dismiss='modal'
              onClick={() => setisOpenCalc(false)}
            >
              <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
            </div>
          </div>
          {pack_calc?.remaining_days && pack_calc.hassucceeded === true ?
            <>
             
              <div className='modal-body scroll-y mx-5 mx-xl-8 pt-0 pb-15'>
                <div className='text-center mb-0 selectPricing'>
                  <h3 className='mb-7'>{pack_calc?.message}</h3>
                  <p>{pack_calc?.remaining_days} * $ {parseFloat(pack_calc?.costperday || "").toFixed(2)} == $ {parseFloat(pack_calc?.discount || "").toFixed(2)}</p>
                  <p>$ {parseFloat(pack_calc?.packagePrice || "").toFixed(2)} - $ {parseFloat(pack_calc?.discount || "").toFixed(2)} = $ {pack_calc?.payable_amount.toFixed(2)}</p>
                  <p>Net payable amount == $ {pack_calc?.payable_amount.toFixed(2)}</p>
                  <div className='pricingBtns'><button data-bs-dismiss='modal' onClick={() => setisOpenCalc(false)}>Cancel</button>
                    <StripeCheckout
                      token={handleToken}
                      stripeKey="pk_test_51NcVU0SDnQIYA3jGrvNOBSvGQneEeb4Uk1Sgo93mEZAX1KF3SwL5OjriWm1OChNYqXAzAO1DJKxvVwPJwnU470Ui003vxKRqgI"
                      amount={pack_calc?.payable_amount * 100} // Convert to cents
                      currency="USD"
                      name="Your Product Name"
                      description="Product Description"
                    /></div>
                </div>
              </div>
            </>
            : pack_calc?.hassucceeded === false ?
              <>
               

                <div className='modal-body scroll-y mx-5 mx-xl-8 pt-0 pb-15'>
                  <div className='text-center mb-0 selectPricing'>
                    <h3 className='mb-7'>{pack_calc?.message}</h3>
                  </div>
                </div>
              </>

              : pack_calc?.topup_min && pack_calc.hassucceeded === true ?
                <>
                 

                  <div className='modal-body scroll-y mx-5 mx-xl-8 pt-0 pb-15'>
                    <div className='text-center mb-0 selectPricing'>
                      <h3 className='mb-7'>{pack_calc?.message}</h3>
                      <p>{pack_calc?.topup_min} * $ {parseFloat(pack_calc?.costpermin || "").toFixed(2)} == $ {pack_calc?.payable_amount.toFixed(2)}</p>
                      <p>Net payable amount == $ {pack_calc?.payable_amount.toFixed(2)}</p>
                      <div className='pricingBtns'><button data-bs-dismiss='modal' onClick={() => setisOpenCalc(false)}>Cancel</button>
                        <StripeCheckout
                          token={handleToken}
                          stripeKey="pk_test_51NcVU0SDnQIYA3jGrvNOBSvGQneEeb4Uk1Sgo93mEZAX1KF3SwL5OjriWm1OChNYqXAzAO1DJKxvVwPJwnU470Ui003vxKRqgI"
                          amount={pack_calc?.payable_amount * 100} // Convert to cents
                          currency="USD"
                          name="Your Product Name"
                          description="Product Description"
                        /></div>
                    </div>
                  </div>
                </>

                :
                (pack_calc && !pack_calc?.topup_min && !pack_calc.remaining_days) ?

                  <>
                    
                    <div className='modal-body scroll-y mx-5 mx-xl-8 pt-0 pb-15'>
                      <div className='text-center mb-0 selectPricing'>
                        <h3 className='mb-7'>{pack_calc?.message}</h3>
                        <h4>Net payable amount</h4>
                        <p>$ {pack_calc?.payable_amount}</p>
                        <div className='pricingBtns'>
                          <button data-bs-dismiss='modal' onClick={() => setisOpenCalc(false)}>Cancel</button>
                          <StripeCheckout
                            token={handleToken}
                            // stripeKey={process.env.STRIPE_PRIVATE_KEY || ""}
                            stripeKey="pk_test_51NcVU0SDnQIYA3jGrvNOBSvGQneEeb4Uk1Sgo93mEZAX1KF3SwL5OjriWm1OChNYqXAzAO1DJKxvVwPJwnU470Ui003vxKRqgI"
                            amount={pack_calc?.payable_amount * 100} // Convert to cents
                            currency="USD"
                            name="Your Product Name"
                            description="Product Description"
                          /></div>
                      </div>
                    </div>
                  </>
                  : ""
          }
        </div>

      </Modal>

      <Modal aria-hidden='true' show={isOpenTopup} scrollable={true} animation={true} centered>


        <div className='modal-content'>
          <div className='modal-header pb-0 border-0 justify-content-end'>
            <div
              className='btn btn-sm btn-icon btn-active-color-primary'
              // data-bs-dismiss='modal'
              onClick={() => setisOpenTopup(false)}
            >
              <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
            </div>
          </div>
          <div className='modal-body scroll-y mx-5 mx-xl-8 pt-0 pb-15'>
            <div className='text-center mb-0'>
              <div className='availableTpUpr'>
                <form onSubmit={formik.handleSubmit}>
                  <div className='availableTp'>
                    <input
                      placeholder='Enter minutes'
                      {...formik.getFieldProps('input_topup')}
                      className={clsx(
                        'form-control bg-transparent',
                        { 'is-invalid': formik.touched.input_topup && formik.errors.input_topup },
                        {
                          'is-valid': formik.touched.input_topup && !formik.errors.input_topup,
                        }
                      )}
                      type='input_topup'
                      name='input_topup'
                      autoComplete='off'
                    // type="text" placeholder="input_topup" name="input_topup" autoComplete="off" className="form-control bg-transparent"
                    />
                    {formik.touched.input_topup && formik.errors.input_topup && (
                      <div className='fv-plugins-message-container'>
                        <span role='alert'>{formik.errors.input_topup}</span>
                      </div>
                    )}
                  </div>
                  <div className='pricingBtns'>
                  <button className="btn btn-primary" data-bs-dismiss='modal' onClick={() => setisOpenTopup(false)}>Cancel</button>

                  <button className="btn btn-primary" type="submit">Submit</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>


      </Modal>

      <Modal aria-hidden='true' show={isOpenTrans} scrollable={true} animation={true} centered size="lg">
  <div className='modal-content'>
    <div className='modal-header pb-0 border-0 justify-content-end'>
      <div
        className='btn btn-sm btn-icon btn-active-color-primary'
        onClick={() => setisOpenTrans(false)}
      >
        <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
      </div>
    </div>
    <div className='modal-body scroll-y mx-5 mx-xl-8 pt-0 pb-15'>
      <div className='text-center mb-0'>
        <table className='table table-bordered'>
          <thead>
            <tr>
              <th>Type</th>
              <th>Amount</th>
              <th>Expense</th>
              <th>Minutes</th>
              <th>Topup Minutes</th>
            </tr>
          </thead>
          <tbody>
          {Array.isArray(currentUser?.expense) ? (
  currentUser?.expense.map((item: any, index: any) => (
    <tr key={index}>
      <td>{item.type}</td>
      <td>{item.amount}</td>
      <td className="tooltip-container">
        {Array.isArray(item.expense) ? (
          // Map over item.expense if it's an array
          item.expense.map((item1: any, innerIndex: any) => (
            <span key={innerIndex}>{item1.text}</span>
          ))
        ) : (
          // Display item.expense as is if it's not an array
          <span>{item.expense}</span>
        )}
        <div className="tooltip-content">
          {Array.isArray(item.expense) ? (
            // Map over item.expense if it's an array
            item.expense.map((item1: any, innerIndex: any) => (
              <div key={innerIndex}>{item1.text}</div>
            ))
          ) : (
            // Display item.expense as is if it's not an array
            <div>{item.expense}</div>
          )}
        </div>
      </td>
      <td>{item.minutes && parseFloat(item.minutes || "").toFixed(2)}</td>
      <td>{item.topup_minutes && parseFloat(item.topup_minutes || "").toFixed(2)}</td>
    </tr>
  ))
) : (
  // Handle the case when currentUser?.expense is not an array
  <tr>
    <td>No expense data available</td>
  </tr>
)}

          </tbody>
        </table>
      </div>
    </div>
  </div>
</Modal>


    </>
  )
}

const PricingWrapper = () => {
  const intl = useIntl()
  console.log('useIntl>>laguage3', intl)
  return (
    <>
      <PageTitle breadcrumbs={dashboardBreadCrumbs}>
        {intl.formatMessage({ id: 'MENU.DASHBOARD' })}
      </PageTitle>
      <Pricing />
    </>
  )
}

export { PricingWrapper }
