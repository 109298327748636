
import firebase from 'firebase/app';
import 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyDVALwcRtjf7j_LIcVFmwN4wi3CAlfVi54",
  authDomain: "texttovoice-a7c03.firebaseapp.com",
  projectId: "texttovoice-a7c03",
  storageBucket: "texttovoice-a7c03.appspot.com",
  messagingSenderId: "896143928903",
  appId: "1:896143928903:web:4c3d5a21d0372602898b62",
  measurementId: "G-6Y73FF2NH6"
};

firebase.initializeApp(firebaseConfig);

export default firebase;