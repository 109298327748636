import {useEffect, useState} from 'react'
import clsx from 'clsx'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {useIntl} from 'react-intl'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {KTSVG, toAbsoluteUrl} from '../../../_metronic/helpers'
import {CardListOption, Dropdown1} from '../../../_metronic/partials'
import {Modal} from 'react-bootstrap'
import {useNavigate, useLocation} from 'react-router-dom'
import {useAuth} from '../../modules/auth/core/Auth'
import {
  getUserByToken,
  login,
  createproject,
  getfolderprojects,
  delrenamefolderproject,
} from '../../modules/auth/core/_requests'

const dashboardBreadCrumbs: Array<PageLink> = [
  {
    title: 'Home',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
]

const CPSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Name is required'),
})

const initialValues = {
  name: '',
  folderid: '',
}

const ProjectSpace = () => {
  const [loading, setLoading] = useState(false)
  const {saveAuth, currentUser, folder, setfolder} = useAuth()
  const [createProj, setCreateProj] = useState<boolean>(false)
  const [createFolder, setCreateFolder] = useState<boolean>(false)
  const [folderName, setFolderName] = useState<boolean>(false)
  const [tabs, setTabs] = useState<number>(0)
  const [selectService, setSelectService] = useState<string>()
  const [permission, setPermission] = useState<String>('Select Folder')
  const [matchingProjects, setmatchingProjects] = useState<any[]>([])
  const [isOpenRename, setIsOpenRename] = useState(false)
  const [isOpenDelete, setIsOpenDelete] = useState(false)
  const [folderRenameValue, setfolderRenameValue] = useState(false)
  const [projectRenameValue, setprojectRenameValue] = useState(false)
  const [newName, setnewName] = useState('')
  const [folderDeleteValue, setfolderDeleteValue] = useState(false)
  const [projectDeleteValue, setprojectDeleteValue] = useState(false)

  const navigate = useNavigate()
  const location = useLocation()

  const formik = useFormik({
    initialValues,
    validationSchema: CPSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        const tk = localStorage.getItem('kt-auth-react-v')
        const {data: msg} = await createproject(
          values.name,
          tk || '',
          values.folderid ? values.folderid : ''
        )
        if (msg.hassucceeded == true) {
          setStatus(msg.message)
          setSubmitting(false)
          setLoading(false)
          setTimeout(() => {
            navigate('/workspace')
          }, 1000)
        } else {
          setStatus(msg.message)
          setSubmitting(false)
          setLoading(false)
        }
      } catch (error) {
        console.error(error)
        saveAuth(undefined)
        setStatus('The login detail is incorrect')
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search)
    const folderId = queryParams.get('folderid')
    const filteredData: any[] = (folder?.AllProjectInfoData || []).filter(
      (obj: any) => obj.folderid === folderId
    )
    const projectIds = filteredData.map((obj: any) => obj.projectid)
    const matchingProjects = (folder?.project || []).filter((obj: any) =>
      projectIds.includes(obj.projectid)
    )
    if (matchingProjects.length === 0) {
      navigate(`/dashboard/?folderid=${folderId}`)
    }
    setmatchingProjects(matchingProjects)
  }, [folder])

  const selectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value
    setPermission(value)
  }

  const handleOpen = (folderId: any, projectId: any) => {
    if (folderId) {
      navigate(`/ProjectSpace/?folderid=${folderId}`)
    } else if (projectId) {
      const filteredData: any = folder?.AllProjectInfoData?.filter(
        (obj: any) => obj.projectid === projectId
      )
      navigate(`/workspace/?project_infoid=${filteredData[0]?.project_infoid}`)
    }
  }

  const handlesetIsOpenRename = (folderId: any, projectId: any, name: any) => {
    if (folderId) {
      setIsOpenRename(true)
      setfolderRenameValue(folderId)
      setnewName(name)
    } else if (projectId) {
      setIsOpenRename(true)
      setprojectRenameValue(projectId)
      setnewName(name)
    }
  }

  const handlesetIsOpenDelete = (folderId: any, projectId: any) => {
    if (folderId) {
      setIsOpenDelete(true)
      setfolderDeleteValue(folderId)
    } else if (projectId) {
      setIsOpenDelete(true)
      setprojectDeleteValue(projectId)
    }
  }

  const delrenamefolproject = async (folderId: any, projectId: any, name: any, purpose: any) => {
    try {
      const {data: msg} = await delrenamefolderproject(folderId, projectId, name, purpose)
      const {data: folder} = await getfolderprojects(currentUser?.company_name || '')
      setfolder(folder)
      setIsOpenDelete(false)
      setIsOpenRename(false)
    } catch (error) {
      // Handle any errors that occurred during the API requests
      console.error('Error:', error)
      // Add your error handling code here
    }
  }

  return (
    <>
      <div>
        <div className=''></div>
        <div className='mt-0'>
          <div className='tab-content w'>
            <div id='kt_project_users_card_panes' className='tab-pane fade show active'>
              <div className='row g-6'>
                {matchingProjects &&
                  Array.isArray(matchingProjects) &&
                  matchingProjects.map((projectData: any) => (
                    <div className='col-12 col-sm-6 col-md-4 col-lg-3'>
                      <div className='card card-xl-stretch mb-xl-8 custCardPad allRecntProject'>
                        {/* begin::Header */}
                        <div className='card-header border-0 px-5'>
                          <h3 className='card-title align-items-start flex-column'>
                            <span className='card-label fw-bold text-dark fs14'>
                              {projectData?.name}
                            </span>
                          </h3>
                          <div className='card-toolbar'>
                            {/* begin::Menu */}
                            <button
                              type='button'
                              className='btn btn-sm btn-icon btn-active-light-primary'
                              data-kt-menu-trigger='click'
                              data-kt-menu-placement='bottom-end'
                              data-kt-menu-flip='top-end'
                            >
                              {/* <KTSVG path='/media/icons/duotune/general/gen024.svg' className='svg-icon-2' /> */}
                              <i className='bi-three-dots-vertical svg-icon-2'></i>
                            </button>
                            <CardListOption
                              folderId=''
                              projectId={projectData.projectid}
                              name={projectData.name}
                              setIsOpenRename={handlesetIsOpenRename}
                              setIsOpenDelete={handlesetIsOpenDelete}
                            />

                            {/* end::Menu */}
                          </div>
                        </div>
                        {/* end::Header */}
                        {/* begin::Body */}
                        <div
                          className='card-body p0 dashBordSection'
                          onClick={() => handleOpen('', projectData.projectid)}
                        >
                          <div className='dashBoardRecent'>
                            <img
                              src={toAbsoluteUrl('/media/avatars/murf-icon-gray5.svg')}
                              alt='img'
                              style={{
                                width: '100%',
                                maxHeight: '150px',
                                borderRadius: '0 0 10px 10px',
                              }}
                            />
                          </div>
                        </div>
                        {/* end::Body */}
                      </div>
                    </div>
                  ))}
              </div>
            </div>
            <div id='kt_project_users_table_paner' className='tab-pane fade'>
              <div className='card px-10' id='kt_project_targets_table_pane'>
                <div className='table-responsive'>
                  <table className='table mb-0 table-row-bordered table-row-dashed gy-4 align-middle fw-bold'>
                    <tbody className='fs-6'>
                      {matchingProjects &&
                        Array.isArray(matchingProjects) &&
                        matchingProjects.map((projectData: any) => (
                          <tr>
                            <td className='fw-bold'>
                              <div className='d-flex align-items-center'>
                                <div>
                                  <img
                                    src={toAbsoluteUrl('/media/avatars/murf-icon-gray5.svg')}
                                    alt='img'
                                    style={{width: '40px', maxHeight: '40px', objectFit: 'contain'}}
                                  />
                                </div>
                                <a href='#' className='text-gray-900 text-hover-primary ms-10'>
                                  {projectData?.name}
                                </a>
                              </div>
                            </td>
                            <td></td>
                            <td></td>
                            <td></td>
                            {/* <td>
                          <span>4h 13min ago</span>
                        </td> */}
                            <td className='text-end'>
                              <button
                                type='button'
                                className='btn btn-sm btn-icon btn-active-light-primary'
                                data-kt-menu-trigger='click'
                                data-kt-menu-placement='bottom-end'
                                data-kt-menu-flip='top-end'
                              >
                                <i className='bi-three-dots-vertical svg-icon-2'></i>
                              </button>
                              <CardListOption
                                folderId=''
                                projectId={projectData.projectid}
                                name={projectData.name}
                                setIsOpenRename={handlesetIsOpenRename}
                                setIsOpenDelete={handlesetIsOpenDelete}
                              />
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='mt-8 mb-5' />
      </div>
      <Modal aria-hidden='true' show={isOpenDelete} scrollable={true} animation={true} centered>
        <div className='modal-content'>
          <div className='modal-header pb-0 border-0 justify-content-end'>
            <div
              className='btn btn-sm btn-icon btn-active-color-primary'
              onClick={() => setIsOpenDelete(false)}
            >
              <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
            </div>
          </div>
          <div className='modal-body scroll-y mx-xl-8 pt-0 pb-10'>
            <div className='renametxt mb-3'>
              <h1 className='mb-3'>Confirm Deletion</h1>
              <h6 className='text-muted fw-bold fs-5'>Are you sure you want to delete?</h6>
            </div>
            <div className='allrename'>
              <div className='primarybtn'>
                <button
                  className='btn btn-lg btn-primary w-40 mb-3'
                  onClick={() =>
                    delrenamefolproject(folderDeleteValue, projectDeleteValue, newName, 'delete')
                  }
                >
                  Yes
                </button>
              </div>
              <div className='primarybtn'>
                <button
                  className='btn btn-lg btn-primary w-40 mb-3'
                  onClick={() => setIsOpenDelete(false)}
                >
                  No
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <Modal aria-hidden='true' show={isOpenRename} scrollable={true} animation={true} centered>
        <div className='mw-650px modal-dialog-centered'>
          <div className='modal-content'>
            <div className='modal-header pb-0 border-0 justify-content-end'>
              <div
                className='btn btn-sm btn-icon btn-active-color-primary'
                // data-bs-dismiss='modal'
                onClick={() => setIsOpenRename(false)}
              >
                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
              </div>
            </div>

            <div className='modal-body scroll-y mx-xl-8 pt-0 pb-15'>
              <div className='renametxt mb-3'>
                <h1 className='mb-3'>Folders Rename</h1>
                <h6 className='text-muted fw-bold fs-5'>
                  Please provide new name you would like to assign.
                </h6>
              </div>
              <div className='allrename'>
                <div className='form-control renameinpt'>
                  <input
                    type='text'
                    value={newName}
                    onChange={(e) => setnewName(e.target.value)}
                  ></input>
                </div>
                <div className='primarybtn'>
                  <button
                    className='btn btn-lg btn-primary w-40 mb-3'
                    onClick={() =>
                      delrenamefolproject(folderRenameValue, projectRenameValue, newName, 'rename')
                    }
                  >
                    Update
                  </button>
                  {/* <button
                  className='btn btn-lg btn-primary w-50 mb-5'
                  onClick={() => setIsOpenRename(false)}
                >
                  No
                </button> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}

const ProjectSpaceWrapper = () => {
  const intl = useIntl()
  console.log('useIntl>>laguage3', intl)
  return (
    <>
      <PageTitle breadcrumbs={dashboardBreadCrumbs}>
        {intl.formatMessage({id: 'MENU.DASHBOARD'})}
      </PageTitle>
      <ProjectSpace />
    </>
  )
}

export {ProjectSpaceWrapper}
