import {
  FC,
  useState,
  useEffect,
  createContext,
  useContext,
  useRef,
  Dispatch,
  SetStateAction,
} from 'react'
import {LayoutSplashScreen} from '../../../../_metronic/layout/core'
import {AuthModel, UserModel, FolderModel} from './_models'
import * as authHelper from './AuthHelpers'
import {getUserByToken, getfolderprojects} from './_requests'
import {WithChildren} from '../../../../_metronic/helpers'

type AuthContextProps = {
  auth: AuthModel | undefined
  saveAuth: (auth: AuthModel | undefined) => void
  currentUser: UserModel | undefined
  folder: FolderModel | undefined
  setCurrentUser: Dispatch<SetStateAction<UserModel | undefined>>
  setfolder: Dispatch<SetStateAction<FolderModel | undefined>>
  logout: () => void
}

const initAuthContextPropsState = {
  auth: authHelper.getAuth(),
  saveAuth: () => {},
  currentUser: undefined,
  folder: undefined,
  setCurrentUser: () => {},
  setfolder: () => {},
  logout: () => {},
}

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState)

const useAuth = () => {
  return useContext(AuthContext)
}

const AuthProvider: FC<WithChildren> = ({children}) => {
  const [auth, setAuth] = useState<AuthModel | undefined>(authHelper.getAuth())
  const [currentUser, setCurrentUser] = useState<UserModel | undefined>()
  const [folder, setfolder] = useState<UserModel | undefined>()
  const saveAuth = (auth: AuthModel | undefined) => {
    setAuth(auth)
    if (auth) {
      authHelper.setAuth(auth)
    } else {
      authHelper.removeAuth()
    }
  }

  const logout = () => {
    saveAuth(undefined)
    setCurrentUser(undefined)
    setfolder(undefined)

  }

  return (
    <AuthContext.Provider value={{auth, saveAuth, currentUser, setCurrentUser, folder, setfolder, logout}}>
      {children}
    </AuthContext.Provider>
  )
}

const AuthInit: FC<WithChildren> = ({children}) => {
  const {auth, logout, setCurrentUser, setfolder, folder} = useAuth()
  const didRequest = useRef(false)
  const [showSplashScreen, setShowSplashScreen] = useState(true)
  // We should request user by authToken (IN OUR EXAMPLE IT'S API_TOKEN) before rendering the application
  useEffect(() => {
    const requestUser = async (apiToken: string, company_name: string) => {
      try {
        if (!didRequest.current) {
          let folderPromise: Promise<any> | undefined;
          let userPromise: Promise<any> | undefined;
    
          if (company_name) {
            folderPromise = getfolderprojects(company_name).then(({ data: folder }) => {
              setfolder(folder);
            });
          }
    
          if (apiToken) {
            userPromise = getUserByToken(apiToken).then(({ data }) => {
              if (Array.isArray(data)) {
                const activeUser = data.find((userData) => userData.team_status === true);
                if (activeUser) {
                  setCurrentUser(activeUser);
                } else {
                  setCurrentUser(data[0]);
                }
              }
            });
          }
    
          await Promise.all([folderPromise, userPromise]);
        }
      } catch (error) {
        console.error(error);
        if (!didRequest.current) {
          logout();
        }
      } finally {
        setShowSplashScreen(false);
      }
    
      return () => (didRequest.current = true);
    };
    

    if (auth && (auth.api_token || auth.company_name)) {
      requestUser(auth.api_token, auth.company_name || "");
    } else {
      logout()
      setShowSplashScreen(false)
    }
    // eslint-disable-next-line
  }, [])

  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>
}

export {AuthProvider, AuthInit, useAuth}
