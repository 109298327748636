/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import { useFormik } from 'formik'
import { getUserByToken, login, getfolderprojects, googleSignin } from '../core/_requests'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import { useAuth } from '../core/Auth'
import firebase from "../../../../firebaseConfig";
import 'firebase/auth';
import axios from 'axios';
import { InfoLinkModal } from '../../../../_metronic/partials/modals/terms-plan-contact-modal/InfoLinkModal';

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
})

const initialValues = {
  email: '',
  password: '',
}
// const initialValues = {
//   email: 'admin@demo.com',
//   password: 'demo',
// }

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {
  const [isOpenTerms, setIsOpenTerms] = useState(false);
  const [isOpenPlans,setIsOpenPlans] = useState(false);
  const [isOpenContactUs,setIsOpenContactUs] = useState(false);

  const [loading, setLoading] = useState(false)
  const { saveAuth, setCurrentUser, setfolder, currentUser } = useAuth()

  const [loginPassword, setLoginPassword] = useState('password')

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      try {
        const { data: auth } = await login(values.email, values.password)

        if (auth.hassucceeded == false) {
          setStatus(auth.message)
          setLoading(false)
        } else {
          saveAuth(auth)
          const { data: user } = await getUserByToken(auth.api_token)
          if (Array.isArray(user)) {
            const activeUser = user.find((userData) => userData.team_status === true)
            if (activeUser) {
              setCurrentUser(activeUser)
            } else {
              setCurrentUser(user[0])
            }
          }
          const { data: folder } = await getfolderprojects(auth?.company_name || '')
          setfolder(folder)
        }
      } catch (error) {
        console.error(error)
        saveAuth(undefined)
        setStatus('The login detail is incorrect')
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  const toggleLoginPassword = (e: any) => {
    e.preventDefault()
    if (loginPassword === 'password') {
      setLoginPassword('text')
      return
    }
    setLoginPassword('password')
  }


  const handleGoogleSignIn = async () => {
    try {
      const provider = new firebase.auth.GoogleAuthProvider();
      const result = await firebase.auth().signInWithPopup(provider);
      const idToken = result?.user?.getIdToken();

      // Send the idToken to your backend API to complete the sign-in process
      // Make the POST request using Axios
      const { data: auth } = await googleSignin(idToken);
      saveAuth(auth)
      const { data: user } = await getUserByToken(auth.api_token)
      if (Array.isArray(user)) {
        const activeUser = user.find((userData) => userData.team_status === true)
        if (activeUser) {
          setCurrentUser(activeUser)
        } else {
          setCurrentUser(user[0])
        }
      }
      const { data: folder } = await getfolderprojects(auth?.company_name || '')
      setfolder(folder)

      // // Redirect to the desired page upon successful sign-in
      // window.location.href = '/dashboard';
    } catch (error) {
      console.error('Google Sign-In Error:', error);
      // Handle sign-in error on the frontend
    }
  };

  return (
    <div className='d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-2 order-lg-1 loginResponse'>
      <div className='d-flex flex-center flex-column flex-lg-row-fluid loginResponse'>
        <div className='w-lg-500px p-10 managePading'>
          <form
            className='form w-100'
            onSubmit={formik.handleSubmit}
            noValidate
            id='kt_login_signin_form'
          >
            <div className='text-center mb-11 signCamp'>
              <h1 className='text-dark fw-bolder mb-3'>Sign In</h1>

              <div className='text-gray-500 fw-semibold fs-6'>Your Social Campaigns</div>
            </div>

            <div className='row g-3 mb-9'>
              <div className='col-md-12 col-sm-12'>

                <button type= "button" onClick={handleGoogleSignIn} className='btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100'>

                  <img
                    alt='Logo'
                    src={toAbsoluteUrl('/media/auth/google-icon.svg')}
                    className='h-15px me-3'
                  />
                  Sign in with Google
                </button>
              </div>

              {/* <div className='col-md-6 col-sm-6'>
                <a
                  href='#'
                  className='btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100'
                >
                  <img
                    alt='Logo'
                    src={toAbsoluteUrl('/media/auth/apple-black.svg')}
                    className='theme-light-show h-15px me-3'
                  />
                  <img
                    alt='Logo'
                    src={toAbsoluteUrl('/media/auth/apple-black-dark.svg')}
                    className='theme-dark-show h-15px me-3'
                  />
                  Sign in with Apple
                </a>
              </div> */}
            </div>

            <div className='separator separator-content my-14'>
              <span className='w-125px text-gray-500 fw-semibold fs-7'>Or with email</span>
            </div>

            {formik.status && (
              <div className='mb-lg-15 alert alert-danger'>
                <div className='errorImgSec'>
                  <i className="fa-solid fa-circle-exclamation"></i>
                  <div className='alert-text font-weight-bold'>{formik.status}</div>
                </div>
              </div>
            )}

            <div className='row allLoginForm'>
              <div className='fv-row mb-8 col-sm-6 col-md-6 col-lg-12 manageLogin'>
                <input
                  placeholder='Email'
                  {...formik.getFieldProps('email')}
                  className={clsx(
                    'form-control bg-transparent',
                    { 'is-invalid': formik.touched.email && formik.errors.email },
                    {
                      'is-valid': formik.touched.email && !formik.errors.email,
                    }
                  )}
                  type='email'
                  name='email'
                  autoComplete='off'
                // type="text" placeholder="Email" name="email" autoComplete="off" className="form-control bg-transparent"
                />
                {formik.touched.email && formik.errors.email && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.email}</span>
                    </div>
                  </div>
                )}
              </div>

              <div className='fv-row mb-3 col-sm-6 col-md-6 col-lg-12 loginsetpassword'>
                <input
                  placeholder='Password'
                  type={loginPassword}
                  autoComplete='off'
                  {...formik.getFieldProps('password')}
                  className={clsx(
                    'form-control bg-transparent',
                    {
                      'is-invalid': formik.touched.password && formik.errors.password,
                    },
                    {
                      'is-valid': formik.touched.password && !formik.errors.password,
                    }
                  )}
                // type="password" placeholder="Password" name="password" autoComplete="off" className="form-control bg-transparent"
                />

                <div className='loginpassbutton '>
                  <button className='btn me-3' onClick={toggleLoginPassword}>
                    {loginPassword === 'password' ? (
                      <i className='bi bi-eye-slash'></i>
                    ) : (
                      <i className='bi bi-eye'></i>
                    )}
                  </button>
                </div>

                {formik.touched.password && formik.errors.password && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.password}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8'>
              <div></div>

              {/* <a href="../../demo1/dist/authentication/layouts/corporate/reset-password.html" className="link-primary">Forgot Password ?</a> */}
              <Link
                to='/auth/forgot-password'
                className='link-primary fs-6'
                style={{ marginLeft: '5px' }}
              >
                Forgot Password?
              </Link>
            </div>

            <div className='d-grid mb-10 continueBtn'>
              <button
                type='submit'
                id='kt_sign_in_submit'
                className='btn btn-lg btn-primary w-100 mb-5'
                disabled={formik.isSubmitting || !formik.isValid}
              >
                {!loading && <span className='indicator-label'>Continue</span>}
                {loading && (
                  <span className='indicator-progress' style={{ display: 'block' }}>
                    Please wait...
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>

              {/* <button type="submit" id="kt_sign_in_submit" className="btn btn-primary">

                <span className="indicator-label">Sign In</span>

                <span className="indicator-progress">Please wait...
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>

              </button> */}
            </div>

            <div className='text-gray-500 text-center fw-semibold fs-6'>
              Not a Member yet?
              {/* <a href="../../demo1/dist/authentication/layouts/corporate/sign-up.html" className="link-primary">Sign up</a></div> */}
              <Link to='/auth/registration' className='link-primary px-1'>
                Create an Account
              </Link>
            </div>
          </form>
        </div>
      </div>

      <div className='w-lg-500px d-flex flex-stack px-10 mx-auto managePading'>
        <div className='me-10'>
          <button
            className='btn btn-flex btn-link btn-color-gray-700 btn-active-color-primary rotate fs-base'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-start'
            data-kt-menu-offset='0px, 0px'
          >
            <img
              data-kt-element='current-lang-flag'
              className='w-20px h-20px rounded me-3'
              src={toAbsoluteUrl('/media/auth/united-states.svg')}
              alt=''
            />
            <span data-kt-element='current-lang-name' className='me-1'>
              English
            </span>
            <i className='ki-duotone ki-down fs-5 text-muted rotate-180 m-0'></i>
          </button>

          <div
            className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold w-200px py-4 fs-7'
            data-kt-menu='true'
            id='kt_auth_lang_menu'
          >
            <div className='menu-item px-3'>
              <a href='#' className='menu-link d-flex px-5' data-kt-lang='English'>
                <span className='symbol symbol-20px me-4'>
                  <img
                    data-kt-element='lang-flag'
                    className='rounded-1'
                    src='assets/media/flags/united-states.svg'
                    alt=''
                  />
                </span>
                <span data-kt-element='lang-name'>English</span>
              </a>
            </div>

            <div className='menu-item px-3'>
              <a href='#' className='menu-link d-flex px-5' data-kt-lang='Spanish'>
                <span className='symbol symbol-20px me-4'>
                  <img
                    data-kt-element='lang-flag'
                    className='rounded-1'
                    src='assets/media/flags/spain.svg'
                    alt=''
                  />
                </span>
                <span data-kt-element='lang-name'>Spanish</span>
              </a>
            </div>

            <div className='menu-item px-3'>
              <a href='#' className='menu-link d-flex px-5' data-kt-lang='German'>
                <span className='symbol symbol-20px me-4'>
                  <img
                    data-kt-element='lang-flag'
                    className='rounded-1'
                    src='assets/media/flags/germany.svg'
                    alt=''
                  />
                </span>
                <span data-kt-element='lang-name'>German</span>
              </a>
            </div>

            <div className='menu-item px-3'>
              <a href='#' className='menu-link d-flex px-5' data-kt-lang='Japanese'>
                <span className='symbol symbol-20px me-4'>
                  <img
                    data-kt-element='lang-flag'
                    className='rounded-1'
                    src='assets/media/flags/japan.svg'
                    alt=''
                  />
                </span>
                <span data-kt-element='lang-name'>Japanese</span>
              </a>
            </div>

            <div className='menu-item px-3'>
              <a href='#' className='menu-link d-flex px-5' data-kt-lang='French'>
                <span className='symbol symbol-20px me-4'>
                  <img
                    data-kt-element='lang-flag'
                    className='rounded-1'
                    src='assets/media/flags/france.svg'
                    alt=''
                  />
                </span>
                <span data-kt-element='lang-name'>French</span>
              </a>
            </div>
          </div>
        </div>

        <div className='d-flex fw-semibold text-primary fs-base gap-5'>
          <a href='#' onClick={() => setIsOpenTerms(true)}>
            Terms
          </a>
          <a href='#' onClick={() => setIsOpenPlans(true)}>
            Plans
          </a>
          <a href='#'  onClick={() => setIsOpenContactUs(true)}>
            Contact Us
          </a>
        </div>
      </div>
      <InfoLinkModal
        isOpenTerms={isOpenTerms}
        setIsOpenTerms={setIsOpenTerms}
        isOpenPlans={isOpenPlans}
        setIsOpenPlans={setIsOpenPlans}
        isOpenContactUs={isOpenContactUs}
        setIsOpenContactUs={setIsOpenContactUs}
      />
    </div>
    // <form
    //   className='form w-100'
    //   onSubmit={formik.handleSubmit}
    //   noValidate
    //   id='kt_login_signin_form'
    // >
    //   {/* begin::Heading */}
    //   <div className=' mb-10'>
    //     <h1 className='mb-3'>Sign In to Metronic</h1>
    //     <div className='text-gray-400 fw-bold fs-4'>
    //       New Here?{' '}
    //       <Link to='/auth/registration' className='link-primary fw-bolder'>
    //         Create an Account
    //       </Link>
    //     </div>
    //   </div>
    //   {/* begin::Heading */}

    //   {formik.status ? (
    //     <div className='mb-lg-15 alert alert-danger'>
    //       <div className='alert-text font-weight-bold'>{formik.status}</div>
    //     </div>
    //   ) : (
    //     <div className='mb-10 bg-light-info p-8 rounded'>
    //       <div className='text-info'>
    //         Use account <strong>admin@demo.com</strong> and password <strong>demo</strong> to
    //         continue.
    //       </div>
    //     </div>
    //   )}

    //   {/* begin::Form group */}
    //   <div className='fv-row mb-10'>
    //     <label className='form-label fs-6 fw-bolder text-dark'>Email</label>
    //     <input
    //       placeholder='Email'
    //       {...formik.getFieldProps('email')}
    //       className={clsx(
    //         'form-control form-control-lg form-control-solid',
    //         {'is-invalid': formik.touched.email && formik.errors.email},
    //         {
    //           'is-valid': formik.touched.email && !formik.errors.email,
    //         }
    //       )}
    //       type='email'
    //       name='email'
    //       autoComplete='off'
    //     />
    //     {formik.touched.email && formik.errors.email && (
    //       <div className='fv-plugins-message-container'>
    //         <span role='alert'>{formik.errors.email}</span>
    //       </div>
    //     )}
    //   </div>
    //   {/* end::Form group */}

    //   {/* begin::Form group */}
    //   <div className='fv-row mb-10'>
    //     <div className='d-flex justify-content-between mt-n5'>
    //       <div className='d-flex flex-stack mb-2'>
    //         {/* begin::Label */}
    //         <label className='form-label fw-bolder text-dark fs-6 mb-0'>Password</label>
    //         {/* end::Label */}
    //         {/* begin::Link */}
    //         <Link
    //           to='/auth/forgot-password'
    //           className='link-primary fs-6 fw-bolder'
    //           style={{marginLeft: '5px'}}
    //         >
    //           Forgot Password ?
    //         </Link>
    //         {/* end::Link */}
    //       </div>
    //     </div>
    //     <input
    //       type='password'
    //       autoComplete='off'
    //       {...formik.getFieldProps('password')}
    //       className={clsx(
    //         'form-control form-control-lg form-control-solid',
    //         {
    //           'is-invalid': formik.touched.password && formik.errors.password,
    //         },
    //         {
    //           'is-valid': formik.touched.password && !formik.errors.password,
    //         }
    //       )}
    //     />
    //     {formik.touched.password && formik.errors.password && (
    //       <div className='fv-plugins-message-container'>
    //         <div className='fv-help-block'>
    //           <span role='alert'>{formik.errors.password}</span>
    //         </div>
    //       </div>
    //     )}
    //   </div>
    //   {/* end::Form group */}

    //   {/* begin::Action */}
    //   <div className='text-center'>
    //     <button
    //       type='submit'
    //       id='kt_sign_in_submit'
    //       className='btn btn-lg btn-primary w-100 mb-5'
    //       disabled={formik.isSubmitting || !formik.isValid}
    //     >
    //       {!loading && <span className='indicator-label'>Continue</span>}
    //       {loading && (
    //         <span className='indicator-progress' style={{display: 'block'}}>
    //           Please wait...
    //           <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
    //         </span>
    //       )}
    //     </button>

    //     {/* begin::Separator */}
    //     <div className='text-center text-muted text-uppercase fw-bolder mb-5'>or</div>
    //     {/* end::Separator */}

    //     {/* begin::Google link */}
    //     <a href='#' className='btn btn-flex flex-center btn-light btn-lg w-100 mb-5'>
    //       <img
    //         alt='Logo'
    //         src={toAbsoluteUrl('/media/svg/brand-logos/google-icon.svg')}
    //         className='h-20px me-3'
    //       />
    //       Continue with Google
    //     </a>
    //     {/* end::Google link */}

    //     {/* begin::Google link */}
    //     <a href='#' className='btn btn-flex flex-center btn-light btn-lg w-100 mb-5'>
    //       <img
    //         alt='Logo'
    //         src={toAbsoluteUrl('/media/svg/brand-logos/facebook-4.svg')}
    //         className='h-20px me-3'
    //       />
    //       Continue with Facebook
    //     </a>
    //     {/* end::Google link */}

    //     {/* begin::Google link */}
    //     <a href='#' className='btn btn-flex flex-center btn-light btn-lg w-100'>
    //       <img
    //         alt='Logo'
    //         src={toAbsoluteUrl('/media/svg/brand-logos/apple-black.svg')}
    //         className='h-20px me-3'
    //       />
    //       Continue with Apple
    //     </a>
    //     {/* end::Google link */}
    //   </div>
    //   {/* end::Action */}
    // </form>
  )
}
