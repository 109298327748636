/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useState} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import * as Yup from 'yup'
import {Modal} from 'react-bootstrap'
import clsx from 'clsx'
import {invite} from '../../../../app/modules/auth/core/_requests'

import {useFormik} from 'formik'

import {Logout, AuthPage, useAuth} from '../../../../app/modules/auth/index'

const inviteSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  role_name: Yup.string().required('Please select an option'),
})

const initialValues = {
  email: '',
  role_name: '',
}

const InviteUsers: FC = () => {
  const [loading, setLoading] = useState(false)
  const {currentUser} = useAuth()
  const [inviteTeam, setInviteTeam] = useState(false)
  const [learnMore,setLearnMore] = useState(false)

  const formik = useFormik({
    initialValues,
    validationSchema: inviteSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        const tk = localStorage.getItem('kt-auth-react-v')
        const {data: msg} = await invite(
          values.email,
          values.role_name,
          currentUser?.company_name || '',
          tk || ''
        )
        if (msg.hassucceeded == true) {
          setStatus(msg.message)
          setSubmitting(false)
          setLoading(false)
        } else {
          setStatus(msg.message)
          setSubmitting(false)
          setLoading(false)
        }
      } catch (error) {
        console.error(error)
        setStatus('The detail is incorrect')
        setSubmitting(false)
        setLoading(false)
      }
    },
  })
  return (
    <div className='modal fade' id='kt_modal_invite_friends' aria-hidden='true'>
      <div className='modal-dialog mw-650px makeCenterModal modal-dialog-centered p-3 p-sm-4'>
        <div className='modal-content'>
          <div className='modal-header pb-0 border-0 justify-content-end'>
          <button
  className='btn btn-sm btn-icon btn-active-color-primary'
  data-bs-dismiss='modal'
  onClick={() => {
    formik.resetForm(); // Reset the Formik form
  }}
>
  <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
</button>

          </div>
          {/* {currentUser?.user?.role_name == "admin"?  */}
          <div className='modal-body scroll-y mx-5 mx-xl-8 pt-0 pb-15'>
            <div className='text-center mb-10'>
              <h1 className='mb-3'>Invite your team members</h1>

              <div className='text-muted fw-bold fs-5'>
                If you need more info, please check out
                <a
                  href='#'
                  className='link-primary fw-bolder'
                  data-bs-dismiss='modal'
                  onClick={() => {
                    setInviteTeam(true)
                  }}
                >
                  {' '}
                  FAQ Page
                </a>
                .
              </div>
            </div>

            <form noValidate onSubmit={formik.handleSubmit}>
              {formik.status && (
                <div className='alert alert-success'> 
                  <div className='alert-text font-weight-bold'>{formik.status}</div>
                </div>
              )}
              <div className='row g-5'>
                <div className='col-lg-9 col-md-9 col-sm-12'>
                  <div className='invitemainContainer'>
                    <i className='bi-envelope-fill emailIcons'></i>
                    <div className='inviteInput'>
                      <input
                        placeholder='Enter an email and press enter'
                        {...formik.getFieldProps('email')}
                        className={clsx(
                          'form-control bg-transparent ps-15 w',
                          {'is-invalid': formik.touched.email && formik.errors.email},
                          {
                            'is-valid': formik.touched.email && !formik.errors.email,
                          }
                        )}
                        type='email'
                        name='email'
                        autoComplete='off'
                      />
                      {formik.touched.email && formik.errors.email && (
                        <div className='fv-plugins-message-container errorImgSec'>
                          <i className="fa-solid fa-circle-exclamation"></i>
                          <span role='alert' className='text-danger'>
                            {formik.errors.email}
                          </span>
                        </div>
                      )}
                    </div>
                    {/* <select onChange={selectChange} className='form-select form-select-sm form-select-solid editorStyle fw600'>
                      <option value="editor">Editor</option>
                      <option value="viewers">Viewer</option>
                    </select> */}
                    <select
                      {...formik.getFieldProps('role_name')}
                      className={clsx(
                        'form-control form-select form-select-sm form-select-solid editorStyle fw600 selectfield',
                        {'is-invalid': formik.touched.role_name && formik.errors.role_name},
                        {'is-valid': formik.touched.role_name && !formik.errors.role_name}
                      )}
                      name='role_name'
                    >
                      <option value='' disabled>
                        Select an option
                      </option>
                      <option value='editor'>Editor</option>
                      <option value='viewer'>Viewer</option>
                    </select>
                    {formik.touched.role_name && formik.errors.role_name && (
                      <div className='fv-plugins-message-container formError errorImgSec'>
                        <i className="fa-solid fa-circle-exclamation"></i>
                        <span role='alert'>{formik.errors.role_name}</span>
                      </div>
                    )}
                  </div>
                </div>
                <div className='col-lg-3 col-md-3 col-sm-12'>
                  <button
                    type='submit'
                    id='btn btn-primary w'
                    className='btn btn-lg btn-primary w-100 mb-5 px-0'
                    // disabled={formik.isSubmitting || !formik.isValid}
                  >
                    {!loading && <span className='indicator-label'>Invite</span>}
                    {loading && (
                      <span className='indicator-progress' style={{display: 'flex',justifyContent:'center',fontSize:'14px',padding:'0 5px'}}>
                        <span>Please wait...</span>
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </button>
                </div>
                <div className='col-12'>
                  {formik.values.role_name === 'editor' && (
                    <div>
                      <p className='mb-0 fs12'>
                        Editors can create, edit, view, share & comment on the contents of this
                        workspace.
                      </p>
                      <p className='mb-0 fs12'>
                        Editors are paid roles and are priced per seat.{' '}
                        <a href='#' 
                        className='btn-primary'  
                        data-bs-dismiss='modal'
                         onClick={() => {
                        setLearnMore(true)
                       }}>
                          Learn more.
                        </a>
                      </p>
                    </div>
                  )}
                  {formik.values.role_name === 'viewer' && (
                    <div>
                      <p className='mb-0 fs12'>
                        Viewers are free users who can only view the contents of this workspace.
                      </p>
                      <p className='mb-0 fs12'>
                        Viewers are not priced in your subscription plan.{' '}
                        <a href='#' 
                        className='btn-primary'
                        data-bs-dismiss='modal'
                         onClick={() => {
                        setLearnMore(true)
                       }}
                        >
                          {' '}
                          Learn more.
                        </a>
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </form>
          </div>
          {/* // : " "} */}
        </div>
      </div>
      <Modal aria-hidden='true' show={inviteTeam} scrollable={true} animation={true} centered>
        <div className='modal-content'>
          <div className='modal-header pb-0 border-0 justify-content-end'>
            <div
              className='btn btn-sm btn-icon btn-active-color-primary'
              onClick={() => setInviteTeam(false)}
            >
              <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
            </div>
          </div>
          <div className='modal-body scroll-y mx-xl-5 pt-0 pb-10'>
            <div className='renametxt mb-3'>
              <h1 className='mb-3'>FAQ Question</h1>
              <h6 className='text-muted fw-bold fs-5'>
                FAQ stands for Frequently Asked Questions. In the context of a website or a
                document.
              </h6>
            </div>

            <div className='accordion mt-10' id='accordionPanelsStayOpenExample'>
              <div className='accordion-item'>
                <h2 className='accordion-header' id='panelsStayOpen-headingOne'>
                  <button
                    className='accordion-button fs14'
                    type='button'
                    data-bs-toggle='collapse'
                    data-bs-target='#panelsStayOpen-collapseOne'
                    aria-expanded='true'
                    aria-controls='panelsStayOpen-collapseOne'
                  >
                    Q: What is a text-to-speech website?
                  </button>
                </h2>
                <div
                  id='panelsStayOpen-collapseOne'
                  className='accordion-collapse collapse show'
                  aria-labelledby='panelsStayOpen-headingOne'
                >
                  <div className='accordion-body fs14'>
                    A: A text-to-speech website is an online service that converts written text into
                    spoken words. It utilizes artificial intelligence and natural language
                    processing techniques to generate audio output from text input. These websites
                    are designed to assist individuals who may have difficulty reading or prefer
                    listening to content instead. Users can simply input text, and the website will
                    convert it into an audio file that can be played back on various devices.
                  </div>
                </div>
              </div>
              <div className='accordion-item'>
                <h2 className='accordion-header' id='panelsStayOpen-headingTwo'>
                  <button
                    className='accordion-button collapsed fs14'
                    type='button'
                    data-bs-toggle='collapse'
                    data-bs-target='#panelsStayOpen-collapseTwo'
                    aria-expanded='false'
                    aria-controls='panelsStayOpen-collapseTwo'
                  >
                    Q: How does a text-to-speech website work?
                  </button>
                </h2>
                <div
                  id='panelsStayOpen-collapseTwo'
                  className='accordion-collapse collapse'
                  aria-labelledby='panelsStayOpen-headingTwo'
                >
                  <div className='accordion-body fs14'>
                    A: Text-to-speech websites typically employ sophisticated algorithms and machine
                    learning models to convert text into speech. The process involves analyzing the
                    structure, grammar, and context of the provided text. The website's system then
                    applies linguistic rules and algorithms to generate human-like speech patterns,
                    intonation, and pronunciation. Some text-to-speech websites use pre-recorded
                    human voices, while others utilize synthetic voices generated by neural networks
                    or other speech synthesis technologies.
                  </div>
                </div>
              </div>
              <div className='accordion-item'>
                <h2 className='accordion-header' id='panelsStayOpen-headingThree'>
                  <button
                    className='accordion-button collapsed fs14'
                    type='button'
                    data-bs-toggle='collapse'
                    data-bs-target='#panelsStayOpen-collapseThree'
                    aria-expanded='false'
                    aria-controls='panelsStayOpen-collapseThree'
                  >
                    Q: Can I customize the voice on a website?
                  </button>
                </h2>
                <div
                  id='panelsStayOpen-collapseThree'
                  className='accordion-collapse collapse'
                  aria-labelledby='panelsStayOpen-headingThree'
                >
                  <div className='accordion-body fs14'>
                    A: The ability to customize the voice on a text-to-speech website depends on the
                    specific platform. Some websites offer a selection of different voices with
                    varying accents, languages, and genders. You may be able to choose a voice that
                    suits your preferences or matches the context of the text. Advanced
                    text-to-speech platforms may even allow users to modify specific voice
                    characteristics like pitch, speed, or emphasis to further personalize the audio
                    output.
                  </div>
                </div>
              </div>
              <div className='accordion-item'>
                <h2 className='accordion-header' id='panelsStayOpen-headingFour'>
                  <button
                    className='accordion-button collapsed fs14'
                    type='button'
                    data-bs-toggle='collapse'
                    data-bs-target='#panelsStayOpen-collapseFour'
                    aria-expanded='false'
                    aria-controls='panelsStayOpen-collapseFour'
                  >
                    Q: Are text-to-speech websites free to use?
                  </button>
                </h2>
                <div
                  id='panelsStayOpen-collapseFour'
                  className='accordion-collapse collapse'
                  aria-labelledby='panelsStayOpen-headingFour'
                >
                  <div className='accordion-body fs14'>
                    A: The availability and cost structure of text-to-speech websites can vary. Some
                    platforms offer free access to basic text-to-speech functionalities, allowing
                    users to convert limited amounts of text into audio. However, certain advanced
                    features, additional voices, or higher usage limits may be available through
                    premium subscription plans or paid tiers. It's advisable to review the pricing
                    information or subscription options provided by the text-to-speech website to
                    understand the available features and any associated costs.
                  </div>
                </div>
              </div>
            </div>

            {/* <div className='allrename'>
              <div className='primarybtn'>
                <button
                  className='btn btn-lg btn-primary w-40 mb-3'
                  onClick={() =>
                    delrenamefolproject(folderDeleteValue, projectDeleteValue, newName, 'delete')
                  }
                >
                  Yes
                </button>
              </div>
              <div className='primarybtn'>
                <button
                  className='btn btn-lg btn-primary w-40 mb-3'
                  onClick={() => setIsOpenDelete(false)}
                >
                  No
                </button>
              </div>
            </div> */}
          </div>
        </div>
      </Modal>
     {/* Modal for Learn More */}
      <Modal aria-hidden='true' show={learnMore} scrollable={true} animation={true} centered>
        <div className='modal-content'>
          <div className='modal-header pb-0 border-0 justify-content-end'>
            <div
              className='btn btn-sm btn-icon btn-active-color-primary'
              onClick={() => setLearnMore(false)}
            >
              <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
            </div>
          </div>
          <div className='modal-body scroll-y mx-xl-5 pt-0 pb-10'>
            <div className='renametxt mb-3'>
              <h1 className='mb-3'>Learn More</h1>
              <h6 className='text-muted fw-bold fs-5'>
                In the context of a Editor or a Viewer.
              
              </h6>
            </div>

           <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Quo nesciunt eveniet nostrum
             minus maxime doloribus, a cum commodi porro deserunt tempore maiores officiis excepturi nisi 
             quasi explicabo! Accusamus, quo est.
             
             Lorem ipsum, dolor sit amet consectetur adipisicing elit. Quo nesciunt eveniet nostrum
             minus maxime doloribus, a cum commodi porro deserunt tempore maiores officiis excepturi nisi 
             quasi explicabo! Accusamus, quo est.

             Lorem ipsum, dolor sit amet consectetur adipisicing elit. Quo nesciunt eveniet nostrum
             minus maxime doloribus, a cum commodi porro deserunt tempore maiores officiis excepturi nisi 
             quasi explicabo! Accusamus, quo est.
             </p>

            {/* <div className='allrename'>
              <div className='primarybtn'>
                <button
                  className='btn btn-lg btn-primary w-40 mb-3'
                  onClick={() =>
                    delrenamefolproject(folderDeleteValue, projectDeleteValue, newName, 'delete')
                  }
                >
                  Yes
                </button>
              </div>
              <div className='primarybtn'>
                <button
                  className='btn btn-lg btn-primary w-40 mb-3'
                  onClick={() => setIsOpenDelete(false)}
                >
                  No
                </button>
              </div>
            </div> */}
          </div>
        </div>
      </Modal>
    </div>
  )
}

export {InviteUsers}
 