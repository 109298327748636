import {FC, Fragment} from 'react'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useLayout} from '../../../core/LayoutProvider'
import {usePageData} from '../../../core/PageData'
import {useLocation} from 'react-router-dom'

const DefaultTitle: FC = () => {
  const location = useLocation()
  const {pageTitle, pageDescription, pageBreadcrumbs} = usePageData()
  const {config, classes} = useLayout()

  const queryParams = new URLSearchParams(location.search)
  var project_infoid = queryParams.get('project_infoid')

  return (
    <div
      data-kt-swapper='true'
      data-kt-swapper-mode='prepend'
      data-kt-swapper-parent="{default: '#kt_content_container', lg: '#kt_header_container'}"
      className={clsx(
        'page-title d-flex flex-column align-items-start justify-content-center flex-wrap mt-n5 mt-lg-0 me-lg-2 pb-2 pb-lg-0',
        classes.pageTitle.join(' ')
      )}
    >
      {/* begin::Heading */}
      <div className='d-flex align-items-center'>
        <h1 className='text-dark fw-bolder my-0 fs-2'>
          {pageTitle && pageTitle}
          {pageDescription && (
            <span className='text-muted fs-6 fw-normal ms-1'>{pageDescription}</span>
          )}
        </h1>
        {project_infoid ? (
          ' '
        ) : (
          <></>
          // <div className='d-flex flex-wrap my-1 ms-12'>
          //   <ul className='nav nav-pills me-6 mb-2 mb-sm-0'>
          //     <li className='nav-item m-0'>
          //       <a
          //         className='btn btn-light-primary me-5 active'
          //         //className='btn btn-sm btn-icon btn-light-primary btn-color-muted me-3 btn-active-primary'
          //         data-bs-toggle='tab'
          //         href='#kt_project_users_card_panes'
          //         id={'row'}
          //       >
          //         <span className='svg-icon svg-icon-2'>
          //           <svg
          //             xmlns='http://www.w3.org/2000/svg'
          //             width='24px'
          //             height='24px'
          //             viewBox='0 0 24 24'
          //           >
          //             <g stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'>
          //               <rect x='5' y='5' width='5' height='5' rx='1' fill='currentColor' />
          //               <rect
          //                 x='14'
          //                 y='5'
          //                 width='5'
          //                 height='5'
          //                 rx='1'
          //                 fill='currentColor'
          //                 opacity='0.3'
          //               />
          //               <rect
          //                 x='5'
          //                 y='14'
          //                 width='5'
          //                 height='5'
          //                 rx='1'
          //                 fill='currentColor'
          //                 opacity='0.3'
          //               />
          //               <rect
          //                 x='14'
          //                 y='14'
          //                 width='5'
          //                 height='5'
          //                 rx='1'
          //                 fill='currentColor'
          //                 opacity='0.3'
          //               />
          //             </g>
          //           </svg>
          //         </span>
          //       </a>
          //     </li>
          //     <li className='nav-item m-0'>
          //       <a
          //         className='btn btn-light-primary'
          //         //className='btn btn-sm btn-icon btn-light btn-color-muted btn-active-primary'
          //         data-bs-toggle='tab'
          //         href='#kt_project_users_table_paner'
          //         id={'column'}
          //       >
          //         <span className='svg-icon svg-icon-2'>
          //           <svg
          //             width='24'
          //             height='24'
          //             viewBox='0 0 24 24'
          //             fill='none'
          //             xmlns='http://www.w3.org/2000/svg'
          //           >
          //             <path
          //               d='M21 7H3C2.4 7 2 6.6 2 6V4C2 3.4 2.4 3 3 3H21C21.6 3 22 3.4 22 4V6C22 6.6 21.6 7 21 7Z'
          //               fill='currentColor'
          //             />
          //             <path
          //               opacity='0.3'
          //               d='M21 14H3C2.4 14 2 13.6 2 13V11C2 10.4 2.4 10 3 10H21C21.6 10 22 10.4 22 11V13C22 13.6 21.6 14 21 14ZM22 20V18C22 17.4 21.6 17 21 17H3C2.4 17 2 17.4 2 18V20C2 20.6 2.4 21 3 21H21C21.6 21 22 20.6 22 20Z'
          //               fill='currentColor'
          //             />
          //           </svg>
          //         </span>
          //       </a>
          //     </li>
          //   </ul>
          // </div>
        )}
      </div>

      {/* end::Heading */}

      {pageBreadcrumbs &&
        pageBreadcrumbs.length > 0 &&
        config.pageTitle &&
        config.pageTitle.breadCrumbs && (
          <>
            <ul className='breadcrumb fw-bold fs-base my-1'>
              {Array.from(pageBreadcrumbs)
                .filter((t) => !t.isSeparator)
                .map((item, index) => (
                  <Fragment key={`${item.path}${index}`}>
                    {item.isActive ? (
                      <li className='breadcrumb-item text-dark'>{item.title}</li>
                    ) : (
                      <li className='breadcrumb-item text-muted'>
                        <Link to={''} className='text-muted'>
                          {item.title}
                        </Link>
                      </li>
                    )}
                  </Fragment>
                ))}
              <li className='breadcrumb-item text-dark'>{pageTitle}</li>
            </ul>
          </>
        )}
    </div>
  )
}

export {DefaultTitle}
