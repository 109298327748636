import React, {useEffect, useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import {emailverify, login, getUserByToken, getfolderprojects} from '../core/_requests'
import {useNavigate, useLocation} from 'react-router-dom'
import {KTSVG, toAbsoluteUrl} from '../../../../../src/_metronic/helpers'
import {useAuth} from '../core/Auth'

export function EmailVerify() {
  const [show, setshow] = useState(false)
  const [msg, setmsg] = useState('')
  const {saveAuth, setCurrentUser, setfolder, currentUser} = useAuth()

  const [loading, setLoading] = useState(false)
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const navigate = useNavigate()
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const paramsArray = Array.from(queryParams.entries())
  const numParams = paramsArray.length

  // URL has parameters
  var tokenFromURL = queryParams.get('token')

  const verify = async (token: any) => {
    try {
      const msg: any = await emailverify(token || '')
      if (msg.data.hassucceeded === true) {
        setshow(true)
        setmsg(msg.data.message)
        const {data: auth} = await login(msg.data.email, msg.data.password)

        if (auth.hassucceeded == false) {
          // setStatus(auth.message)
          setLoading(false)
        } else {
          saveAuth(auth)
          const {data: user} = await getUserByToken(auth.api_token)
          if (Array.isArray(user)) {
            const activeUser = user.find((userData) => userData.team_status === true)
            if (activeUser) {
              setCurrentUser(activeUser)
            } else {
              setCurrentUser(user[0])
            }
          }
          const {data: folder} = await getfolderprojects(auth?.company_name || '')
          setfolder(folder)
        }
      } else {
        setmsg(msg.data.message) // Throw an error to trigger the catch block
      }
    } catch (error) {
      console.error('Error verifying email:', error)
      // Handle the error, show an error message, or perform any necessary error handling logic
    }
  }

  useEffect(() => {
    verify(tokenFromURL)
  }, [])

  return (
    <>
      <div className='d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-2 order-lg-1'>
        {msg && (
        <div className='fv-row mt-8 col-sm-8 col-md-6 col-lg-8 emailVerifySec'>
          <div
            className={`mb-lg-15 alert ${
              msg.includes('Success') ? 'alert-success' : 'alert-danger'
            }`}
          >
            <div className='alert-text font-weight-bold'>
              This company already exists! please connect your administrator
            </div>
          </div>
        </div>
        )}
      </div>
      {/* {show &&
        <div className='d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-2 order-lg-1'>
          <div className='d-flex flex-center flex-column flex-lg-row-fluid'>
            <div className='w-lg-500px p-10'>
              <img
                className='w-100'
                src={toAbsoluteUrl('/media/avatars/check-email-icon-27.jpg')}
                alt='avatar'
              />
            </div>
            <h1>Email Verified Successfully!</h1>
            <button onClick={() => navigate('/auth/login')}>Continue</button>
          </div>

          <div className='w-lg-500px d-flex flex-stack px-10 mx-auto'>
            <div className='me-10'>
              <button
                className='btn btn-flex btn-link btn-color-gray-700 btn-active-color-primary rotate fs-base'
                data-kt-menu-trigger='click'
                data-kt-menu-placement='bottom-start'
                data-kt-menu-offset='0px, 0px'
              >
                <img
                  data-kt-element='current-lang-flag'
                  className='w-20px h-20px rounded me-3'
                  src='assets/media/flags/united-states.svg'
                  alt=''
                />
                <span data-kt-element='current-lang-name' className='me-1'>
                  English
                </span>
                <i className='ki-duotone ki-down fs-5 text-muted rotate-180 m-0'></i>
              </button>

              <div
                className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold w-200px py-4 fs-7'
                data-kt-menu='true'
                id='kt_auth_lang_menu'
              >
                <div className='menu-item px-3'>
                  <a href='#' className='menu-link d-flex px-5' data-kt-lang='English'>
                    <span className='symbol symbol-20px me-4'>
                      <img
                        data-kt-element='lang-flag'
                        className='rounded-1'
                        src='assets/media/flags/united-states.svg'
                        alt=''
                      />
                    </span>
                    <span data-kt-element='lang-name'>English</span>
                  </a>
                </div>

                <div className='menu-item px-3'>
                  <a href='#' className='menu-link d-flex px-5' data-kt-lang='Spanish'>
                    <span className='symbol symbol-20px me-4'>
                      <img
                        data-kt-element='lang-flag'
                        className='rounded-1'
                        src='assets/media/flags/spain.svg'
                        alt=''
                      />
                    </span>
                    <span data-kt-element='lang-name'>Spanish</span>
                  </a>
                </div>

                <div className='menu-item px-3'>
                  <a href='#' className='menu-link d-flex px-5' data-kt-lang='German'>
                    <span className='symbol symbol-20px me-4'>
                      <img
                        data-kt-element='lang-flag'
                        className='rounded-1'
                        src='assets/media/flags/germany.svg'
                        alt=''
                      />
                    </span>
                    <span data-kt-element='lang-name'>German</span>
                  </a>
                </div>

                <div className='menu-item px-3'>
                  <a href='#' className='menu-link d-flex px-5' data-kt-lang='Japanese'>
                    <span className='symbol symbol-20px me-4'>
                      <img
                        data-kt-element='lang-flag'
                        className='rounded-1'
                        src='assets/media/flags/japan.svg'
                        alt=''
                      />
                    </span>
                    <span data-kt-element='lang-name'>Japanese</span>
                  </a>
                </div>

                <div className='menu-item px-3'>
                  <a href='#' className='menu-link d-flex px-5' data-kt-lang='French'>
                    <span className='symbol symbol-20px me-4'>
                      <img
                        data-kt-element='lang-flag'
                        className='rounded-1'
                        src='assets/media/flags/france.svg'
                        alt=''
                      />
                    </span>
                    <span data-kt-element='lang-name'>French</span>
                  </a>
                </div>
              </div>
            </div>

            <div className='d-flex fw-semibold text-primary fs-base gap-5'>
              <a href='../../demo1/dist/pages/team.html' target='_blank'>
                Terms
              </a>
              <a href='../../demo1/dist/pages/pricing/column.html' target='_blank'>
                Plans
              </a>
              <a href='../../demo1/dist/pages/contact.html' target='_blank'>
                Contact Us
              </a>
            </div>
          </div>
        </div>
       }  */}

      {/* <form
  className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
  noValidate
  id='kt_login_password_reset_form'
  onSubmit={formik.handleSubmit}
>
  <div className='text-center mb-10'>
 
    <h1 className='text-dark mb-3'>Forgot Password ?</h1>
   
    <div className='text-gray-400 fw-bold fs-4'>Enter your email to reset your password.</div>
 
  </div>


  {hasErrors === true && (
    <div className='mb-lg-15 alert alert-danger'>
      <div className='alert-text font-weight-bold'>
        Sorry, looks like there are some errors detected, please try again.
      </div>
    </div>
  )}

  {hasErrors === false && (
    <div className='mb-10 bg-light-info p-8 rounded'>
      <div className='text-info'>Sent password reset. Please check your email</div>
    </div>
  )}

  <div className='fv-row mb-10'>
    <label className='form-label fw-bolder text-gray-900 fs-6'>Email</label>
    <input
      type='email'
      placeholder=''
      autoComplete='off'
      {...formik.getFieldProps('email')}
      className={clsx(
        'form-control form-control-lg form-control-solid',
        {'is-invalid': formik.touched.email && formik.errors.email},
        {
          'is-valid': formik.touched.email && !formik.errors.email,
        }
      )}
    />
    {formik.touched.email && formik.errors.email && (
      <div className='fv-plugins-message-container'>
        <div className='fv-help-block'>
          <span role='alert'>{formik.errors.email}</span>
        </div>
      </div>
    )}
  </div>



  <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
    <button
      type='submit'
      id='kt_password_reset_submit'
      className='btn btn-lg btn-primary fw-bolder me-4'
    >
      <span className='indicator-label'>Submit</span>
      {loading && (
        <span className='indicator-progress'>
          Please wait...
          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
        </span>
      )}
    </button>
    <Link to='/auth/login'>
      <button
        type='button'
        id='kt_login_password_reset_form_cancel_button'
        className='btn btn-lg btn-light-primary fw-bolder'
        disabled={formik.isSubmitting || !formik.isValid}
      >
        Cancel
      </button>
    </Link>{' '}
  </div>
 
</form> */}
    </>
  )
}
