import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { PageLink, PageTitle } from '../../../_metronic/layout/core'
import { KTSVG, toAbsoluteUrl } from '../../../_metronic/helpers'
import { Modal } from 'react-bootstrap'
import { useNavigate, useLocation } from 'react-router-dom'
import axios from 'axios'
import {
  getUserByToken,
  login,
  getfolderprojects,
  voices_data,
  getprojectinfo,
  projectinfo,
  speech,
} from '../../../app/modules/auth/core/_requests'
import { useAuth } from '../../modules/auth/core/Auth'
import { Voice, InputItem } from '../../../app/modules/auth/core/_models'
// import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd'
import { AddPause, Dropdown1, DropdownPitch, DropdownSpeed } from '../../../_metronic/partials'
import { Languages } from '../../../_metronic/partials/layout/header-menus/Languages'

const dashboardBreadCrumbs: Array<PageLink> = [
  {
    title: 'Home',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
]

const WorkSpace = () => {
  const { saveAuth, currentUser, folder } = useAuth()
  const [createProj, setCreateProj] = useState<boolean>(false)
  const [permission, setPermission] = useState<String>('Select Folder')
  const [fav, setFav] = useState<boolean>(false)
  const [preview, setPreview] = useState<boolean>(true)
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  // const paramsArray = Array.from(queryParams.entries());
  // const numParams = paramsArray.length;

  // URL has parameters
  var project_infoid = queryParams.get('project_infoid')

  //------------
  const [inputList, setinputList] = useState<InputItem[]>([
    {
      text: '',
      pitch: '',
      volume: '',
      speed: '',
      voice_name: '',
      voice_languageCodes: '',
      pitch_percent: '',
      volume_percent: '',
      speed_percent: '',
      voice_photo: '',
      duration: '',
    },
  ])
  const [speechData, setSpeechData] = useState([])
  const [audioUrl, setAudioUrl] = useState('')
  const [audio, setAudio] = useState<HTMLAudioElement | null>(null)
  const [isPlaying, setIsPlaying] = useState(false)
  const [isPlayingfull, setIsPlayingfull] = useState(false)
  const [isEnterfull, setIsEnterfull] = useState(false)
  const [isEnterPrevIndex, setIsEnterPrevIndex] = useState(false)
  const [audioInitialized, setAudioInitialized] = useState(false)
  const [prevIndex, setPrevIndex] = useState<number | null>(null)
  const [voices, setVoices] = useState<Voice[]>([])
  const [filteredVoices, setFilteredVoices] = useState<Voice[]>([])
  const [selectedVoice, setSelectedVoice] = useState('')
  const [selectedOption, setSelectedOption] = useState('')
  const [selectedValue, setSelectedValue] = useState<number | null>(null)
  const [playingname, setPlayingname] = useState('')
  const [PitchIndex, setPitchIndex] = useState<number | null>(null)
  const [VolumeIndex, setVolumeIndex] = useState<number | null>(null)
  const [SpeedIndex, setSpeedIndex] = useState<number | null>(null)
  const [DeleteIndex, setDeleteIndex] = useState<number | null>(null)
  const [uniqueCountries, setUniqueCountries] = useState([])
  const [selectedGender, setSelectedGender] = useState('')
  const [selectedGenderVoice, setSelectedGenderVoice] = useState<Voice[]>([])
  const [loading, setLoading] = useState(false)
  const [voiceloader, setvoiceloader] = useState(false)
  const [fullvoiceloader, setfullvoiceloader] = useState(false)
  const [favoriteVoices, setFavoriteVoices] = useState<string[]>([]);
  const [fullvoiceduration, setfullvoiceduration] = useState<number | null>(null)
  const [showTooltip, setShowTooltip] = useState(false);
  const [showerrmsg, setshowerrmsg] = useState("");







  const [isOpenDelete, setIsOpenDelete] = useState(false)

  const fetchVoices = async () => {
    try {
      const voices = await voices_data()
      // const countries = Array.from(new Set(voices?.data?.map((voice: Voice) => voice.country))) as [];
      setVoices(voices?.data)
      // setUniqueCountries(countries);
    } catch (error) {
      console.error('Error fetching voices:', error)
    }
  }

  const handleToggleFavorite = (voiceName: string) => {
    const updatedFavoriteVoices = favoriteVoices.includes(voiceName)
      ? favoriteVoices.filter((name) => name !== voiceName)
      : [...favoriteVoices, voiceName];

    setFavoriteVoices(updatedFavoriteVoices);
    // fetchUpdatedData("", updatedFavoriteVoices);
  };






  const fetchData = async () => {
    try {
      const queryParams = new URLSearchParams(location.search)
      const project_infoId = queryParams.get('project_infoid')
      const filteredData: any = (folder?.AllProjectInfoData || []).filter(
        (obj: any) => obj.project_infoid === project_infoId
      )
      const projectInfoData = await getprojectinfo(filteredData[0].project_infoid)
      if (projectInfoData?.data?.orders == null) {
        setinputList([
          {
            text: '',
            pitch: '',
            volume: '',
            speed: '',
            voice_name: '',
            voice_languageCodes: '',
            pitch_percent: '',
            volume_percent: '',
            speed_percent: '',
            voice_photo: '',
            duration: '',
          },
        ])
      } else {
        setinputList(projectInfoData?.data?.orders)
      }
      if (projectInfoData?.data?.fav_voices == null) {
        setFavoriteVoices([]);
      } else {
        setFavoriteVoices(projectInfoData?.data?.fav_voices);
      }
    } catch (error) {
      console.error('Error fetching data:', error)
    }
  }

  const fetchUpdatedData = async (list: any, fav_voices: any) => {
    try {
      // Trigger your second API here
      // Perform any necessary operations based on the changes in the inputList state
      const queryParams = new URLSearchParams(location.search)
      const project_infoId = queryParams.get('project_infoid')
      const filteredData: any = (folder?.AllProjectInfoData || []).filter(
        (obj: any) => obj.project_infoid === project_infoId
      )
      const projectInfoData = await projectinfo(filteredData[0].project_infoid, list ? list : inputList, fav_voices ? fav_voices : favoriteVoices)

      setinputList(JSON.parse(projectInfoData?.data?.orders))
      setFavoriteVoices(JSON.parse(projectInfoData?.data?.fav_voices))
    } catch (error) {
      console.error('Error fetching updated data:', error)
    }
  }

  useEffect(() => {
    fetchVoices()
    fetchData()
    setIsEnterfull(true)
  }, [])

  useEffect(() => {
    if (favoriteVoices && favoriteVoices.length > 0) {
      fetchUpdatedData("", favoriteVoices);
    }
  }, [favoriteVoices]);

  useEffect(() => {
    if (showTooltip) {
      

      // Hide the message after 3 seconds (adjust as needed)
      const timeoutId = setTimeout(() => {
        setShowTooltip(false);
      }, 3000); // 3000 milliseconds (3 seconds)

      
    }
  }, [showTooltip]);

  const handleinputchange = (
    e:
      | React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
      | React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number,
    b?: string,
    voice?: string,
    voice_photo?: string
  ) => {
    if (index === prevIndex) {
      setIsEnterPrevIndex(true)
    }
    if (b === 'voice') {
      const languageCodes = voice?.split('-')?.slice(0, 2)?.join('-') ?? ''
      const list = [...inputList]
      list[index]['voice_name'] = voice || ''
      list[index]['voice_languageCodes'] = languageCodes || ''
      list[index]['voice_photo'] = voice_photo || ''
      setSelectedOption(voice || '')
      setinputList(list)
      setCreateProj(!createProj)
    } else {
      const target = e.target as HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
      const { name, value } = target
      const list = [...inputList]
      list[index][name] = value
      setinputList(list)
      if (name == 'text') {
        // Parse the value to a number
        // const words = value.trim().split(/\s+/);

  if (value.length <= 350) {
    // If the number of words is less than or equal to 100, assign the value
    const list = [...inputList]

    list[index][name] = value;
    setinputList(list);
    setShowTooltip(false);

  } else {
  
    // If more than 100 words are entered, truncate to the first 100 words
    const list = [...inputList]

    const truncatedValue = value.substring(0, 350);
    list[index][name] = truncatedValue;
    setinputList(list);

    // Set a state variable to control the tooltip visibility or display a message
    const msg = "You have reached the character limit 350 characters (Hint: Use Add More)"
    handleerror(msg)
  }
        
      }
      if (name == 'pitch') {
        // Parse the value to a number
        const parsedValue = parseFloat(value)

        // Calculate the percentage
        const minValue = -20
        const maxValue = 20
        const range = maxValue - minValue
        const halfRange = range / 2
        const offset = (parsedValue - minValue - halfRange) / halfRange
        const percentage = offset * 50

        const list = [...inputList]
        list[index]['pitch_percent'] = percentage.toFixed(0)
        setinputList(list)
      }
      if (name == 'volume') {
        // Parse the value to a number
        const parsedValue = parseFloat(value)

        // Calculate the percentage
        const minValue = -24
        const maxValue = 6
        const range = maxValue - minValue
        const halfRange = range / 2
        const offset = (parsedValue - minValue - halfRange) / halfRange
        const percentage = offset * 50

        const list = [...inputList]
        list[index]['volume_percent'] = percentage.toFixed(0)
        setinputList(list)
      }
      if (name == 'speed') {
        // Parse the value to a number
        const parsedValue = parseFloat(value)

        // Calculate the percentage
        const minValue = 0.25
        const maxValue = 1.75
        const range = maxValue - minValue
        const halfRange = range / 2
        const offset = (parsedValue - minValue - halfRange) / halfRange
        const percentage = offset * 50

        const list = [...inputList]
        list[index]['speed_percent'] = percentage.toFixed(0)
        setinputList(list)
      }
    }
    audio?.pause()
    setIsPlaying(false)
    setIsPlayingfull(false)
    fetchUpdatedData("", "")
    setIsEnterfull(true)
  }

  const handleremove = (index: number) => {
    if (index === prevIndex) {
      setIsEnterPrevIndex(true)
    }

    setIsEnterfull(true)
    const list = [...inputList]
    list.splice(index, 1)
    setinputList(list)
    fetchUpdatedData(list, "")
  }

  const handleerror = (msg:any) => {
    setShowTooltip(true);
    setshowerrmsg(msg);
  }

  const handleaddclick = () => {
    setSelectedGender('')
    setSelectedGenderVoice([])
    setFilteredVoices([])

    setinputList([
      ...inputList,
      {
        text: '',
        pitch: '',
        volume: '',
        speed: '',
        voice_name: '',
        voice_languageCodes: '',
        pitch_percent: '',
        volume_percent: '',
        speed_percent: '',
        voice_photo: '',
        duration: '',
      },
    ])
  }

  const individual_sound = async (voice: any) => {
    if (voice?.text !== null) {
      setPreview(false)
    }
    setPlayingname(voice?.name)
    const data = {
      text: voice?.text,
      pitch: '',
      volume: '4',
      speed: '',
      voice_name: voice?.name,
      voice_languageCodes: voice?.languagecodes,
    }
    const response = await speech(data, currentUser?.company_id || "")
    const audioBlob = new Blob([response.data], { type: 'audio/mp3' })
    const url = URL.createObjectURL(audioBlob)
    const newAudio = new Audio(url)
    newAudio.play()
    newAudio.addEventListener('ended', () => {
      setPreview(true)
    })
  }

  const getAudioDuration = async (audioBlob: any) => {
    return new Promise((resolve) => {
      const audio = new Audio();
      audio.addEventListener('loadedmetadata', () => {
        const durationInSeconds = audio.duration.toFixed(1);
        resolve(parseFloat(durationInSeconds)); // Convert back to a floating-point number
      });
      audio.src = URL.createObjectURL(audioBlob);
    });
  };


  const handleSpeak = async (i: number | 'full', download: any) => {
    try {
      let data
      if ((i !== 'full' && i !== prevIndex) || (isEnterPrevIndex && i !== 'full') || (i === 'full' && isEnterfull)) {
        if (download) {
          if (i === 'full') {
            data = inputList
            setIsEnterfull(false)
            setIsEnterPrevIndex(true)
          }
          else {
            data = inputList[i]
            setPrevIndex(i)
            setIsEnterPrevIndex(false)
            setIsEnterfull(true)
          }
        }
        else {
          if (i === 'full') {
            audio?.pause()
            data = inputList
            // setIsPlayingfull(true)
            setfullvoiceloader(true)
            setIsEnterfull(false)
            setIsEnterPrevIndex(true)
            setIsPlaying(false)

          } else {
            audio?.pause()
            data = inputList[i]
            // setIsPlaying(true)
            setvoiceloader(true)
            setPrevIndex(i)
            setIsEnterPrevIndex(false)
            setIsEnterfull(true)

            setIsPlayingfull(false)

          }
        }

        const response = await speech(data, currentUser?.company_id || "")
        if (response.status == 204) {
          const msg = "Your plan is insufficient to make this request, Please recharge";
          handleerror(msg);
          setvoiceloader(false)
          setfullvoiceloader(false)
        }
        else if (response.status == 404) {
          const msg = "Error synthesizing speech";
          handleerror(msg);
          setvoiceloader(false)
          setfullvoiceloader(false)
        }
        else{

        const audioBlob = new Blob([response.data], { type: 'audio/mp3' })
        const durationInSeconds = await getAudioDuration(audioBlob);
        const url = URL.createObjectURL(audioBlob)
        if (!download) {
          const newAudio = new Audio(url)
          // newAudio.play()
          setvoiceloader(false)
          setfullvoiceloader(false)
          setIsPlaying(false)

          setAudio(newAudio)
          setAudioUrl(url)
          // newAudio.addEventListener('ended', () => {
          //   if (i === 'full') {
          //     setIsPlayingfull(false)
          //   } else {
          //     setIsPlaying(false)
          //   }
          // })
          if (i === "full") {
            setfullvoiceduration(durationInSeconds as number)
          }
          else {
            const list = [...inputList]
            list[i]['duration'] = durationInSeconds as number;
            setinputList(list)
            fetchUpdatedData(list, "")

          }
        }
        if (download) {
          // Create a download link
          const downloadLink = document.createElement('a');
          downloadLink.href = url;
          downloadLink.download = 'audio.mp3';
          downloadLink.style.display = 'none';

          // Append the link to the document body
          document.body.appendChild(downloadLink);

          // Simulate a click on the download link
          downloadLink.click();

          // Clean up by removing the download link from the document body
          document.body.removeChild(downloadLink);
          if (i === "full") {
            setfullvoiceduration(durationInSeconds as number)

          }
          else {
            const list = [...inputList]
            list[i]['duration'] = durationInSeconds as number;
            setinputList(list)
            fetchUpdatedData(list, "")

          }
        }
      }
      } else {
        if (!download) {

          if (isPlaying || isPlayingfull) {
            audio?.pause()
            if (i === 'full') {
              setIsPlayingfull(false)
            } else {
              setIsPlaying(false)
            }
            audio?.addEventListener('ended', () => {
              if (i === 'full') {
                setIsPlayingfull(false)
              } else {
                setIsPlaying(false)
              }
            })
          } else {
            audio?.play()
            if (i === 'full') {
              setIsPlayingfull(true)
            } else {
              setIsPlaying(true)
            }
          }
        }
        if (download) {
          // Create a download link
          const downloadLink = document.createElement('a');
          downloadLink.href = audioUrl;
          downloadLink.download = 'audio.mp3';
          downloadLink.style.display = 'none';

          // Append the link to the document body
          document.body.appendChild(downloadLink);

          // Simulate a click on the download link
          downloadLink.click();

          // Clean up by removing the download link from the document body
          document.body.removeChild(downloadLink);
          setvoiceloader(false)

        }

      }
    } catch (err) {
      console.error(err)
    }
  }

  const handleModalOpen = (index: number, value: string) => {
    setSelectedOption(String(inputList[index].voice_name))
    setSelectedValue(index)
    setCreateProj(true)
    setSelectedGender('')
    setSelectedGenderVoice([])
    setFilteredVoices([])
  }

  const handlePVSIndex = (index: number, value: string) => {
    if (value == 'pitch') {
      setPitchIndex(index)
      setVolumeIndex(null)
      setSpeedIndex(null)
    }
    if (value == 'volume') {
      setPitchIndex(null)
      setVolumeIndex(index)
      setSpeedIndex(null)
    }
    if (value == 'speed') {
      setPitchIndex(null)
      setVolumeIndex(null)
      setSpeedIndex(index)
    }
  }

  // Function to filter voices based on language and country
  const filterVoices = (
    e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>
  ) => {
    setSelectedGender('')
    setSelectedGenderVoice([])
    const query = e.target.value.toLowerCase()

    const filtered = voices.filter((voice) => {
      const language = voice.language ? voice.language.toLowerCase() : ''
      const country = voice.country ? voice.country.toLowerCase() : ''

      // Check if any of the parameters match the query
      const isCountryMatch = country.includes(query)
      const isLanguageMatch = language.includes(query)

      // If the query is empty, include all voices
      if (query === '') {
        return true
      }

      if (isCountryMatch || isLanguageMatch) {
        return true // Any one parameter matches the query
      }

      return false
    })

    setFilteredVoices(filtered)
  }

  const filterVoicesGender = (
    e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>
  ) => {
    setSelectedGender(e.target.value)
    if (filteredVoices.length > 0) {
      const query = e.target.value.toLowerCase()
      const filtered = filteredVoices.filter((voice) => {
        const gender = voice.ssmlgender ? voice.ssmlgender.toLowerCase().trim() : ''

        return gender === query
      })
      setSelectedGenderVoice(filtered)
    } else {
      const query = e.target.value.toLowerCase()
      const filtered = voices.filter((voice) => {
        const gender = voice.ssmlgender ? voice.ssmlgender.toLowerCase().trim() : ''
        return gender === query
      })

      setSelectedGenderVoice(filtered)
    }
  }

  const handleModalDelete = (index: number) => {
    setDeleteIndex(index)
    setIsOpenDelete(true)

  }

  return (
    <>
      <div className='mt-10'>
        <div className='row align-items-center justify-content-center'>
          {inputList.map((x, i) => (
            <>
              <div className='col-lg-10 col-sm-12'>
                <div className='card allcardsections'>
                  <div className='card-body removeaddbtn'>
                    <div className='d-flex align-items-center justify-content-end mb-2'>
                      {inputList.length !== 1 && (
                        <div
                          className='closeSec text-hover-primary cursor-pointer d-flex align-items-center text-gray-600 pt-3 px-3'
                          onClick={() => {

                            handleModalDelete(i)
                          }}
                        >
                          <KTSVG
                            path='/media/icons/duotune/arrows/arr061.svg'
                            className='svg-icon-1'
                          />
                        </div>
                      )}
                    </div>

                    <div className='alltrgtdtls'>
                      <div className='d-flex align-items-center justify-content-end mb-2'>
                        <ul className='menu menu-gray-600 menu-hover-primary fw-bold order-1 flex-wrap'>
                          <li className='menu-item'>
                            <p className='py-1 px-3 ms-3 text-muted'>{x?.duration}s | {x?.duration}s</p>
                          </li>
                          <li className='menu-item'>
                            <div
                              className='border-secondary border py-1 px-3 ms-3 setingPitch'
                              data-kt-menu-trigger={`click-${i}`}
                              data-kt-menu-placement='bottom-end'
                              data-kt-menu-flip='top-end'
                              onClick={() => handlePVSIndex(i, 'pitch')}
                            >
                              <>
                                <span className='fw400'>Pitch</span>

                                <span className='ms-2'>{x?.pitch_percent}%</span>
                              </>
                            </div>
                            <div
                              className={`menu menu-sub menu-sub-dropdown w-250px w-md-300px${PitchIndex === i ? ' show_ed' : ''
                                }`}
                              data-kt-menu={`true-${i}`}
                            >
                              {PitchIndex === i && (
                                <>
                                  <div className='row justify-content-center'>
                                    <div className='col-sm-12 col-md-12 col-lg-12'>
                                      <div className='px-7 py-5 d-flex align-items-center'>
                                        <div className='fs-5 text-dark fw-bolder'>Adjust Pitch</div>
                                        <div className='flex-grow-1'></div>
                                        <div
                                          className='d-flex justify-content-end btn btn-icon btn-sm btn-active-light-primary align-items-center'
                                          onClick={() => setPitchIndex(null)}
                                        >
                                          <KTSVG
                                            path='/media/icons/duotune/arrows/arr061.svg'
                                            className='svg-icon-3x '
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className='separator border-gray-200'></div>
                                  <div className='px-7 py-5'>
                                    <div className='mb-5'></div>
                                    <div className='slidecontainer mb-5'>
                                      <input
                                        name='pitch'
                                        type='range'
                                        className='form- w slider'
                                        min='-20'
                                        max='20'
                                        step='1'
                                        onChange={(e) => handleinputchange(e, i)}
                                        value={
                                          PitchIndex !== null ? inputList[PitchIndex].pitch : ''
                                        }
                                      />
                                      <ul className='d-flex align-items-center justify-content-between list-style-none p-0 pt-3'>
                                        <li>-50%</li>
                                        <li>-25%</li>
                                        <li>0%</li>
                                        <li>+25%</li>
                                        <li>+50%</li>
                                      </ul>
                                    </div>
                                    {/* <div className='d-flex justify-content-end'>
                                      <button
                                        type='reset'
                                        className='btn btn-sm btn-light btn-active-light-primary me-2'
                                        data-kt-menu-dismiss='true'
                                      >
                                        Reset to default
                                      </button>
                                    </div> */}
                                  </div>
                                </>
                              )}
                            </div>
                          </li>
                          <li className='menu-item'>
                            <div
                              className='border-secondary border py-1 px-3 ms-3 setingPitch'
                              data-kt-menu-trigger={`click-${i}`}
                              data-kt-menu-placement='bottom-end'
                              data-kt-menu-flip='top-end'
                              onClick={() => handlePVSIndex(i, 'volume')}
                            >
                              <span className='fw400'>Volume</span>
                              <span className='ms-2'>{x?.volume_percent}%</span>
                            </div>
                            <div
                              className={`menu menu-sub menu-sub-dropdown w-250px w-md-300px${VolumeIndex === i ? ' show_ed' : ''
                                }`}
                              data-kt-menu={`true-${i}`}
                            >
                              {VolumeIndex === i && (
                                <>
                                  <div className='row justify-content-center'>
                                    <div className='col-sm-12 col-md-12 col-lg-12'>
                                      <div className='px-7 py-5 d-flex align-items-center'>
                                        <div className='fs-5 text-dark fw-bolder'>
                                          Adjust Volume
                                        </div>
                                        <div className='flex-grow-1'></div>
                                        <div
                                          className='d-flex justify-content-end btn btn-icon btn-sm btn-active-light-primary align-items-center'
                                          onClick={() => setVolumeIndex(null)}
                                        >
                                          <KTSVG
                                            path='/media/icons/duotune/arrows/arr061.svg'
                                            className='svg-icon-3x '
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className='separator border-gray-200'></div>
                                  <div className='px-7 py-5'>
                                    <div className='mb-5'></div>
                                    <div className='slidecontainer mb-5'>
                                      <input
                                        name='volume'
                                        type='range'
                                        className='form- w slider'
                                        min='-24'
                                        max='6'
                                        step='1'
                                        onChange={(e) => handleinputchange(e, i)}
                                        value={
                                          VolumeIndex !== null ? inputList[VolumeIndex].volume : ''
                                        }
                                      />
                                      <ul className='d-flex align-items-center justify-content-between list-style-none p-0 pt-3'>
                                        <li>-50%</li>
                                        <li>-25%</li>
                                        <li>0%</li>
                                        <li>+25%</li>
                                        <li>+50%</li>
                                      </ul>
                                    </div>
                                    {/* <div className='d-flex justify-content-end'>
                                      <button
                                        type='reset'
                                        className='btn btn-sm btn-light btn-active-light-primary me-2'
                                        data-kt-menu-dismiss='true'
                                      >
                                        Reset to default
                                      </button>
                                    </div> */}
                                  </div>
                                </>
                              )}
                            </div>
                          </li>
                          <li className='menu-item'>
                            <div
                              className='border-secondary border py-1 px-3 ms-3 setingPitch'
                              data-kt-menu-trigger={`click-${i}`}
                              data-kt-menu-placement='bottom-end'
                              data-kt-menu-flip='top-end'
                              onClick={() => handlePVSIndex(i, 'speed')}
                            >
                              <span className='fw400'>Speed</span>
                              <span className='ms-2'>{x?.speed_percent}%</span>
                            </div>
                            <div
                              className={`menu menu-sub menu-sub-dropdown w-250px w-md-300px${SpeedIndex === i ? ' show_ed' : ''
                                }`}
                              data-kt-menu={`true-${i}`}
                            >
                              {SpeedIndex === i && (
                                <>
                                  <div className='row justify-content-center'>
                                    <div className='col-sm-12 col-md-12 col-lg-12'>
                                      <div className='px-7 py-5 d-flex align-items-center'>
                                        <div className='fs-5 text-dark fw-bolder'>Adjust Speed</div>
                                        <div className='flex-grow-1'></div>
                                        <div
                                          className='d-flex justify-content-end btn btn-icon btn-sm btn-active-light-primary align-items-center'
                                          onClick={() => setSpeedIndex(null)}
                                        >
                                          <KTSVG
                                            path='/media/icons/duotune/arrows/arr061.svg'
                                            className='svg-icon-3x '
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className='separator border-gray-200'></div>
                                  <div className='px-7 py-5'>
                                    <div className='mb-5'></div>
                                    <div className='slidecontainer mb-5'>
                                      <input
                                        name='speed'
                                        type='range'
                                        className='form- w slider'
                                        min='0.25'
                                        max='1.75'
                                        step='0.01'
                                        onChange={(e) => handleinputchange(e, i)}
                                        value={
                                          SpeedIndex !== null ? inputList[SpeedIndex].speed : ''
                                        }
                                      />
                                      <ul className='d-flex align-items-center justify-content-between list-style-none p-0 pt-3'>
                                        <li>-50%</li>
                                        <li>-25%</li>
                                        <li>0%</li>
                                        <li>+25%</li>
                                        <li>+50%</li>
                                      </ul>
                                    </div>
                                    {/* <div className='d-flex justify-content-end'>
                                      <button
                                        type='reset'
                                        className='btn btn-sm btn-light btn-active-light-primary me-2'
                                        data-kt-menu-dismiss='true'
                                      >
                                        Reset to default
                                      </button>
                                    </div> */}
                                  </div>
                                </>
                              )}
                            </div>
                          </li>
                        </ul>
                      </div>

                      <div className='position-relative d-flex'>
                        {/* <i className="bi-grip-vertical cursor-pointer fs18 me-2 text-hover-primary"></i> */}
                        <div
                          onClick={() => handleModalOpen(i, String(x.voice_name) || '')}
                          className='cursor-pointer symbol symbol-40px me-3'
                        >
                          {x.voice_photo ? (
                            <img
                              src={process.env.REACT_APP_API_URL + '/' + x.voice_photo}
                              alt='avatar'
                            />
                          ) : (
                            <img src={toAbsoluteUrl('/media/avatars/300-2.jpg')} alt='avatar' />
                          )}
                        </div>
                        {/* <input placeholder="Enter your text here" className="form-control bg-transparent" /> */}
                        <div onClick={() => handleSpeak(i, "")}>
                          {prevIndex === i && voiceloader ? (
                            <span className='spinner-border spinner-border-lg align-middle ms-2'></span>
                          ) : (
                            <>
                              {prevIndex === i && isPlaying ? (
                                <i className='bi-pause-circle-fill fs18 me-2 text-hover-primary cursor-pointer playBtn'></i>
                              ) : (
                                <i className='bi-play-circle-fill fs18 me-2 text-hover-primary cursor-pointer playBtn'></i>
                              )}
                            </>
                          )}
                        </div>

                        <div onClick={() => handleSpeak(i, "download")} className='ind_download'>
                          <i className="bi bi-file-arrow-down-fill fs18 me-2 text-hover-primary cursor-pointer playBtn"></i>
                        </div>

                        {/* <i className="bi-play-circle-fill fs18 me-2 text-hover-primary cursor-pointer playBtn"></i> */}
<>
                        <textarea
                          className='form-control form-control-solid px-12'
                          rows={3}
                          name='text'
                          placeholder='Type Target Details'
                          value={x.text}
                          onChange={(e) => handleinputchange(e, i)}
                        ></textarea>
                        {showTooltip && (
        <div className='flash-message'>
        {showerrmsg}
      </div>
      )}
      </>
                        {/* <div className='position-absolute voiceIconContainer'>
                          <button
                            type='button'
                            className='btn btn-sm btn-icon btn-active-light-primary'
                            data-kt-menu-trigger='click'
                            data-kt-menu-placement='bottom-end'
                            data-kt-menu-flip='top-end'
                          >
                            <i className='bi-three-dots-vertical svg-icon-2'></i>
                          </button>
                          <Dropdown1 />
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='col-lg-10 col-sm-12'>
                <div className='d-flex align-items-center justify-content-center mt-5'>
                  {/* {inputList.length !== 1 && (
                      <div
                        className='text-hover-primary cursor-pointer d-flex align-items-center text-gray-600 addMoreBorder py-1 px-4 '
                        onClick={() => handleremove(i)}
                      >
                        <i className='bi-plus-circle fs18 me-2'></i>
                        <span className=''>Remove</span>
                      </div>
                    )} */}
                  {inputList.length - 1 === i && (
                    <div
                      className='text-hover-primary cursor-pointer d-flex align-items-center text-gray-600 addMoreBorder py-1 px-4 addmorebtn'
                      onClick={handleaddclick}
                    >
                      <i className='bi-plus-circle fs18 me-2'></i>
                      <span className='fw600'>Add More</span>
                    </div>
                  )}
                </div>
              </div>

              <Modal
                aria-hidden='true'
                size='xl'
                show={createProj}
                scrollable={true}
                animation={true}
              >
                <div className='modal-content'>
                  <div className='modal-header soundSerch'>
                    <div className='modal-title'>
                      <h3 className='mb-1'>Sound</h3>
                      <h6 className='text-gray-400'>Extensive language and voice coverage</h6>
                    </div>

                    <div className='position-relative me-5 mt-2 w-50'>
                      <i className='bi-search fs18 me-2 position-absolute searchIconsStyle'></i>
                      <input
                        type='text'
                        placeholder='Search by language or country '
                        autoComplete='off'
                        onChange={(e) => filterVoices(e)}
                        className='form-control bg-light ps-10 searchBox w-100'
                      />
                    </div>

                    <div className='w-150px me-5 mt-2 custPad'>
                      <select
                        className='form-control'
                        value={selectedGender}
                        name='voices'
                        onChange={(e) => filterVoicesGender(e)}
                      >
                        <option value='' disabled selected>
                          Gender...
                        </option>
                        <option value='Male'>Male</option>
                        <option value='Female'>Female</option>
                      </select>
                    </div>

                    <div
                      className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                      onClick={() => setCreateProj(!createProj)}
                    >
                      <KTSVG
                        path='/media/icons/duotune/arrows/arr061.svg'
                        className='svg-icon-2x'
                      />
                    </div>
                  </div>

                  <div className='mobileSoundSerch px-7 py-8'>
                    <div className='d-flex align-items-center justify-content-between'>
                      <div className='modal-title'>
                        <h3 className='mb-1'>Sound</h3>
                        <h6 className='text-gray-400'>Extensive language and voice coverage</h6>
                      </div>

                      <div
                        className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                        onClick={() => setCreateProj(!createProj)}
                      >
                        <KTSVG
                          path='/media/icons/duotune/arrows/arr061.svg'
                          className='svg-icon-2x'
                        />
                      </div>
                    </div>

                    <div className='row col-sm-12 allSearchField mt-5'>
                      <div className='col-sm-7 position-relative mt-2s mt-2'>
                        <div className='searchFieldRgt'>
                          <i className='bi-search fs18 me-2 position-absolute searchIconsStyle'></i>
                          <input
                            type='text'
                            placeholder='Search by language or country '
                            autoComplete='off'
                            onChange={(e) => filterVoices(e)}
                            className='form-control bg-light ps-10 searchBox w-100'
                          />
                        </div>
                      </div>

                      <div className='mt-2 custPad mt-2 col-sm-5'>
                        <div className='searchFieldLft'>
                          <select
                            className='form-control'
                            value={selectedGender}
                            name='voices'
                            onChange={(e) => filterVoicesGender(e)}
                          >
                            <option value='' disabled selected>
                              Gender...
                            </option>
                            <option value='Male'>Male</option>
                            <option value='Female'>Female</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='modal-body'>
                    <div>
                      <div className='row justify-content-center'>
                        <div className='col-sm-12 col-md-12 col-lg-12'>
                          <ul className='menu menu-gray-600 menu-hover-primary fw-bold order-1 flex-wrap'>
                            <li className='menu-item'>
                              <div className='w-150px me-5 mt-2 custPad'>
                                {/* <select className="form-control" name="voices" onChange={(e) => filterVoices(e)}>
                                    <option value="" disabled selected>Countries...</option>
                                    {uniqueCountries.map(country => (
                                      <option key={country} value={country}>{country}</option>
                                    ))}
                                  </select> */}
                              </div>
                            </li>
                            {/* <li className='menu-item'>
                                <div className="w-200px me-5 mt-2">
                                  <select className="form-select form-select-solid genderCon form-select-sm" data-control="select2" data-hide-search="true">
                                    <option value="1">All Age Group</option>
                                    <option value="2">Young</option>
                                    <option value="3">Middle Age</option>
                                    <option value="4">Kids</option>
                                  </select>
                                </div>
                              </li> */}
                            {/* <li className='menu-item'>
                                <div className="w-200px me-5 mt-2">
                                  <select className="form-select form-select-solid genderCon form-select-sm" data-control="select2" data-hide-search="true">
                                    <option value="1">All Age Group</option>
                                    <option value="2">Young</option>
                                    <option value="3">Middle Age</option>
                                    <option value="4">Kids</option>
                                  </select>
                                </div>
                              </li> */}
                            {/* <li className='menu-item'>
                                <div className="w-150px me-5 mt-2 custPad">
                                  <select className="form-control" value={selectedGender} name="voices" onChange={(e) => filterVoicesGender(e)}>
                                    <option value="" disabled selected>Gender...</option>
                                    <option value="Male">Male</option>
                                    <option value="Female">Female</option>
                                  </select>
                                </div> */}
                            {/* <ul className='nav bg-light genderCon me-5 mt-2'>
                                  <li className='nav-item' onClick={(e) => filterVoices(e)}>
                                    <a
                                      className='genderCon nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary active fw-bold px-4 me-1'
                                      data-bs-toggle='tab'
                                      href='#kt_table_widget_4_tab_1'
                                    >
                                      Male
                                    </a>
                                  </li>
                                  <li className='nav-item'>
                                    <a
                                      className='genderCon nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bold px-4 me-1'
                                      data-bs-toggle='tab'
                                      href='#kt_table_widget_4_tab_2'
                                    >
                                      Female
                                    </a>
                                  </li>
                                </ul> */}
                            {/* </li> */}
                            {/* <li className='menu-item'>
                                <div className="w-200px me-5 mt-2">
                                  <select className="form-select form-select-solid genderCon form-select-sm" data-control="select2" data-hide-search="true">
                                    <option value="1">Advertisements</option>
                                    <option value="2">Audiobooks</option>
                                    <option value="3">Documentary</option>
                                    <option value="4">E-Learning & Presentations</option>
                                    <option value="4">Explainer Videos</option>
                                    <option value="4">Product Demos</option>
                                  </select>
                                </div>
                              </li>
                              <li className='menu-item d-flex align-items-center'>
                                <div className='form-check form-check-solid mt-2'>
                                  <input
                                    className='form-check-input'
                                    type='checkbox'
                                    value=''
                                    id='kt_settings_notification_phone'
                                  // defaultChecked={data.notifications.phone}
                                  // onChange={() =>
                                  //   updateData({
                                  //     notifications: {
                                  //       phone: !data.notifications.phone,
                                  //       email: data.notifications.email,
                                  //     },
                                  //   })
                                  // }
                                  />
                                  <label
                                    className='form-check-label ps-2'
                                    htmlFor='kt_settings_notification_phone'
                                  >
                                    Choose a voice and apply it to the entire project
                                  </label>
                                </div>
                              </li> */}
                          </ul>
                        </div>

                        <div className='col-sm-12 col-md-12 col-lg-12'>

                          <div className='mb-15'>
                            <h4 className='text-gray-800 fw-bolder text-center'>
                              Here are some suggestions to try !
                            </h4>
                          </div>

                          <div className='row g-4'>
                            <>
                              {selectedGenderVoice.length > 0 ? (
                                <>
                                  {selectedGenderVoice.map((voice) => (
                                    <div
                                      className={`col-sm-12 col-lg-3 col-mg-4 ${selectedOption === voice?.name ? 'selected_voice' : ''
                                        }`}
                                      onClick={(e) =>
                                        handleinputchange(
                                          e,
                                          selectedValue ?? 0,
                                          'voice',
                                          voice?.name,
                                          voice?.photo
                                        )
                                      }
                                    >
                                      <div className='card serviceEffect'>
                                        <div className='d-flex align-items-center justify-content-center flex-column mt-1 position-relative'>
                                          {favoriteVoices.includes(voice?.name) ? (
                                            <i
                                              className='bi-heart-fill cursor-pointer fs18 position-absolute heartIcons text-danger'
                                              onClick={() => handleToggleFavorite(voice?.name)}
                                            ></i>
                                          ) : (
                                            <i
                                              className='bi-heart cursor-pointer fs18 position-absolute heartIcons'
                                              onClick={() => handleToggleFavorite(voice?.name)}
                                            ></i>
                                          )}

                                          {preview === true ? (
                                            <i
                                              className={`bi-play-circle fs18 position-absolute previewSound `}
                                              onClick={() => individual_sound(voice)}
                                            ></i>
                                          ) : playingname === voice?.name ? (
                                            <i className='bi-pause-circle fs18 position-absolute previewSound'></i>
                                          ) : (
                                            <i className='bi-play-circle fs18 position-absolute previewSound'></i>
                                          )}

                                          {/* <div onClick={() => individual_sound(voice)}>{prevIndex == i && isPlaying ? <i className="bi-pause-circle-fill fs18 me-2 text-hover-primary cursor-pointer playBtn"></i> : <i className="bi-play-circle-fill fs18 me-2 text-hover-primary cursor-pointer playBtn"></i>}</div> */}

                                          <div className='cursor-pointer symbol symbol-60px me-3'>
                                            <img
                                              src={
                                                process.env.REACT_APP_API_URL + '/' + voice?.photo
                                              }
                                              alt='avatar'
                                            />
                                          </div>
                                          <h4 className='mt-5'>{voice?.title}{voice?.ssmlgender === "MALE" ? "(M)" : (voice?.ssmlgender === "FEMALE" ? "(F)" : voice?.ssmlgender)}</h4>
                                          {/* <p className='text-gray-500 fw-bold fs-5 mb-1'>
                                            Young adult
                                          </p> */}
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                                </>
                              ) : filteredVoices.length > 0 ? (
                                <>
                                  {filteredVoices.map((voice) => (
                                    <div
                                      className={`col-sm-12 col-lg-3 col-mg-4 ${selectedOption === voice?.name ? 'selected_voice' : ''
                                        }`}
                                      onClick={(e) =>
                                        handleinputchange(
                                          e,
                                          selectedValue ?? 0,
                                          'voice',
                                          voice?.name,
                                          voice?.photo
                                        )
                                      }
                                    >
                                      <div className='card serviceEffect'>
                                        <div className='d-flex align-items-center justify-content-center flex-column mt-1 position-relative'>
                                          {favoriteVoices.includes(voice?.name) ? (
                                            <i
                                              className='bi-heart-fill cursor-pointer fs18 position-absolute heartIcons text-danger'
                                              onClick={() => handleToggleFavorite(voice?.name)}
                                            ></i>
                                          ) : (
                                            <i
                                              className='bi-heart cursor-pointer fs18 position-absolute heartIcons'
                                              onClick={() => handleToggleFavorite(voice?.name)}
                                            ></i>
                                          )}

                                          {preview === true ? (
                                            <i
                                              className={`bi-play-circle fs18 position-absolute previewSound `}
                                              onClick={() => individual_sound(voice)}
                                            ></i>
                                          ) : playingname === voice?.name ? (
                                            <i className='bi-pause-circle fs18 position-absolute previewSound'></i>
                                          ) : (
                                            <i className='bi-play-circle fs18 position-absolute previewSound'></i>
                                          )}

                                          {/* <div onClick={() => individual_sound(voice)}>{prevIndex == i && isPlaying ? <i className="bi-pause-circle-fill fs18 me-2 text-hover-primary cursor-pointer playBtn"></i> : <i className="bi-play-circle-fill fs18 me-2 text-hover-primary cursor-pointer playBtn"></i>}</div> */}

                                          <div className='cursor-pointer symbol symbol-60px me-3'>
                                            <img
                                              src={
                                                process.env.REACT_APP_API_URL + '/' + voice?.photo
                                              }
                                              alt='avatar'
                                            />
                                          </div>
                                          <h4 className='mt-5'>{voice?.title}{voice?.ssmlgender === "MALE" ? "(M)" : (voice?.ssmlgender === "FEMALE" ? "(F)" : voice?.ssmlgender)}</h4>
                                          {/* <p className='text-gray-500 fw-bold fs-5 mb-1'>
                                            Young adult
                                          </p> */}
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                                </>
                              ) : (
                                <>
                                  {voices.map((voice) => (
                                    <div
                                      className={`col-sm-12 col-lg-3 col-mg-4 ${selectedOption === voice?.name ? 'selected_voice' : ''
                                        }`}
                                      onClick={(e) =>
                                        handleinputchange(
                                          e,
                                          selectedValue ?? 0,
                                          'voice',
                                          voice?.name,
                                          voice?.photo
                                        )
                                      }
                                    >
                                      <div className='card serviceEffect'>
                                        <div className='d-flex align-items-center justify-content-center flex-column mt-1 position-relative'>
                                          <>
                                            {favoriteVoices.includes(voice?.name) ? (
                                              <i
                                                className='bi-heart-fill cursor-pointer fs18 position-absolute heartIcons text-danger'
                                                onClick={(e) => {
                                                  e.stopPropagation(); // Prevent click from bubbling
                                                  handleToggleFavorite(voice?.name);
                                                }}
                                              ></i>
                                            ) : (
                                              <i
                                                className='bi-heart cursor-pointer fs18 position-absolute heartIcons'
                                                onClick={(e) => {
                                                  e.stopPropagation(); // Prevent click from bubbling
                                                  handleToggleFavorite(voice?.name);
                                                }}
                                              ></i>
                                            )}

{preview === true ? (
  <span
    className={`bi-play-circle fs18 position-absolute previewSound `}
    onClick={(e) => {
      e.stopPropagation(); // Prevent click from bubbling
      individual_sound(voice);
    }}
  ></span>
) : playingname === voice?.name ? (
  <i onClick={(e) => {
    e.stopPropagation(); // Prevent click from bubbling
    
  }}className='bi-pause-circle fs18 position-absolute previewSound'></i>
) : (
  <i onClick={(e) => {
    e.stopPropagation(); // Prevent click from bubbling
    
  }} className='bi-play-circle fs18 position-absolute previewSound'></i>
)}


                                            {/* <div onClick={() => individual_sound(voice)}>{prevIndex == i && isPlaying ? <i className="bi-pause-circle-fill fs18 me-2 text-hover-primary cursor-pointer playBtn"></i> : <i className="bi-play-circle-fill fs18 me-2 text-hover-primary cursor-pointer playBtn"></i>}</div> */}

                                            <div className='cursor-pointer symbol symbol-60px me-3'>
                                              <img
                                                src={
                                                  process.env.REACT_APP_API_URL + '/' + voice?.photo
                                                }
                                                alt='avatar'
                                              />
                                            </div>
                                            <h4 className='mt-5'>{voice?.title}{voice?.ssmlgender === "MALE" ? "(M)" : (voice?.ssmlgender === "FEMALE" ? "(F)" : voice?.ssmlgender)}</h4>
                                            {/* <p className='text-gray-500 fw-bold fs-5 mb-1'>
                                          {voice?.ssmlgender === "MALE" ? "(M)" : (voice?.ssmlgender === "FEMALE" ? "(F)" : voice?.ssmlgender)}
                                          </p> */}
                                          </>
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                                </>
                              )}
                            </>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal>

              <Modal
                aria-hidden='true'
                show={isOpenDelete}
                scrollable={true}
                animation={true}
                centered
              >
                <div className='modal-content'>
                  <div className='modal-header pb-0 border-0 justify-content-end'>
                    <div
                      className='btn btn-sm btn-icon btn-active-color-primary'
                      onClick={() => setIsOpenDelete(false)}
                    >
                      <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
                    </div>
                  </div>
                  <div className='modal-body scroll-y mx-xl-8 pt-0 pb-10'>
                    <div className='renametxt mb-3'>
                      <h1 className='mb-3'>Confirm Deletion</h1>
                      <h6 className='text-muted fw-bold fs-5'>Are you sure you want to delete?</h6>
                    </div>
                    <div className='allrename'>
                      <div className='primarybtn'>
                        <button
                          className='btn btn-lg btn-primary w-40 mb-3'
                          onClick={() => {
                            if (DeleteIndex !== null) {
                              handleremove(DeleteIndex);
                              setIsOpenDelete(false);
                            }
                          }}
                        >
                          Yes
                        </button>
                      </div>
                      <div className='primarybtn'>
                        <button
                          className='btn btn-lg btn-primary w-40 mb-3'
                          onClick={() => setIsOpenDelete(false)}
                        >
                          No
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal>
            </>
          ))}
          <div className='d-flex justify-content-center'>
            <div className='row align-items-center justify-content-center g-4 w-850px fi_bu downPlayBtn'>
              <div className='form-group col-md-12 h-100 playSlider d-flex mb-4'>
                <button
                  className='form-control btn btn-sm btn-light btn-active-light-primary me-3'
                  onClick={() => handleSpeak('full', '')}
                >
                  {fullvoiceloader ? (
                    <span className='spinner-border spinner-border-lg align-middle ms-2'></span>
                  ) : (
                    <>
                      {isPlayingfull ? (
                        <>
                          <i className='bi-pause-circle-fill fs28 text-hover-primary cursor-pointer'></i>&nbsp;&nbsp;
                          {fullvoiceduration ? `[${fullvoiceduration}s | ${fullvoiceduration}s]` : ''}

                        </>
                      ) : (
                        <>
                          <i className='bi-play-circle-fill fs28 text-hover-primary cursor-pointer'></i>&nbsp;&nbsp;
                          {fullvoiceduration ? `[${fullvoiceduration}s | ${fullvoiceduration}s]` : ''}

                        </>
                      )}
                    </>
                  )}
                </button>
                <button
                  className='form-control btn btn-sm btn-light btn-active-light-primary'
                  onClick={() => handleSpeak('full', 'download')}
                >
                  Download &nbsp; {fullvoiceduration ? `[${fullvoiceduration}s | ${fullvoiceduration}s]` : ''}

                </button>
              </div>
            </div>
          </div>


        </div>
      </div>
    </>
  )
}

const WorkSpaceWrapper = () => {
  const intl = useIntl()
  console.log('useIntl>>laguage3', intl)
  return (
    <>
      <PageTitle breadcrumbs={dashboardBreadCrumbs}>
        {intl.formatMessage({ id: 'MENU.DASHBOARD' })}
      </PageTitle>
      <WorkSpace />
    </>
  )
}

export { WorkSpaceWrapper }
