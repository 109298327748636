import {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import {Modal} from 'react-bootstrap'
import {useNavigate, useLocation} from 'react-router-dom'
import {useAuth} from '../../../modules/auth/core/Auth'


// const dashboardBreadCrumbs: Array<PageLink> = [
//   {
//     title: 'Home',
//     path: '/dashboard',
//     isSeparator: false,
//     isActive: false,
//   },
// ]


export function Plans() {
 
  const {saveAuth, currentUser, folder, setfolder} = useAuth()
  const [isMonthly, setIsMonthly] = useState(true)

  const [matchingProjects, setmatchingProjects] = useState<any[]>([])
  
  const navigate = useNavigate()
  const location = useLocation()


  // useEffect(() => {
  //   const queryParams = new URLSearchParams(location.search)
  //   const folderId = queryParams.get('folderid')
  //   const filteredData: any[] = (folder?.AllProjectInfoData || []).filter(
  //     (obj: any) => obj.folderid === folderId
  //   )
  //   const projectIds = filteredData.map((obj: any) => obj.projectid)
  //   const matchingProjects = (folder?.project || []).filter((obj: any) =>
  //     projectIds.includes(obj.projectid)
  //   )
  //   if (matchingProjects.length === 0) {
  //     navigate(`/dashboard/?folderid=${folderId}`)
  //   }
  //   setmatchingProjects(matchingProjects)
  // }, [folder])

  


  const handleMonthlyClick = () => {
    setIsMonthly(true)
  }

  const handleAnnualClick = () => {
    setIsMonthly(false)
  }

  return (
    <>
      <div className='content d-flex flex-column flex-column-fluid' id='kt_content'>
        <div className='mb-20' id='kt_content_container'>
          <div className='card' id='kt_pricing'>
            <div className='card-body p-lg-17'>
              <div className='d-flex flex-column'>
                <div className='mb-13 text-center'>
                  <h1 className='fs-2hx fw-bold mb-5'>Choose Your Plan</h1>
                  <div className='text-gray-400 fw-semibold fs-5'>
                    If you need more info about our pricing, please check
                    <a href='#' className='link-primary fw-bold'>
                      Pricing Guidelines
                    </a>
                    .
                  </div>
                </div>

                <div className='nav-group nav-group-outline mx-auto mb-15' data-kt-buttons='true'>
                  <button
                    className={`btn btn-color-gray-400 btn-active btn-active-secondary px-6 py-3 me-2 ${
                      isMonthly ? 'active' : ''
                    }`}
                    onClick={handleMonthlyClick}
                  >
                    Monthly
                  </button>
                  <button
                    className={`btn btn-color-gray-400 btn-active btn-active-secondary px-6 py-3 ${
                      !isMonthly ? 'active' : ''
                    }`}
                    onClick={handleAnnualClick}
                  >
                    Annual
                  </button>
                </div>

                <div className='row g-10'>
                  <div className='col-xl-4'>
                    <div className='d-flex h-100 align-items-center'>
                      <div className='w-100 d-flex flex-column flex-center rounded-3 bg-light bg-opacity-75 py-15 px-10'>
                        <div className='mb-7 text-center'>
                          <h1 className='text-dark mb-5 fw-bolder'>Startup</h1>

                          <div className='text-gray-400 fw-semibold mb-5'>
                            Optimal for 10+ team size
                            <br />
                            and new startup
                          </div>

                          <div className='text-center'>
                            <span className='mb-2 text-primary'>$</span>
                            {isMonthly ? (
                              <span className='fs-3x fw-bold text-primary'>39</span>
                            ) : (
                              <span className='fs-3x fw-bold text-primary'>399</span>
                            )}
                            <span className='fs-7 fw-semibold opacity-50'>
                              /<span data-kt-element='period'>Mon</span>
                            </span>
                          </div>

                          {/* <div className='text-center'>
                            <span className='mb-2 text-primary'>$</span>
                            <span
                              className='fs-3x fw-bold text-primary'
                              data-kt-plan-price-month='39'
                              data-kt-plan-price-annual='399'
                            >
                              39
                            </span>
                            <span className='fs-7 fw-semibold opacity-50'>
                              /<span data-kt-element='period'>Mon</span>
                            </span>
                          </div> */}
                        </div>

                        <div className='w-100 mb-10'>
                          <div className='d-flex align-items-center mb-5'>
                            <span className='fw-semibold fs-6 text-gray-800 flex-grow-1 pe-3'>
                              Up to 10 Active Users
                            </span>

                            <span className='svg-icon svg-icon-1 svg-icon-success'>
                              <svg
                                width='24'
                                height='24'
                                viewBox='0 0 24 24'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <rect
                                  opacity='0.3'
                                  x='2'
                                  y='2'
                                  width='20'
                                  height='20'
                                  rx='10'
                                  fill='currentColor'
                                />
                                <path
                                  d='M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z'
                                  fill='currentColor'
                                />
                              </svg>
                            </span>
                          </div>

                          <div className='d-flex align-items-center mb-5'>
                            <span className='fw-semibold fs-6 text-gray-800 flex-grow-1 pe-3'>
                              Up to 30 Project Integrations
                            </span>

                            <span className='svg-icon svg-icon-1 svg-icon-success'>
                              <svg
                                width='24'
                                height='24'
                                viewBox='0 0 24 24'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <rect
                                  opacity='0.3'
                                  x='2'
                                  y='2'
                                  width='20'
                                  height='20'
                                  rx='10'
                                  fill='currentColor'
                                />
                                <path
                                  d='M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z'
                                  fill='currentColor'
                                />
                              </svg>
                            </span>
                          </div>

                          <div className='d-flex align-items-center mb-5'>
                            <span className='fw-semibold fs-6 text-gray-800 flex-grow-1 pe-3'>
                              Analytics Module
                            </span>

                            <span className='svg-icon svg-icon-1 svg-icon-success'>
                              <svg
                                width='24'
                                height='24'
                                viewBox='0 0 24 24'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <rect
                                  opacity='0.3'
                                  x='2'
                                  y='2'
                                  width='20'
                                  height='20'
                                  rx='10'
                                  fill='currentColor'
                                />
                                <path
                                  d='M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z'
                                  fill='currentColor'
                                />
                              </svg>
                            </span>
                          </div>

                          <div className='d-flex align-items-center mb-5'>
                            <span className='fw-semibold fs-6 text-gray-400 flex-grow-1'>
                              Finance Module
                            </span>

                            <span className='svg-icon svg-icon-1'>
                              <svg
                                width='24'
                                height='24'
                                viewBox='0 0 24 24'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <rect
                                  opacity='0.3'
                                  x='2'
                                  y='2'
                                  width='20'
                                  height='20'
                                  rx='10'
                                  fill='currentColor'
                                />
                                <rect
                                  x='7'
                                  y='15.3137'
                                  width='12'
                                  height='2'
                                  rx='1'
                                  transform='rotate(-45 7 15.3137)'
                                  fill='currentColor'
                                />
                                <rect
                                  x='8.41422'
                                  y='7'
                                  width='12'
                                  height='2'
                                  rx='1'
                                  transform='rotate(45 8.41422 7)'
                                  fill='currentColor'
                                />
                              </svg>
                            </span>
                          </div>

                          <div className='d-flex align-items-center mb-5'>
                            <span className='fw-semibold fs-6 text-gray-400 flex-grow-1'>
                              Accounting Module
                            </span>

                            <span className='svg-icon svg-icon-1'>
                              <svg
                                width='24'
                                height='24'
                                viewBox='0 0 24 24'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <rect
                                  opacity='0.3'
                                  x='2'
                                  y='2'
                                  width='20'
                                  height='20'
                                  rx='10'
                                  fill='currentColor'
                                />
                                <rect
                                  x='7'
                                  y='15.3137'
                                  width='12'
                                  height='2'
                                  rx='1'
                                  transform='rotate(-45 7 15.3137)'
                                  fill='currentColor'
                                />
                                <rect
                                  x='8.41422'
                                  y='7'
                                  width='12'
                                  height='2'
                                  rx='1'
                                  transform='rotate(45 8.41422 7)'
                                  fill='currentColor'
                                />
                              </svg>
                            </span>
                          </div>

                          <div className='d-flex align-items-center mb-5'>
                            <span className='fw-semibold fs-6 text-gray-400 flex-grow-1'>
                              Network Platform
                            </span>

                            <span className='svg-icon svg-icon-1'>
                              <svg
                                width='24'
                                height='24'
                                viewBox='0 0 24 24'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <rect
                                  opacity='0.3'
                                  x='2'
                                  y='2'
                                  width='20'
                                  height='20'
                                  rx='10'
                                  fill='currentColor'
                                />
                                <rect
                                  x='7'
                                  y='15.3137'
                                  width='12'
                                  height='2'
                                  rx='1'
                                  transform='rotate(-45 7 15.3137)'
                                  fill='currentColor'
                                />
                                <rect
                                  x='8.41422'
                                  y='7'
                                  width='12'
                                  height='2'
                                  rx='1'
                                  transform='rotate(45 8.41422 7)'
                                  fill='currentColor'
                                />
                              </svg>
                            </span>
                          </div>

                          <div className='d-flex align-items-center'>
                            <span className='fw-semibold fs-6 text-gray-400 flex-grow-1'>
                              Unlimited Cloud Space
                            </span>

                            <span className='svg-icon svg-icon-1'>
                              <svg
                                width='24'
                                height='24'
                                viewBox='0 0 24 24'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <rect
                                  opacity='0.3'
                                  x='2'
                                  y='2'
                                  width='20'
                                  height='20'
                                  rx='10'
                                  fill='currentColor'
                                />
                                <rect
                                  x='7'
                                  y='15.3137'
                                  width='12'
                                  height='2'
                                  rx='1'
                                  transform='rotate(-45 7 15.3137)'
                                  fill='currentColor'
                                />
                                <rect
                                  x='8.41422'
                                  y='7'
                                  width='12'
                                  height='2'
                                  rx='1'
                                  transform='rotate(45 8.41422 7)'
                                  fill='currentColor'
                                />
                              </svg>
                            </span>
                          </div>
                        </div>

                        <a href='#' className='btn btn-sm btn-primary'>
                          Select
                        </a>
                      </div>
                    </div>
                  </div>

                  <div className='col-xl-4'>
                    <div className='d-flex h-100 align-items-center'>
                      <div className='w-100 d-flex flex-column flex-center rounded-3 bg-light bg-opacity-75 py-20 px-10'>
                        <div className='mb-7 text-center'>
                          <h1 className='text-dark mb-5 fw-bolder'>Advanced</h1>

                          <div className='text-gray-400 fw-semibold mb-5'>
                            Optimal for 100+ team siz
                            <br />e and grown company
                          </div>

                          <div className='text-center'>
                            <span className='mb-2 text-primary'>$</span>
                            {isMonthly ? (
                              <span className='fs-3x fw-bold text-primary'>339</span>
                            ) : (
                              <span className='fs-3x fw-bold text-primary'>3399</span>
                            )}
                            <span className='fs-7 fw-semibold opacity-50'>
                              /<span data-kt-element='period'>Mon</span>
                            </span>
                          </div>
                        </div>

                        <div className='w-100 mb-10'>
                          <div className='d-flex align-items-center mb-5'>
                            <span className='fw-semibold fs-6 text-gray-800 flex-grow-1 pe-3'>
                              Up to 10 Active Users
                            </span>

                            <span className='svg-icon svg-icon-1 svg-icon-success'>
                              <svg
                                width='24'
                                height='24'
                                viewBox='0 0 24 24'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <rect
                                  opacity='0.3'
                                  x='2'
                                  y='2'
                                  width='20'
                                  height='20'
                                  rx='10'
                                  fill='currentColor'
                                />
                                <path
                                  d='M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z'
                                  fill='currentColor'
                                />
                              </svg>
                            </span>
                          </div>

                          <div className='d-flex align-items-center mb-5'>
                            <span className='fw-semibold fs-6 text-gray-800 flex-grow-1 pe-3'>
                              Up to 30 Project Integrations
                            </span>

                            <span className='svg-icon svg-icon-1 svg-icon-success'>
                              <svg
                                width='24'
                                height='24'
                                viewBox='0 0 24 24'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <rect
                                  opacity='0.3'
                                  x='2'
                                  y='2'
                                  width='20'
                                  height='20'
                                  rx='10'
                                  fill='currentColor'
                                />
                                <path
                                  d='M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z'
                                  fill='currentColor'
                                />
                              </svg>
                            </span>
                          </div>

                          <div className='d-flex align-items-center mb-5'>
                            <span className='fw-semibold fs-6 text-gray-800 flex-grow-1 pe-3'>
                              Analytics Module
                            </span>

                            <span className='svg-icon svg-icon-1 svg-icon-success'>
                              <svg
                                width='24'
                                height='24'
                                viewBox='0 0 24 24'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <rect
                                  opacity='0.3'
                                  x='2'
                                  y='2'
                                  width='20'
                                  height='20'
                                  rx='10'
                                  fill='currentColor'
                                />
                                <path
                                  d='M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z'
                                  fill='currentColor'
                                />
                              </svg>
                            </span>
                          </div>

                          <div className='d-flex align-items-center mb-5'>
                            <span className='fw-semibold fs-6 text-gray-800 flex-grow-1 pe-3'>
                              Finance Module
                            </span>

                            <span className='svg-icon svg-icon-1 svg-icon-success'>
                              <svg
                                width='24'
                                height='24'
                                viewBox='0 0 24 24'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <rect
                                  opacity='0.3'
                                  x='2'
                                  y='2'
                                  width='20'
                                  height='20'
                                  rx='10'
                                  fill='currentColor'
                                />
                                <path
                                  d='M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z'
                                  fill='currentColor'
                                />
                              </svg>
                            </span>
                          </div>

                          <div className='d-flex align-items-center mb-5'>
                            <span className='fw-semibold fs-6 text-gray-800 flex-grow-1 pe-3'>
                              Accounting Module
                            </span>

                            <span className='svg-icon svg-icon-1 svg-icon-success'>
                              <svg
                                width='24'
                                height='24'
                                viewBox='0 0 24 24'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <rect
                                  opacity='0.3'
                                  x='2'
                                  y='2'
                                  width='20'
                                  height='20'
                                  rx='10'
                                  fill='currentColor'
                                />
                                <path
                                  d='M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z'
                                  fill='currentColor'
                                />
                              </svg>
                            </span>
                          </div>

                          <div className='d-flex align-items-center mb-5'>
                            <span className='fw-semibold fs-6 text-gray-400 flex-grow-1'>
                              Network Platform
                            </span>

                            <span className='svg-icon svg-icon-1'>
                              <svg
                                width='24'
                                height='24'
                                viewBox='0 0 24 24'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <rect
                                  opacity='0.3'
                                  x='2'
                                  y='2'
                                  width='20'
                                  height='20'
                                  rx='10'
                                  fill='currentColor'
                                />
                                <rect
                                  x='7'
                                  y='15.3137'
                                  width='12'
                                  height='2'
                                  rx='1'
                                  transform='rotate(-45 7 15.3137)'
                                  fill='currentColor'
                                />
                                <rect
                                  x='8.41422'
                                  y='7'
                                  width='12'
                                  height='2'
                                  rx='1'
                                  transform='rotate(45 8.41422 7)'
                                  fill='currentColor'
                                />
                              </svg>
                            </span>
                          </div>

                          <div className='d-flex align-items-center'>
                            <span className='fw-semibold fs-6 text-gray-400 flex-grow-1'>
                              Unlimited Cloud Space
                            </span>

                            <span className='svg-icon svg-icon-1'>
                              <svg
                                width='24'
                                height='24'
                                viewBox='0 0 24 24'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <rect
                                  opacity='0.3'
                                  x='2'
                                  y='2'
                                  width='20'
                                  height='20'
                                  rx='10'
                                  fill='currentColor'
                                />
                                <rect
                                  x='7'
                                  y='15.3137'
                                  width='12'
                                  height='2'
                                  rx='1'
                                  transform='rotate(-45 7 15.3137)'
                                  fill='currentColor'
                                />
                                <rect
                                  x='8.41422'
                                  y='7'
                                  width='12'
                                  height='2'
                                  rx='1'
                                  transform='rotate(45 8.41422 7)'
                                  fill='currentColor'
                                />
                              </svg>
                            </span>
                          </div>
                        </div>

                        <a href='#' className='btn btn-sm btn-primary'>
                          Select
                        </a>
                      </div>
                    </div>
                  </div>

                  <div className='col-xl-4'>
                    <div className='d-flex h-100 align-items-center'>
                      <div className='w-100 d-flex flex-column flex-center rounded-3 bg-light bg-opacity-75 py-15 px-10'>
                        <div className='mb-7 text-center'>
                          <h1 className='text-dark mb-5 fw-bolder'>Enterprise</h1>

                          <div className='text-gray-400 fw-semibold mb-5'>
                            Optimal for 1000+ team
                            <br />
                            and enterpise
                          </div>

                          <div className='text-center'>
                            <span className='mb-2 text-primary'>$</span>
                            {isMonthly ? (
                              <span className='fs-3x fw-bold text-primary'>999</span>
                            ) : (
                              <span className='fs-3x fw-bold text-primary'>9999</span>
                            )}
                            <span className='fs-7 fw-semibold opacity-50'>
                              /<span data-kt-element='period'>Mon</span>
                            </span>
                          </div>
                        </div>

                        {/* <div className='text-center'>
                            <span className='mb-2 text-primary'>$</span>
                            <span
                              className='fs-3x fw-bold text-primary'
                              data-kt-plan-price-month='999'
                              data-kt-plan-price-annual='9999'
                            >
                              999
                            </span>
                            <span className='fs-7 fw-semibold opacity-50'>
                              /<span data-kt-element='period'>Mon</span>
                            </span>
                          </div>
                        </div> */}

                        <div className='w-100 mb-10'>
                          <div className='d-flex align-items-center mb-5'>
                            <span className='fw-semibold fs-6 text-gray-800 flex-grow-1 pe-3'>
                              Up to 10 Active Users
                            </span>

                            <span className='svg-icon svg-icon-1 svg-icon-success'>
                              <svg
                                width='24'
                                height='24'
                                viewBox='0 0 24 24'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <rect
                                  opacity='0.3'
                                  x='2'
                                  y='2'
                                  width='20'
                                  height='20'
                                  rx='10'
                                  fill='currentColor'
                                />
                                <path
                                  d='M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z'
                                  fill='currentColor'
                                />
                              </svg>
                            </span>
                          </div>

                          <div className='d-flex align-items-center mb-5'>
                            <span className='fw-semibold fs-6 text-gray-800 flex-grow-1 pe-3'>
                              Up to 30 Project Integrations
                            </span>

                            <span className='svg-icon svg-icon-1 svg-icon-success'>
                              <svg
                                width='24'
                                height='24'
                                viewBox='0 0 24 24'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <rect
                                  opacity='0.3'
                                  x='2'
                                  y='2'
                                  width='20'
                                  height='20'
                                  rx='10'
                                  fill='currentColor'
                                />
                                <path
                                  d='M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z'
                                  fill='currentColor'
                                />
                              </svg>
                            </span>
                          </div>

                          <div className='d-flex align-items-center mb-5'>
                            <span className='fw-semibold fs-6 text-gray-800 flex-grow-1 pe-3'>
                              Analytics Module
                            </span>

                            <span className='svg-icon svg-icon-1 svg-icon-success'>
                              <svg
                                width='24'
                                height='24'
                                viewBox='0 0 24 24'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <rect
                                  opacity='0.3'
                                  x='2'
                                  y='2'
                                  width='20'
                                  height='20'
                                  rx='10'
                                  fill='currentColor'
                                />
                                <path
                                  d='M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z'
                                  fill='currentColor'
                                />
                              </svg>
                            </span>
                          </div>

                          <div className='d-flex align-items-center mb-5'>
                            <span className='fw-semibold fs-6 text-gray-800 flex-grow-1 pe-3'>
                              Finance Module
                            </span>

                            <span className='svg-icon svg-icon-1 svg-icon-success'>
                              <svg
                                width='24'
                                height='24'
                                viewBox='0 0 24 24'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <rect
                                  opacity='0.3'
                                  x='2'
                                  y='2'
                                  width='20'
                                  height='20'
                                  rx='10'
                                  fill='currentColor'
                                />
                                <path
                                  d='M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z'
                                  fill='currentColor'
                                />
                                Name{' '}
                              </svg>
                            </span>
                          </div>

                          <div className='d-flex align-items-center mb-5'>
                            <span className='fw-semibold fs-6 text-gray-800 flex-grow-1 pe-3'>
                              Accounting Module
                            </span>

                            <span className='svg-icon svg-icon-1 svg-icon-success'>
                              <svg
                                width='24'
                                height='24'
                                viewBox='0 0 24 24'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <rect
                                  opacity='0.3'
                                  x='2'
                                  y='2'
                                  width='20'
                                  height='20'
                                  rx='10'
                                  fill='currentColor'
                                />
                                <path
                                  d='M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z'
                                  fill='currentColor'
                                />
                              </svg>
                            </span>
                          </div>

                          <div className='d-flex align-items-center mb-5'>
                            <span className='fw-semibold fs-6 text-gray-800 flex-grow-1 pe-3'>
                              Network Platform
                            </span>

                            <span className='svg-icon svg-icon-1 svg-icon-success'>
                              <svg
                                width='24'
                                height='24'
                                viewBox='0 0 24 24'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <rect
                                  opacity='0.3'
                                  x='2'
                                  y='2'
                                  width='20'
                                  height='20'
                                  rx='10'
                                  fill='currentColor'
                                />
                                <path
                                  d='M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z'
                                  fill='currentColor'
                                />
                              </svg>
                            </span>
                          </div>

                          <div className='d-flex align-items-center'>
                            <span className='fw-semibold fs-6 text-gray-800 flex-grow-1 pe-3'>
                              Unlimited Cloud Space
                            </span>

                            <span className='svg-icon svg-icon-1 svg-icon-success'>
                              <svg
                                width='24'
                                height='24'
                                viewBox='0 0 24 24'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <rect
                                  opacity='0.3'
                                  x='2'
                                  y='2'
                                  width='20'
                                  height='20'
                                  rx='10'
                                  fill='currentColor'
                                />
                                <path
                                  d='M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z'
                                  fill='currentColor'
                                />
                              </svg>
                            </span>
                          </div>
                        </div>

                        <a href='#' className='btn btn-sm btn-primary'>
                          Select
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

// const PricingWrapper = () => {
//   const intl = useIntl()
//   console.log('useIntl>>laguage3', intl)
//   return (
//     <>
//       <PageTitle breadcrumbs={dashboardBreadCrumbs}>
//         {intl.formatMessage({id: 'MENU.DASHBOARD'})}
//       </PageTitle>
//       <Pricing />
//     </>
//   )
// }

// export {PricingWrapper}
