/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useEffect} from 'react'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import {
  getUserByToken,
  register,
  createUser,
  packages_data,
  checkuser,
  registervalidation,
  getfolderprojects, googleSignin
} from '../core/_requests'
import {Link} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {PasswordMeterComponent} from '../../../../_metronic/assets/ts/components'
import {useAuth} from '../core/Auth'
import {useNavigate, useLocation} from 'react-router-dom'
import firebase from "../../../../firebaseConfig";
import 'firebase/auth';
import PasswordStrengthBar from 'react-password-strength-bar'

import { InfoLinkModal } from '../../../../_metronic/partials/modals/terms-plan-contact-modal/InfoLinkModal'; 


const registrationSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Name is required'),
  // phone: Yup.string()
  //   .matches(/^\d+$/, 'Phone number is not valid')
  //   .min(10, 'Phone number must be at least 10 digits')
  //   .max(15, 'Phone number can be at most 15 digits')
  //   .required('Phone number is required'),
  // // phone: Yup.string()
  // //   .min(3, 'Minimum 3 symbols')
  // //   .max(50, 'Maximum 50 symbols')
  // //   .required('Phone number is required'),
  company_name: Yup.string().when('$companyNameFromURL', {
    is: true,
    then: Yup.string().notRequired(),
    otherwise: Yup.string().required('Company name is required'),
  }),
  email: Yup.string()
    .email('Invalid email address')
    .when('$emailFromURL', {
      is: true,
      then: Yup.string().notRequired(),
      otherwise: Yup.string().required('Email is required'),
    }),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
  // changepassword: Yup.string()
  //   .required('Password confirmation is required')
  //   .when('password', {
  //     is: (val: string) => (val && val.length > 0 ? true : false),
  //     then: Yup.string().oneOf([Yup.ref('password')], "Password and Confirm Password didn't match"),
  //   }),
  acceptTerms: Yup.boolean().test(
    'acceptTerms',
    'You must accept the terms and conditions',
    (value) => value === true
  ),
})

export function Registration() {
  const [isOpenTerms, setIsOpenTerms] = useState(false);
  const [isOpenPlans,setIsOpenPlans] = useState(false);
  const [isOpenContactUs,setIsOpenContactUs] = useState(false);

  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const paramsArray = Array.from(queryParams.entries())
  const numParams = paramsArray.length

  // URL has parameters
  var emailFromURL = queryParams.get('email')
  var roleNameFromURL = queryParams.get('role_name')
  var companyNameFromURL = queryParams.get('company_name')
  var tokenFromURL = queryParams.get('token')

  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [multistep, setmultistep] = useState(false)
  const [packages, setpackages] = useState([])

  const [regPassword, setRegPassword] = useState('password')

  const [packagevalue, setpackagevalue] = useState('')
  const [companyType, setCompanyType] = useState('individual')

  const {saveAuth, setCurrentUser, setfolder} = useAuth()
  const formik = useFormik({
    initialValues: {
      name: '',
      // phone: '',
      company_name: companyNameFromURL || '',
      email: emailFromURL || '',
      password: '',
      acceptTerms: false,
    },
    validationSchema: registrationSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        let msg

        if (numParams > 0) {
          // URL has parameters
          const {data} = await createUser(
            values.name,
            // values.phone,
            values.company_name,
            values.email,
            values.password,
            roleNameFromURL ? roleNameFromURL : '',
            tokenFromURL ? tokenFromURL : ''
          )
          msg = data
          if (msg.hassucceeded == true) {
            setStatus(msg.message)
            setSubmitting(false)
            setLoading(false)
            setTimeout(() => {
              navigate('/auth/login')
            }, 1000)
          } else {
            setStatus(msg.message)
            setSubmitting(false)
            setLoading(false)
          }
        } else {
          // URL has no parameters
          const {data} = await register(
            values.name,
            // values.phone,
            values.company_name,
            values.email,
            values.password
            // packagevalue
          )
          msg = data
          if (msg.hassucceeded == true) {
            setStatus(msg.message)
            setSubmitting(false)
            setLoading(false)
          }
          if (msg.hassucceeded == false) {
            setStatus(msg.message)
            setSubmitting(false)
            setLoading(false)
          }
        }
      } catch (error) {
        console.error(error)
        saveAuth(undefined)
        setStatus('The registration details is incorrect')
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  // const handleRegisterValidation = async (email: any, phone: any, company_name: any) => {
  //   try {
  //     const msg = await registervalidation(email, phone, company_name);
  //     if (msg.data.hassucceeded === true) {
  //       setmultistep(true);
  //       formik.setStatus("")
  //     } else {
  //       formik.setStatus(msg.data.message);
  //     }
  //   } catch (error) {
  //     console.error('Error fetching packages:', error);
  //     formik.setStatus('An error occurred during validation. Please try again.');
  //   }
  // };

  const fetchPackage = async () => {
    try {
      const packages = await packages_data()
      const freePackage = packages?.data.find((pkg: any) => pkg.name === 'Free')
      if (freePackage) {
        setpackages(packages?.data)
        setpackagevalue(freePackage.packageid)
      }
    } catch (error) {
      console.error('Error fetching packages:', error)
    }
  }

  const checkUser = async (email: any, role: any, company: any, token: any) => {
    try {
      const userdata = await checkuser(email)
      if (userdata) {
        const {data} = await createUser(
          userdata.data.name,
          // userdata.data.phone,
          company,
          userdata.data.email,
          userdata.data.password,
          role,
          token
        )
        const msg = data
        if (msg.hassucceeded == true) {
          setTimeout(() => {
            navigate('/auth/login')
          }, 1000)
        }
      }
    } catch (error) {
      console.error('Error fetching packages:', error)
    }
  }

  useEffect(() => {
    PasswordMeterComponent.bootstrap()
    fetchPackage()
    if (numParams > 0 && emailFromURL) {
      checkUser(emailFromURL, roleNameFromURL, companyNameFromURL, tokenFromURL)
    }
  }, [])

  const generateUniqueValue = () => {
    const timestamp = Date.now()
    const randomNumber = Math.floor(Math.random() * 10000)
    return `${timestamp}_${randomNumber}`
  }

  const updateCompanyFromEmail = (email: any) => {
    formik.setFieldValue('company_name', email)
  }

  const handleCompanyTypeChange = (event: any) => {
    if (event.target.value == 'company') {
      formik.setFieldValue('company_name', '')
    }
    if (event.target.value == 'individual') {
      formik.setFieldValue('company_name', formik.values.email)
    }
    setCompanyType(event.target.value)
  }

  const toggleRegPassword = (e: any) => {
    e.preventDefault()
    if (regPassword === 'password') {
      setRegPassword('text')
      return
    }
    setRegPassword('password')
  }

  const handleGoogleSignIn = async () => {
    try {
      const provider = new firebase.auth.GoogleAuthProvider();
      const result = await firebase.auth().signInWithPopup(provider);
      const idToken = result?.user?.getIdToken();

      // Send the idToken to your backend API to complete the sign-in process
      // Make the POST request using Axios
      const { data: auth } = await googleSignin(idToken);
      saveAuth(auth)
      const { data: user } = await getUserByToken(auth.api_token)
      if (Array.isArray(user)) {
        const activeUser = user.find((userData) => userData.team_status === true)
        if (activeUser) {
          setCurrentUser(activeUser)
        } else {
          setCurrentUser(user[0])
        }
      }
      const { data: folder } = await getfolderprojects(auth?.company_name || '')
      setfolder(folder)

      // // Redirect to the desired page upon successful sign-in
      // window.location.href = '/dashboard';
    } catch (error) {
      console.error('Google Sign-In Error:', error);
      // Handle sign-in error on the frontend
    }
  };

  return (
    <div className='d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-2 order-lg-1 loginResponse'>
      <div className='d-flex flex-center flex-column flex-lg-row-fluid loginResponse'>
        <div className='w-lg-500px p-10 managePading'>
          <form
            className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
            noValidate
            id='kt_login_signup_form'
            onSubmit={formik.handleSubmit}
          >
            <div className='text-center mb-11'>
              <h1 className='text-dark fw-bolder mb-3'>Sign Up</h1>

              <div className='text-gray-500 fw-semibold fs-6'>Your Social Campaigns</div>
            </div>

            <div className='row g-3 mb-9'>
              <div className='col-sm-12 col-md-12'>
                <button type= "button" onClick={handleGoogleSignIn} className='btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100'>

                  <img
                    alt='Logo'
                    src={toAbsoluteUrl('/media/auth/google-icon.svg')}
                    className='h-15px me-3'
                  />
                  Sign up with Google
                </button>
              </div>

              {/* <div className='col-sm-6 col-md-6'>
                <a
                  href='#'
                  className='btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100'
                >
                  <img
                    alt='Logo'
                    src={toAbsoluteUrl('/media/auth/apple-black.svg')}
                    className='theme-light-show h-15px me-3'
                  />
                  <img
                    alt='Logo'
                    src={toAbsoluteUrl('/media/auth/apple-black-dark.svg')}
                    className='theme-dark-show h-15px me-3'
                  />
                  Sign in with Apple
                </a>
              </div> */}
            </div>

            <div className='separator separator-content my-14'>
              <span className='w-125px text-gray-500 fw-semibold fs-7'>Or with email</span>
            </div>

            {formik.status && (
              <div
                className={`mb-lg-15 alert alert-${
                  formik.status.includes('Success') ? 'success' : 'danger'
                }`}
              >
                <div className='alert-text font-weight-bold'>
                  {formik.status.split('\n').map((line: any, index: any) => (
                    <div className='errorImgSec'>
                      <i className={`fa-solid ${
                 formik.status.includes('Success')
                ? 'fa-circle-check'  // Change icon for success
                : 'fa-circle-exclamation'  // Icon for error
            }`}
            style={{
              color: formik.status.includes('Success') ? 'green' : 'red',
            }}
            ></i>
                      <div key={index}>{line}</div>
                    </div>
                  ))}
                </div>
              </div>
            )}

            {numParams > 0 ? (
              <>
                <div className='fv-row mb-8'>
                  <input
                    placeholder='Name'
                    type='text'
                    autoComplete='off'
                    {...formik.getFieldProps('name')}
                    className={clsx(
                      'form-control bg-transparent',
                      {'is-invalid': formik.touched.name && formik.errors.name},
                      {
                        'is-valid': formik.touched.name && !formik.errors.name,
                      }
                    )}
                    // type="text" placeholder="Email" name="email" autoComplete="off" className="form-control bg-transparent"
                  />
                  {formik.touched.name && formik.errors.name && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.name}</span>
                      </div>
                    </div>
                  )}
                </div>
                {/* <div className='fv-row mb-8'>
                  <input
                    placeholder='Phone'
                    type='number'
                    autoComplete='off'
                    {...formik.getFieldProps('phone')}
                    className={clsx(
                      'form-control bg-transparent',
                      {'is-invalid': formik.touched.phone && formik.errors.phone},
                      {
                        'is-valid': formik.touched.phone && !formik.errors.phone,
                      }
                    )}
                    // type="text" placeholder="Email" name="email" autoComplete="off" className="form-control bg-transparent"
                  />
                  {formik.touched.phone && formik.errors.phone && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.phone}</span>
                      </div>
                    </div>
                  )}
                </div> */}
                <div className='fv-row mb-8'>
                  <input
                    placeholder='Company_name'
                    type='text'
                    autoComplete='off'
                    {...formik.getFieldProps('company_name')}
                    value={companyNameFromURL || formik.values.company_name}
                    readOnly={!!companyNameFromURL}
                    className={clsx(
                      'form-control bg-transparent',
                      {'is-invalid': formik.touched.company_name && formik.errors.company_name},
                      {
                        'is-valid': formik.touched.company_name && !formik.errors.company_name,
                      }
                    )}
                    // type="text" placeholder="Email" name="email" autoComplete="off" className="form-control bg-transparent"
                  />
                  {formik.touched.company_name && formik.errors.company_name && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.company_name}</span>
                      </div>
                    </div>
                  )}
                </div>
                <div className='fv-row mb-8'>
                  <input
                    placeholder='Email'
                    type='email'
                    autoComplete='off'
                    {...formik.getFieldProps('email')}
                    value={emailFromURL || formik.values.email}
                    readOnly={!!emailFromURL}
                    className={clsx('form-control bg-transparent', {
                      'is-invalid': (formik.touched.email || !!emailFromURL) && formik.errors.email,
                      'is-valid': formik.touched.email && !formik.errors.email,
                    })}
                  />
                  {formik.touched.email && formik.errors.email && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.email}</span>
                      </div>
                    </div>
                  )}
                </div>

                <div className='fv-row mb-8' data-kt-password-meter='true'>
                  <div className='mb-1'>
                    <div className='position-relative mb-3'>
                      {/* <input className="form-control bg-transparent" type="password" placeholder="Password" name="password" autoComplete="off" /> */}
                      <input
                        placeholder='Password'
                        type='password'
                        autoComplete='off'
                        {...formik.getFieldProps('password')}
                        className={clsx(
                          'form-control bg-transparent',
                          {'is-invalid': formik.touched.password && formik.errors.password},
                          {
                            'is-valid': formik.touched.password && !formik.errors.password,
                          }
                        )}
                      />
                      {formik.touched.password && formik.errors.password && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.password}</span>
                          </div>
                        </div>
                      )}
                      <span
                        className='btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2'
                        data-kt-password-meter-control='visibility'
                      >
                        <i className='ki-duotone ki-eye-slash fs-2'></i>
                        <i className='ki-duotone ki-eye fs-2 d-none'></i>
                      </span>
                    </div>

                    <div
                      className='d-flex align-items-center mb-3'
                      data-kt-password-meter-control='highlight'
                    >
                      <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
                      <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
                      <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
                      <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px'></div>
                    </div>
                  </div>

                  <div className='text-muted'>
                    Use 8 or more characters with a mix of letters, numbers & symbols.
                  </div>
                </div>

                {/* <div className="fv-row mb-8">

              <input placeholder="Repeat Password" name="confirm-password" type="password" autoComplete="off" className="form-control bg-transparent" />

            </div> */}

                <div className='fv-row mb-8'>
                  <label className='form-check form-check-inline'>
                    <input
                      type='checkbox'
                      {...formik.getFieldProps('acceptTerms')}
                      className={clsx(
                        'form-check-input',
                        {'is-invalid': formik.touched.acceptTerms && formik.errors.acceptTerms},
                        {
                          'is-valid': formik.touched.acceptTerms && !formik.errors.acceptTerms,
                        }
                      )}
                    />
                    <span className='form-check-label fw-semibold text-gray-700 fs-base ms-1'>
                      I Accept the
                      <a href='#' className='ms-1 link-primary'  onClick={() => setIsOpenTerms(true)}>
                        Terms
                      </a>
                    </span>
                  </label>
                  {formik.touched.acceptTerms && formik.errors.acceptTerms && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.acceptTerms}</span>
                      </div>
                    </div>
                  )}
                </div>

                <div className='d-grid mb-10'>
                  <button
                    type='submit'
                    id='kt_sign_up_submit'
                    className='btn btn-lg btn-primary w-100 mb-5'
                    // disabled={formik.isSubmitting || !formik.isValid || !formik.values.acceptTerms}
                  >
                    {!loading && <span className='indicator-label'>Submit</span>}
                    {loading && (
                      <span className='indicator-progress' style={{display: 'block'}}>
                        Please wait...{' '}
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </button>
                </div>

                <div className='text-gray-500 text-center fw-semibold fs-6'>
                  Already have an Account?
                  {/* <a href="../../demo1/dist/authentication/layouts/corporate/sign-in.html" className="link-primary fw-semibold">Sign in</a></div> */}
                  <Link to='/auth/login' className='link-primary'>
                    Sign in
                  </Link>
                </div>
              </>
            ) : (
              <>
                {/* {multistep == false ? (
                  <> */}
                <div className='row allLoginForm'>
                  <div className='fv-row mb-8 manageLogin'>
                    <input
                      placeholder='Name'
                      type='text'
                      autoComplete='off'
                      {...formik.getFieldProps('name')}
                      className={clsx(
                        'form-control bg-transparent',
                        {'is-invalid': formik.touched.name && formik.errors.name},
                        {
                          'is-valid': formik.touched.name && !formik.errors.name,
                        }
                      )}
                      // type="text" placeholder="Email" name="email" autoComplete="off" className="form-control bg-transparent"
                    />
                    {formik.touched.name && formik.errors.name && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.name}</span>
                        </div>
                      </div>
                    )}
                  </div>
                  {/* <div className='fv-row mb-8 col-sm-6 col-md-6 col-lg-12'>
                    <input
                      placeholder='Phone'
                      type='number'
                      autoComplete='off'
                      {...formik.getFieldProps('phone')}
                      className={clsx(
                        'form-control bg-transparent',
                        {'is-invalid': formik.touched.phone && formik.errors.phone},
                        {
                          'is-valid': formik.touched.phone && !formik.errors.phone,
                        }
                      )}
                      // type="text" placeholder="Email" name="email" autoComplete="off" className="form-control bg-transparent"
                    />
                    {formik.touched.phone && formik.errors.phone && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.phone}</span>
                        </div>
                      </div>
                    )}
                  </div> */}
                </div>
                <div>
                  <div className='fv-row mb-8'>
                    <div className='row allradiobtn'>
                      <div className='col-5 col-sm-4 col-md-4 col-lg-3'>
                        <label className='indradiosection text-gray-500'>
                          <input
                            type='radio'
                            value='individual'
                            checked={companyType === 'individual'}
                            onChange={handleCompanyTypeChange}
                          />
                          Individual
                        </label>
                      </div>
                      <div className='col-5 col-sm-4 col-md-4 col-lg-3'>
                        <label className='indradiosection text-gray-500'>
                          <input
                            type='radio'
                            value='company'
                            checked={companyType === 'company'}
                            onChange={handleCompanyTypeChange}
                          />
                          Company
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className='fv-row mb-8'>
                    <div className='mb-8'>
                      {companyType === 'individual' ? null : (
                        <>
                          <input
                            placeholder='Company_name'
                            type='text'
                            autoComplete='off'
                            {...formik.getFieldProps('company_name')}
                            value={formik.values.company_name}
                            className={clsx(
                              'form-control bg-transparent',
                              {
                                'is-invalid':
                                  formik.touched.company_name && formik.errors.company_name,
                              },
                              {
                                'is-valid':
                                  formik.touched.company_name && !formik.errors.company_name,
                              }
                            )}
                          />
                          {formik.touched.company_name && formik.errors.company_name && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>
                                <span role='alert'>{formik.errors.company_name}</span>
                              </div>
                            </div>
                          )}
                        </>
                      )}
                    </div>

                    <input
                      placeholder='Email'
                      type='email'
                      autoComplete='off'
                      {...formik.getFieldProps('email')}
                      value={emailFromURL || formik.values.email}
                      readOnly={!!emailFromURL}
                      className={clsx('form-control bg-transparent', {
                        'is-invalid':
                          (formik.touched.email || !!emailFromURL) && formik.errors.email,
                        'is-valid': formik.touched.email && !formik.errors.email,
                      })}
                      onChange={(event) => {
                        formik.setFieldValue('email', event.target.value)
                        if (companyType === 'individual') {
                          updateCompanyFromEmail(event.target.value)
                        }
                      }}
                    />
                    {formik.touched.email && formik.errors.email && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.email}</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className='fv-row mb-8' data-kt-password-meter='true'>
                  <div className='mb-1'>
                    <div className='position-relative mb-3 loginsetpassword'>
                      {/* <input className="form-control bg-transparent" type="password" placeholder="Password" name="password" autoComplete="off" /> */}
                      <input
                        placeholder='Password'
                        type={regPassword}
                        autoComplete='off'
                        {...formik.getFieldProps('password')}
                        className={clsx(
                          'form-control bg-transparent',
                          {'is-invalid': formik.touched.password && formik.errors.password},
                          {
                            'is-valid': formik.touched.password && !formik.errors.password,
                          }
                        )}
                      />

                      <div className='loginpassbutton '>
                        <button className='btn' onClick={toggleRegPassword}>
                          {regPassword === 'password' ? (
                            <i className='bi bi-eye-slash'></i>
                          ) : (
                            <i className='bi bi-eye'></i>
                          )}
                        </button>
                      </div>

                      {formik.touched.password && formik.errors.password && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.password}</span>
                          </div>
                        </div>
                      )}
                      <span
                        className='btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2'
                        data-kt-password-meter-control='visibility'
                      >
                        <i className='ki-duotone ki-eye-slash fs-2'></i>
                        <i className='ki-duotone ki-eye fs-2 d-none'></i>
                      </span>
                    </div>

                    {/* <div
                      className='d-flex align-items-center mb-3'
                      data-kt-password-meter-control='highlight'
                    >
                      <div className='flex-grow-1 bg-secondary bg-active-danger rounded h-5px me-2'></div>
                      <div className='flex-grow-1 bg-secondary bg-active-warning rounded h-5px me-2'></div>
                      <div className='flex-grow-1 bg-secondary bg-active-primary rounded h-5px me-2'></div>
                      <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px'></div>
                    </div> */}

                    <PasswordStrengthBar
                      password={formik.values.password}
                      // shortScoreWord="muy corta"
                      // scoreWords={["debil", "debil", "bien", "buena", "fuerte"]}
                      // onChangeScore={setPasswordScore}
                    />
                  </div>

                  {/* <div className='text-muted'>
                    Use 8 or more characters with a mix of letters, numbers & symbols.
                  </div> */}
                </div>

                <div className='fv-row mb-8'>
                  <label className='form-check form-check-inline'>
                    <input
                      type='checkbox'
                      {...formik.getFieldProps('acceptTerms')}
                      className={clsx(
                        'form-check-input',
                        {'is-invalid': formik.touched.acceptTerms && formik.errors.acceptTerms},
                        {
                          'is-valid': formik.touched.acceptTerms && !formik.errors.acceptTerms,
                        }
                      )}
                    />
                    <span className='form-check-label fw-semibold text-gray-700 fs-base ms-1'>
                      I Accept the
                      <a href='#' onClick={() => setIsOpenTerms(true)} className='ms-1 link-primary'>
                        Terms
                      </a>
                    </span>
                  </label>
                  {formik.touched.acceptTerms && formik.errors.acceptTerms && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.acceptTerms}</span>
                      </div>
                    </div>
                  )}
                </div>

                <div className='d-grid mb-10 continueBtn'>
                  <button
                    type='submit'
                    id='kt_sign_up_submit'
                    className='btn btn-lg btn-primary w-100 mb-5'
                    // disabled={!formik.values.name || !formik.values.phone || !formik.values.company_name || !formik.values.email || !formik.values.password || !formik.values.acceptTerms}
                    // onClick={() => handleRegisterValidation(formik.values.email, formik.values.phone, formik.values.company_name)}
                  >
                    {!loading && <span className='indicator-label'>Submit</span>}
                    {loading && (
                      <span className='indicator-progress' style={{display: 'block'}}>
                        Please wait...{' '}
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </button>
                </div>

                {/* <div className='col-lg-12 d-grid mb-10 nextbtn'>
                      <button
                        className='btn btn-lg btn-primary w-50 mb-5'
                        disabled={!formik.values.name || !formik.values.phone || !formik.values.company_name || !formik.values.email || !formik.values.password}
                        // onClick={() => setmultistep(true)}
                        onClick={() => handleRegisterValidation(formik.values.email, formik.values.phone, formik.values.company_name)}

                      >
                        Next
                      </button>

                     
                    </div> */}

                <div className='text-gray-500 text-center fw-semibold fs-6'>
                  Already have an Account?
                  {/* <a href="../../demo1/dist/authentication/layouts/corporate/sign-in.html" className="link-primary fw-semibold">Sign in</a></div> */}
                  <Link to='/auth/login' className='link-primary px-1'>
                    Sign in
                  </Link>
                </div>
                {/* </>
                ) : (
                  <>
                    <div className='row mb-9 g-3'>
                      {packages.map((pkg: any) => (
                        <div
                          key={pkg.packageid}
                          className={`col-sm-4 col-lg-4 col-md-4 ${pkg.packageid === packagevalue ? 'active cardactive' : ''
                            }`}
                          onClick={() => setpackagevalue(pkg.packageid)}
                        >
                          <div className='card px-3 py-3'>
                            <div className='card-body px-3 py-3'>
                              <h5 className='card-title'>{pkg.name}</h5>
                              <p className='card-text text-gray-500'>
                                A product package, a travel package Please provide more details.
                              </p>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                    <div className='fv-row mb-8'>
                      <label className='form-check form-check-inline'>
                        <input
                          type='checkbox'
                          {...formik.getFieldProps('acceptTerms')}
                          className={clsx(
                            'form-check-input',
                            { 'is-invalid': formik.touched.acceptTerms && formik.errors.acceptTerms },
                            {
                              'is-valid': formik.touched.acceptTerms && !formik.errors.acceptTerms,
                            }
                          )}
                        />
                        <span className='form-check-label fw-semibold text-gray-700 fs-base ms-1'>
                          I Accept the
                          <a href='#' className='ms-1 link-primary'>
                            Terms
                          </a>
                        </span>
                      </label>
                      {formik.touched.acceptTerms && formik.errors.acceptTerms && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.acceptTerms}</span>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className='row d-grid d-flex mb-10 g-3'>
                      <div className='col-sm-6 col-md-6'>
                        <button
                          className='btn btn-lg btn-primary w-100 mb-3'
                          onClick={() => setmultistep(false)}
                        >
                          Back
                        </button>
                      </div>

                      <div className='col-sm-6 col-md-6'>
                        <button
                          type='submit'
                          id='kt_sign_up_submit'
                          className='btn btn-lg btn-primary w-100 mb-3'
                        // disabled={formik.isSubmitting || !formik.isValid || !formik.values.acceptTerms}
                        >
                          {!loading && <span className='indicator-label'>Submit</span>}
                          {loading && (
                            <span className='indicator-progress' style={{ display: 'block' }}>
                              Please wait...{' '}
                              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                          )}
                        </button>
                      </div>


                    </div>
                  </>
                )} */}
              </>
            )}
          </form>
        </div>
      </div>

      <div className='w-lg-500px d-flex flex-stack px-10 mx-auto managePading'>
        <div className='me-10'>
          <button
            className='btn btn-flex btn-link btn-color-gray-700 btn-active-color-primary rotate fs-base'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-start'
            data-kt-menu-offset='0px, 0px'
          >
            <img
              data-kt-element='current-lang-flag'
              className='w-20px h-20px rounded me-3'
              src={toAbsoluteUrl('/media/auth/united-states.svg')}
              alt=''
            />
            <span data-kt-element='current-lang-name' className='me-1'>
              English
            </span>
            <i className='ki-duotone ki-down fs-5 text-muted rotate-180 m-0'></i>
          </button>

          <div
            className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold w-200px py-4 fs-7'
            data-kt-menu='true'
            id='kt_auth_lang_menu'
          >
            <div className='menu-item px-3'>
              <a href='#' className='menu-link d-flex px-5' data-kt-lang='English'>
                <span className='symbol symbol-20px me-4'>
                  <img
                    data-kt-element='lang-flag'
                    className='rounded-1'
                    src='assets/media/flags/united-states.svg'
                    alt=''
                  />
                </span>
                <span data-kt-element='lang-name'>English</span>
              </a>
            </div>

            <div className='menu-item px-3'>
              <a href='#' className='menu-link d-flex px-5' data-kt-lang='Spanish'>
                <span className='symbol symbol-20px me-4'>
                  <img
                    data-kt-element='lang-flag'
                    className='rounded-1'
                    src='assets/media/flags/spain.svg'
                    alt=''
                  />
                </span>
                <span data-kt-element='lang-name'>Spanish</span>
              </a>
            </div>

            <div className='menu-item px-3'>
              <a href='#' className='menu-link d-flex px-5' data-kt-lang='German'>
                <span className='symbol symbol-20px me-4'>
                  <img
                    data-kt-element='lang-flag'
                    className='rounded-1'
                    src='assets/media/flags/germany.svg'
                    alt=''
                  />
                </span>
                <span data-kt-element='lang-name'>German</span>
              </a>
            </div>

            <div className='menu-item px-3'>
              <a href='#' className='menu-link d-flex px-5' data-kt-lang='Japanese'>
                <span className='symbol symbol-20px me-4'>
                  <img
                    data-kt-element='lang-flag'
                    className='rounded-1'
                    src='assets/media/flags/japan.svg'
                    alt=''
                  />
                </span>
                <span data-kt-element='lang-name'>Japanese</span>
              </a>
            </div>

            <div className='menu-item px-3'>
              <a href='#' className='menu-link d-flex px-5' data-kt-lang='French'>
                <span className='symbol symbol-20px me-4'>
                  <img
                    data-kt-element='lang-flag'
                    className='rounded-1'
                    src='assets/media/flags/france.svg'
                    alt=''
                  />
                </span>
                <span data-kt-element='lang-name'>French</span>
              </a>
            </div>
          </div>
        </div>

        <div className='d-flex fw-semibold text-primary fs-base gap-5'>
          <a href='#' onClick={() => setIsOpenTerms(true)} >
            Terms
          </a>
          <a href='#' onClick={() => setIsOpenPlans(true)} >
            Plans
          </a>
          <a href='#'  onClick={() => setIsOpenContactUs(true)} >
            Contact Us
          </a>
        </div>
      </div>
      <InfoLinkModal
        isOpenTerms={isOpenTerms}
        setIsOpenTerms={setIsOpenTerms}
        isOpenPlans={isOpenPlans}
        setIsOpenPlans={setIsOpenPlans}
        isOpenContactUs={isOpenContactUs}
        setIsOpenContactUs={setIsOpenContactUs}
      />
    </div>
    // <form
    //   className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
    //   noValidate
    //   id='kt_login_signup_form'
    //   onSubmit={formik.handleSubmit}
    // >
    //   {/* begin::Heading */}
    //   <div className='mb-10 text-center'>
    //     {/* begin::Title */}
    //     <h1 className='text-dark mb-3'>Create an Account</h1>
    //     {/* end::Title */}

    //     {/* begin::Link */}
    //     <div className='text-gray-400 fw-bold fs-4'>
    //       Already have an account?
    //       <Link to='/auth/login' className='link-primary fw-bolder' style={{marginLeft: '5px'}}>
    //         Forgot Password ?
    //       </Link>
    //     </div>
    //     {/* end::Link */}
    //   </div>
    //   {/* end::Heading */}

    //   {/* begin::Action */}
    //   <button type='button' className='btn btn-light-primary fw-bolder w-100 mb-10'>
    //     <img
    //       alt='Logo'
    //       src={toAbsoluteUrl('/media/svg/brand-logos/google-icon.svg')}
    //       className='h-20px me-3'
    //     />
    //     Sign in with Google
    //   </button>
    //   {/* end::Action */}

    //   <div className='d-flex align-items-center mb-10'>
    //     <div className='border-bottom border-gray-300 mw-50 w-100'></div>
    //     <span className='fw-bold text-gray-400 fs-7 mx-2'>OR</span>
    //     <div className='border-bottom border-gray-300 mw-50 w-100'></div>
    //   </div>

    //   {formik.status && (
    //     <div className='mb-lg-15 alert alert-danger'>
    //       <div className='alert-text font-weight-bold'>{formik.status}</div>
    //     </div>
    //   )}

    //   {/* begin::Form group Firstname */}
    //   <div className='row fv-row mb-7'>
    //     <div className='col-xl-6'>
    //       <label className='class="form-label fw-bolder text-dark fs-6'>First name</label>
    //       <input
    //         placeholder='First name'
    //         type='text'
    //         autoComplete='off'
    //         {...formik.getFieldProps('firstname')}
    //         className={clsx(
    //           'form-control form-control-lg form-control-solid',
    //           {
    //             'is-invalid': formik.touched.firstname && formik.errors.firstname,
    //           },
    //           {
    //             'is-valid': formik.touched.firstname && !formik.errors.firstname,
    //           }
    //         )}
    //       />
    //       {formik.touched.firstname && formik.errors.firstname && (
    //         <div className='fv-plugins-message-container'>
    //           <div className='fv-help-block'>
    //             <span role='alert'>{formik.errors.firstname}</span>
    //           </div>
    //         </div>
    //       )}
    //     </div>
    //     <div className='col-xl-6'>
    //       {/* begin::Form group Lastname */}
    //       <div className='fv-row mb-5'>
    //         <label className='form-label fw-bolder text-dark fs-6'>Last name</label>
    //         <input
    //           placeholder='Last name'
    //           type='text'
    //           autoComplete='off'
    //           {...formik.getFieldProps('lastname')}
    //           className={clsx(
    //             'form-control form-control-lg form-control-solid',
    //             {
    //               'is-invalid': formik.touched.lastname && formik.errors.lastname,
    //             },
    //             {
    //               'is-valid': formik.touched.lastname && !formik.errors.lastname,
    //             }
    //           )}
    //         />
    //         {formik.touched.lastname && formik.errors.lastname && (
    //           <div className='fv-plugins-message-container'>
    //             <div className='fv-help-block'>
    //               <span role='alert'>{formik.errors.lastname}</span>
    //             </div>
    //           </div>
    //         )}
    //       </div>
    //       {/* end::Form group */}
    //     </div>
    //   </div>
    //   {/* end::Form group */}

    //   {/* begin::Form group Email */}
    //   <div className='fv-row mb-7'>
    //     <label className='form-label fw-bolder text-dark fs-6'>Email</label>
    //     <input
    //       placeholder='Email'
    //       type='email'
    //       autoComplete='off'
    //       {...formik.getFieldProps('email')}
    //       className={clsx(
    //         'form-control form-control-lg form-control-solid',
    //         {'is-invalid': formik.touched.email && formik.errors.email},
    //         {
    //           'is-valid': formik.touched.email && !formik.errors.email,
    //         }
    //       )}
    //     />
    //     {formik.touched.email && formik.errors.email && (
    //       <div className='fv-plugins-message-container'>
    //         <div className='fv-help-block'>
    //           <span role='alert'>{formik.errors.email}</span>
    //         </div>
    //       </div>
    //     )}
    //   </div>
    //   {/* end::Form group */}

    //   {/* begin::Form group Password */}
    //   <div className='mb-10 fv-row' data-kt-password-meter='true'>
    //     <div className='mb-1'>
    //       <label className='form-label fw-bolder text-dark fs-6'>Password</label>
    //       <div className='position-relative mb-3'>
    //         <input
    //           type='password'
    //           placeholder='Password'
    //           autoComplete='off'
    //           {...formik.getFieldProps('password')}
    //           className={clsx(
    //             'form-control form-control-lg form-control-solid',
    //             {
    //               'is-invalid': formik.touched.password && formik.errors.password,
    //             },
    //             {
    //               'is-valid': formik.touched.password && !formik.errors.password,
    //             }
    //           )}
    //         />
    //         {formik.touched.password && formik.errors.password && (
    //           <div className='fv-plugins-message-container'>
    //             <div className='fv-help-block'>
    //               <span role='alert'>{formik.errors.password}</span>
    //             </div>
    //           </div>
    //         )}
    //       </div>
    //       {/* begin::Meter */}
    //       <div
    //         className='d-flex align-items-center mb-3'
    //         data-kt-password-meter-control='highlight'
    //       >
    //         <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
    //         <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
    //         <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
    //         <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px'></div>
    //       </div>
    //       {/* end::Meter */}
    //     </div>
    //     <div className='text-muted'>
    //       Use 8 or more characters with a mix of letters, numbers & symbols.
    //     </div>
    //   </div>
    //   {/* end::Form group */}

    //   {/* begin::Form group Confirm password */}
    //   <div className='fv-row mb-5'>
    //     <label className='form-label fw-bolder text-dark fs-6'>Confirm Password</label>
    //     <input
    //       type='password'
    //       placeholder='Password confirmation'
    //       autoComplete='off'
    //       {...formik.getFieldProps('changepassword')}
    //       className={clsx(
    //         'form-control form-control-lg form-control-solid',
    //         {
    //           'is-invalid': formik.touched.changepassword && formik.errors.changepassword,
    //         },
    //         {
    //           'is-valid': formik.touched.changepassword && !formik.errors.changepassword,
    //         }
    //       )}
    //     />
    //     {formik.touched.changepassword && formik.errors.changepassword && (
    //       <div className='fv-plugins-message-container'>
    //         <div className='fv-help-block'>
    //           <span role='alert'>{formik.errors.changepassword}</span>
    //         </div>
    //       </div>
    //     )}
    //   </div>
    //   {/* end::Form group */}

    //   {/* begin::Form group */}
    //   <div className='fv-row mb-10'>
    //     <div className='form-check form-check-custom form-check-solid'>
    //       <input
    //         className='form-check-input'
    //         type='checkbox'
    //         id='kt_login_toc_agree'
    //         {...formik.getFieldProps('acceptTerms')}
    //       />
    //       <label
    //         className='form-check-label fw-bold text-gray-700 fs-6'
    //         htmlFor='kt_login_toc_agree'
    //       >
    //         I Agree the{' '}
    //         <Link to='/auth/terms' className='ms-1 link-primary'>
    //           terms and conditions
    //         </Link>
    //         .
    //       </label>
    //       {formik.touched.acceptTerms && formik.errors.acceptTerms && (
    //         <div className='fv-plugins-message-container'>
    //           <div className='fv-help-block'>
    //             <span role='alert'>{formik.errors.acceptTerms}</span>
    //           </div>
    //         </div>
    //       )}
    //     </div>
    //   </div>
    //   {/* end::Form group */}

    //   {/* begin::Form group */}
    //   <div className='text-center'>
    //     <button
    //       type='submit'
    //       id='kt_sign_up_submit'
    //       className='btn btn-lg btn-primary w-100 mb-5'
    //       disabled={formik.isSubmitting || !formik.isValid || !formik.values.acceptTerms}
    //     >
    //       {!loading && <span className='indicator-label'>Submit</span>}
    //       {loading && (
    //         <span className='indicator-progress' style={{display: 'block'}}>
    //           Please wait...{' '}
    //           <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
    //         </span>
    //       )}
    //     </button>
    //     <Link to='/auth/login'>
    //       <button
    //         type='button'
    //         id='kt_login_signup_form_cancel_button'
    //         className='btn btn-lg btn-light-primary w-100 mb-5'
    //       >
    //         Cancel
    //       </button>
    //     </Link>
    //   </div>
    //   {/* end::Form group */}
    // </form>
  )
}
