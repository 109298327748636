/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect} from 'react'
import {Outlet, Route, Routes} from 'react-router-dom'
import {Registration} from './components/Registration'
import {ForgotPassword} from './components/ForgotPassword'
import {Login} from './components/Login'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {ResetPassword} from './components/ResetPassword'
import {EmailVerify} from './components/EmailVerify'
// import { ContactUsWrapper } from '../../pages/contactus/Contactus'
import {Plans} from './components/Plans'


const AuthLayout = () => {
  useEffect(() => {
    document.body.style.backgroundImage = 'none'
    return () => {}
  }, [])

  return (
    <div className="d-flex flex-column flex-root" id="kt_app_root">

      <div className="d-flex flex-column flex-lg-row flex-column-fluid">
        <Outlet />
        <div className="d-flex flex-lg-row-fluid w-lg-50 bgi-size-cover bgi-position-center order-1 order-lg-2"
          style={{
            backgroundImage: `url('${toAbsoluteUrl('/media/auth/auth-bg.png')}')`,
          }}>

          <div className="d-flex flex-column flex-center py-7 py-lg-15 px-5 px-md-15 w-100">

            <a href="../../demo1/dist/index.html" className="mb-0 mb-lg-12">
              <img alt="Logo" src={toAbsoluteUrl('/media/auth/custom-1.png')} className="h-60px h-lg-75px" />
            </a>

            <img className="d-none d-lg-block mx-auto w-275px w-md-50 w-xl-500px mb-10 mb-lg-20" src={toAbsoluteUrl('/media/auth/auth-screens.png')} />


            <h1 className="d-none d-lg-block text-white fs-2qx fw-bolder text-center mb-7">Fast, Efficient and Productive</h1>

            <div className="d-none d-lg-block text-white fs-base text-center">In this kind of post,
              <a href="#" className="opacity-75-hover text-warning fw-bold me-1">the blogger</a>introduces a person they’ve interviewed
              <br />and provides some background information about
              <a href="#" className="opacity-75-hover text-warning fw-bold me-1">the interviewee</a>and their
              <br />work following this is a transcript of the interview.</div>

          </div>

        </div>

      </div>

    </div>


    // <div
    //   className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'
    //   style={{
    //     backgroundImage: `url(${toAbsoluteUrl('/media/illustrations/sketchy-1/14.png')})`,
    //   }}
    // >
    //   {/* begin::Content */}
    //   <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20'>
    //     {/* begin::Logo */}
    //     <a href='#' className='mb-12'>
    //       <img
    //         alt='Logo'
    //         src={toAbsoluteUrl('/media/logos/default-dark.svg')}
    //         className='theme-dark-show h-45px'
    //       />
    //       <img
    //         alt='Logo'
    //         src={toAbsoluteUrl('/media/logos/default.svg')}
    //         className='theme-light-show h-45px'
    //       ></img>
    //     </a>
    //     {/* end::Logo */}
    //     {/* begin::Wrapper */}
    //     <div className='w-lg-500px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto'>
    //       <Outlet />
    //     </div>
    //     {/* end::Wrapper */}
    //   </div>
    //   {/* end::Content */}
    //   {/* begin::Footer */}
    //   <div className='d-flex flex-center flex-column-auto p-10'>
    //     <div className='d-flex align-items-center fw-semibold fs-6'>
    //       <a href='#' className='text-muted text-hover-primary px-2'>
    //         About
    //       </a>

    //       <a href='#' className='text-muted text-hover-primary px-2'>
    //         Contact
    //       </a>

    //       <a href='#' className='text-muted text-hover-primary px-2'>
    //         Contact Us
    //       </a>
    //     </div>
    //   </div>
    //   {/* end::Footer */}
    // </div>
  )
}

const AuthPage = () => (
  <Routes>
    <Route element={<AuthLayout />}>
      <Route path='login' element={<Login />} />
      <Route path='registration' element={<Registration />} />
      <Route path='forgot-password' element={<ForgotPassword />} />
      <Route path='reset-password' element={<ResetPassword />} />
      {/* <Route path='contactus' element={<ContactUsWrapper />} /> */}
      <Route path='email-verify' element={<EmailVerify />} />
      <Route path='plans' element={<Plans />} />
      <Route index element={<Login />} />
    </Route>
  </Routes>
)

export {AuthPage}
