/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../../../../app/modules/auth/core/Auth'


interface CardListOptionProps {
  folderId: string;
  projectId: string;
  name: string;
  setIsOpenDelete: (folderId: string, projectId: string) => void
  setIsOpenRename: (folderId: string, projectId: string, name: string) => void
}

export const CardListOption: FC<CardListOptionProps> = ({ folderId, projectId, name, setIsOpenRename, setIsOpenDelete }) => {
  const navigate = useNavigate();
  const { saveAuth, currentUser, folder, setfolder } = useAuth()
  const handleOpen = () => {
    if (folderId) {
      navigate(`/ProjectSpace/?folderid=${folderId}`);
    } else if (projectId) {
      const filteredData: any = folder?.AllProjectInfoData?.filter((obj: any) => obj.projectid === projectId);
      navigate(`/workspace/?project_infoid=${filteredData[0]?.project_infoid}`);
    }
  };
  return (
    <div className='menu menu-sub menu-sub-dropdown w-250px w-md-300px' data-kt-menu='true'>
      <div className='px-7 py-5'>
        <div className='fs-5 text-dark fw-bolder'>Options</div>
      </div>

      <div className='separator border-gray-200'></div>

      <div className='px-7 py-1'>
        <div className='mb-5'>
          <ul className='menu menu-gray-600 menu-hover-primary fw-bold order-1 flex-column'>
            <li className='menu-item'>
              <a href='#' onClick={handleOpen} className='menu-link px-2'>
                Open
              </a>
            </li>

            <li className='menu-item'>
              <a href='#' onClick={() => setIsOpenRename(folderId, projectId, name)} className='menu-link px-2'>
                Rename
              </a>
            </li>

            {/* <li className='menu-item'>
              <a href='#'  className='menu-link px-2'>
                Share
              </a>
            </li> */}
            <li className='menu-item'>
              <a href='#' onClick={() => setIsOpenDelete(folderId, projectId,)} className='menu-link px-2'>
                Delete
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}
