import axios from 'axios'
import { AuthModel, UserModel } from './_models'

const API_URL = process.env.REACT_APP_API_URL

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/verify_token`
export const LOGIN_URL = `${API_URL}/login`
export const REGISTER_VALIDATION_URL = `${API_URL}/registervalidation`
export const REGISTER_URL = `${API_URL}/register`
export const INVITE_URL = `${API_URL}/invite`
export const CREATE_USER_URL = `${API_URL}/createUser`
export const FORGOT_PASSWORD_URL = `${API_URL}/forgot-password`
export const RESET_PASSWORD_URL = `${API_URL}/reset-password`
export const GET_FOLDER_PROJECT = `${API_URL}/folderprojects`
export const CREATE_PROJECT = `${API_URL}/createproject`
export const CREATE_FOLDER = `${API_URL}/createfolder`
export const GET_VOICES = `${API_URL}/voices`
export const GET_PACKAGES = `${API_URL}/packages`
export const GET_PROJECT_INFO = `${API_URL}/getprojectinfo`
export const UPDATE_GET_PROJECT_INFO = `${API_URL}/projectinfo`
export const GET_SPEECH = `${API_URL}/api/speech`
export const DEL_RENAME_FOLDERPROJECT = `${API_URL}/delrenamefolderproject`
export const CHECK_USER = `${API_URL}/checkuser`
export const EMAIL_VERIFY = `${API_URL}/emailverify`
export const SET_LAST_ACTIVE = `${API_URL}/lastactive`
export const ADD_ACCOUNT = `${API_URL}/addaccount`
export const GOOGLE_SIGNIN = `${API_URL}/google-signin`
export const PACK_CALC = `${API_URL}/package_calc`
export const PAYMENT = `${API_URL}/payment`















// Server should return AuthModel
export function login(email: string, password: string) {
  return axios.post<AuthModel>(LOGIN_URL, {
    email,
    password,
  })
}

// Server should return AuthModel
export function googleSignin(idToken: any) {
  return axios.post(GOOGLE_SIGNIN, {
    idToken
  })
}

// Server should return AuthModel
export function speech(data: object | Array<object>, company_id: string) {
  // Append the company_id to the data object
  if (Array.isArray(data)) {
    // If data is an array, you may want to add the company_id to each object in the array
    const modifiedData = data.map((item) => ({ ...item, company_id }));
    return axios.post(GET_SPEECH, modifiedData, { responseType: 'arraybuffer' });
  } else {
    // If data is an object, simply add the company_id property to it
    const requestData = { ...data, company_id };
    return axios.post(GET_SPEECH, requestData, { responseType: 'arraybuffer' });
  }
}

// Server should return AuthModel
export function registervalidation(
  email: string,
  phone: string,
  company_name: string,
) {
  return axios.post(REGISTER_VALIDATION_URL, {
    email,
    phone,
    company_name
  })
}

// Server should return AuthModel
export function addaccount(
  token: string,
  company_name: string,
) {
  return axios.post(ADD_ACCOUNT, {
    token,
    company_name
  })
}

// Server should return AuthModel
export function register(
  name: string,
  // phone: string,
  company_name: string,
  email: string,
  password: string,
  // packagevalue: string
) {
  return axios.post(REGISTER_URL, {
    name,
    // phone,
    company_name,
    email,
    password,
    // packageid: packagevalue
  })
}

// Server should return AuthModel
export function createUser(
  name: string,
  // phone: string,
  company_name: string,
  email: string,
  password: string,
  roleNameFromURL: string,
  tokenFromURL: string
) {
  return axios.post(CREATE_USER_URL, {
    name,
    // phone,
    company_name,
    email,
    password,
    role_name: roleNameFromURL,
    token: tokenFromURL
  })
}

// Server should return AuthModel
export function invite(
  email: string,
  role_name: string,
  company_name: string,
  token: string,


) {
  return axios.post(INVITE_URL, {
    email,
    role_name,
    company_name,
    token,
  })
}

// Server should return AuthModel
export function getfolderprojects(
  company_name: string,
) {
  return axios.get(GET_FOLDER_PROJECT + "/" + company_name)
}

// Server should return AuthModel
export function createproject(
  name: string,
  company_name: string,
  folderid: string,


) {
  return axios.post(CREATE_PROJECT, {
    name,
    company_name,
    folderid,
  })
}

// Server should return AuthModel
export function createfolder(
  name: string,
  company_name: string


) {
  return axios.post(CREATE_FOLDER, {
    name,
    company_name
  })
}

// Server should return AuthModel
export function voices_data() {
  return axios.get(GET_VOICES)
}

// Server should return AuthModel
export function packages_data() {
  return axios.get(GET_PACKAGES)
}

// Server should return AuthModel
export function getprojectinfo(
  project_infoid: string,
) {
  return axios.get(GET_PROJECT_INFO + "/" + project_infoid)
}

// Server should return AuthModel
export function projectinfo(
  project_infoid: string,
  orders: Array<object>,
  fav_voices: Array<object>


) {
  return axios.post(UPDATE_GET_PROJECT_INFO, {
    project_infoid,
    orders,
    fav_voices
  })
}

// Server should return AuthModel
export function delrenamefolderproject(
  folderId: string,
  projectId: string,
  name: string,
  purpose: string,


) {
  return axios.post(DEL_RENAME_FOLDERPROJECT, {
    folderId,
    projectId,
    Newname: name,
    purpose
  })
}

// Server should return AuthModel
export function checkuser(
  email: string,
) {
  return axios.get(CHECK_USER + "/" + email)
}


// Server should return AuthModel
export function emailverify(
  token: string,
) {
  return axios.get(EMAIL_VERIFY + "/" + token)
}





// Server should return object => { result: boolean } (Is Email in DB)
export function forgotPassword(email: string) {
  return axios.post(FORGOT_PASSWORD_URL, {
    email,
  })
}

// Server should return AuthModel
export function resetPassword(
  token: string,
  newPassword: string,
) {
  return axios.post(RESET_PASSWORD_URL, {
    token,
    newPassword
  })
}

// Server should return AuthModel
export function package_calc(
  company_id: string,
  packageid: string,
  topup_min: number
) {
  return axios.post(PACK_CALC, {
    company_id,
    packageid,
    topup_min
  })
}

// Server should return AuthModel
export function payment(
  token : any,
  pack_calc : any
) {
  return axios.post(PAYMENT, {
    token,
    pack_calc
  })
}

export function getUserByToken(token: string) {
  return axios.post<UserModel>(GET_USER_BY_ACCESSTOKEN_URL, {
    api_token: token,
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function lastactive(token: string, teamid: string) {
  return axios.post(SET_LAST_ACTIVE, {
    api_token: token,
    teamid
  })
}

