import {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {Modal} from 'react-bootstrap'
import {useNavigate, useLocation} from 'react-router-dom'
import {useAuth} from '../../modules/auth/core/Auth'
import {KTSVG, toAbsoluteUrl} from '../../../_metronic/helpers'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import {log} from 'console'

const dashboardBreadCrumbs: Array<PageLink> = [
  {
    title: 'Home',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
]

const initialValues = {
  name: '',
  email: '',
  subject: '',
  message: '',
}

const signUpSchema = Yup.object({
  name: Yup.string().min(2).max(25).required('Please enter your name'),
  email: Yup.string().email().required('Please enter your email'),
  subject: Yup.string().required('Please enter your subject'),
  message: Yup.string().required('Please enter message'),
})

const Contactus = () => {
  const {saveAuth, currentUser, folder, setfolder} = useAuth()

  const [matchingProjects, setmatchingProjects] = useState<any[]>([])

  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search)
    const folderId = queryParams.get('folderid')
    const filteredData: any[] = (folder?.AllProjectInfoData || []).filter(
      (obj: any) => obj.folderid === folderId
    )
    const projectIds = filteredData.map((obj: any) => obj.projectid)
    const matchingProjects = (folder?.project || []).filter((obj: any) =>
      projectIds.includes(obj.projectid)
    )
    if (matchingProjects.length === 0) {
      navigate(`/dashboard/?folderid=${folderId}`)
    }
    setmatchingProjects(matchingProjects)
  }, [folder])

  const {values, errors, touched, handleBlur, handleChange, handleSubmit} = useFormik({
    initialValues,
    validationSchema: signUpSchema,
    onSubmit: (values, action) => {
      console.log('dsdsad', values)
      action.resetForm()
    },
  })
  console.log('error', errors)

  return (
    <>
      <div className='card mb-10'>
        <div className='card-body p-lg-15'>
          <div className='row mb-3'>
            <div className='col-md-6 pe-lg-10'>
              <form action='' className='form mb-8' method='post' onSubmit={handleSubmit}>
                <h1 className='fw-bold text-dark mb-9'>Send Us Email</h1>

                <div className='row mb-5'>
                  <div className='col-md-6 fv-row'>
                    <label className='fs-5 fw-semibold mb-2'>Name</label>

                    <input
                      type='text'
                      className='form-control form-control-solid'
                      placeholder=''
                      name='name'
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.name && touched.name ? (
                      <p className='formError'>{errors.name}</p>
                    ) : null}
                  </div>

                  <div className='col-md-6 fv-row'>
                    <label className='fs-5 fw-semibold mb-2'>Email</label>

                    <input
                      type='text'
                      className='form-control form-control-solid'
                      placeholder=''
                      name='email'
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.email && touched.email ? (
                      <p className='formError'>{errors.email}</p>
                    ) : null}
                  </div>
                </div>

                <div className='d-flex flex-column mb-5 fv-row'>
                  <label className='fs-5 fw-semibold mb-2'>Subject</label>

                  <input
                    className='form-control form-control-solid'
                    placeholder=''
                    name='subject'
                    value={values.subject}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.subject && touched.subject ? (
                    <p className='formError'>{errors.subject}</p>
                  ) : null}
                </div>

                <div className='d-flex flex-column mb-10 fv-row'>
                  <label className='fs-6 fw-semibold mb-2'>Message</label>
                  <textarea
                    className='form-control form-control-solid'
                    rows={6}
                    name='message'
                    placeholder=''
                    value={values.message}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  ></textarea>
                  {errors.message && touched.message ? (
                    <p className='formError'>{errors.message}</p>
                  ) : null}
                </div>

                <button type='submit' className='btn btn-primary' id='kt_contact_submit_button'>
                  <span className='indicator-label'>Send Feedback</span>

                  <span className='indicator-progress'>
                    Please wait...
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                </button>
              </form>
            </div>



            <div className='col-md-6 text-center'>
            <div className='col-sm-6 col-md-6 ps-lg-10 w-100 mb-12'>
              <div className='text-center bg-light card-rounded d-flex flex-column justify-content-center p-10'>
                <i className='fa-solid fa-briefcase fs-2tx text-primary'></i>
                <h1 className='text-dark fw-bold my-5'>Let’s Speak</h1>

                <div className='text-gray-700 fw-semibold fs-2'>1 (833) 597-7538</div>
              </div>
            </div>

            <div className='col-sm-6 col-md-6 ps-lg-10 w-100'>
              <div className='text-center bg-light card-rounded d-flex flex-column justify-content-center p-10'>
                <span className='svg-icon svg-icon-3tx svg-icon-primary'>
                  <svg
                    width='24'
                    height='24'
                    viewBox='0 0 24 24'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      opacity='0.3'
                      d='M18.0624 15.3453L13.1624 20.7453C12.5624 21.4453 11.5624 21.4453 10.9624 20.7453L6.06242 15.3453C4.56242 13.6453 3.76242 11.4453 4.06242 8.94534C4.56242 5.34534 7.46242 2.44534 11.0624 2.04534C15.8624 1.54534 19.9624 5.24534 19.9624 9.94534C20.0624 12.0453 19.2624 13.9453 18.0624 15.3453Z'
                      fill='currentColor'
                    />
                    <path
                      d='M12.0624 13.0453C13.7193 13.0453 15.0624 11.7022 15.0624 10.0453C15.0624 8.38849 13.7193 7.04535 12.0624 7.04535C10.4056 7.04535 9.06241 8.38849 9.06241 10.0453C9.06241 11.7022 10.4056 13.0453 12.0624 13.0453Z'
                      fill='currentColor'
                    />
                  </svg>
                </span>

                <h1 className='text-dark fw-bold my-5'>Our Head Office</h1>

                <div className='text-gray-700 fs-3 fw-semibold'>
                  5/51, Rachna Colony, sector5, vaishali, Ghaziabad, Uttar Pradesh 201010
                </div>
              </div>
            </div>
          </div>

            {/* <div className='col-md-6 ps-lg-10'>
              <iframe
                src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7003.073572886707!2d77.3401701930107!3d28.643641952634493!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cface333187f3%3A0x82a3ea59833bc60c!2sSector%205%2C%20Vaishali%2C%20Ghaziabad%2C%20Uttar%20Pradesh!5e0!3m2!1sen!2sin!4v1688555419956!5m2!1sen!2sin'
                className='w-100 rounded mb-2 mb-lg-0 mt-2'
                style={{height: '486px'}}
              ></iframe>
            </div> */}
          </div>


          <div className='card bg-light text-center'>
            <div className='card-body py-5'>
              <a href='#' className='mx-4'>
                <img
                  src={toAbsoluteUrl('/media/avatars/facebook.png')}
                  className='h-30px my-2'
                  alt='img'
                />
              </a>

              <a href='#' className='mx-4'>
                <img
                  src={toAbsoluteUrl('/media/avatars/insta.jpg')}
                  className='h-30px my-2'
                  alt=''
                />
              </a>

              <a href='#' className='mx-4'>
                <img
                  src={toAbsoluteUrl('/media/avatars/twitter.png')}
                  className='h-30px my-2'
                  alt=''
                />
              </a>

              {/* <a href='#' className='mx-4'>
                <img
                  src='assets/media/svg/brand-logos/behance.svg'
                  className='h-30px my-2'
                  alt=''
                />
              </a>

              <a href='#' className='mx-4'>
                <img
                  src='assets/media/svg/brand-logos/pinterest-p.svg'
                  className='h-30px my-2'
                  alt=''
                />
              </a>

              <a href='#' className='mx-4'>
                <img
                  src='assets/media/svg/brand-logos/twitter.svg'
                  className='h-30px my-2'
                  alt=''
                />
              </a>

              <a href='#' className='mx-4'>
                <img
                  src='assets/media/svg/brand-logos/dribbble-icon-1.svg'
                  className='h-30px my-2'
                  alt=''
                />
              </a> */}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const ContactUsWrapper = () => {
  const intl = useIntl()
  console.log('useIntl>>laguage3', intl)
  return (
    <>
      <PageTitle breadcrumbs={dashboardBreadCrumbs}>
        {intl.formatMessage({id: 'MENU.DASHBOARD'})}
      </PageTitle>
      <Contactus />
    </>
  )
}

export {ContactUsWrapper}
