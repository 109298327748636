/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useState } from 'react'
import { KTSVG, toAbsoluteUrl } from '../../../helpers'
import * as Yup from 'yup'
import clsx from 'clsx'
import { invite, createfolder, getfolderprojects } from '../../../../app/modules/auth/core/_requests'
import { useNavigate } from 'react-router-dom'

import { useFormik } from 'formik'

import { Logout, AuthPage, useAuth } from '../../../../app/modules/auth/index'
import { Modal } from 'react-bootstrap'
import internal from 'stream'
import { Value } from 'sass'

const CFSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Name is required'),
})

const initialValues = {
  name: '',
}

interface props {
  mainvalue: boolean
  setCreateFolder: any
  comesfrom: any



}
const CreateFolder: FC<props> = ({ mainvalue, setCreateFolder, comesfrom }: any) => {
  const [loading, setLoading] = useState(false)
  const { currentUser, setfolder } = useAuth()
  const navigate = useNavigate()


  const formik = useFormik({
    initialValues,
    validationSchema: CFSchema,
    onSubmit: async (values, { setStatus, setSubmitting,resetForm }) => {
      setLoading(true)
      try {
        const tk = localStorage.getItem('kt-auth-react-v')
        const { data: msg } = await createfolder(values.name, currentUser?.company_name || '')
        const { data: folder } = await getfolderprojects(currentUser?.company_name || '');
        setfolder(folder)
        if (msg.hassucceeded == true) {
          if(comesfrom === "newProject"){
            setStatus(msg.message)
          setCreateFolder(!mainvalue)
          resetForm(); // Reset the form to initial values
          setSubmitting(false)
          setLoading(false)
              navigate(`/dashboard/?folderid=${msg.folderid}`)
          }
          else{
            setStatus(msg.message)
          // setCreateFolder(!mainvalue)
          setSubmitting(false)
          setLoading(false)
          setTimeout(() => {
            window.location.reload();
          }, 200)
          }
        }
        else {
          setStatus(msg.message)
          setSubmitting(false)
          setLoading(false)
        }
      } catch (error) {
        console.error(error)
        setStatus('The detail is incorrect')
        setSubmitting(false)
        setLoading(false)
      }
    },
  })
  return (
    <Modal
      aria-hidden='true'
      dialogClassName='modal-lg'
      show={mainvalue}
      scrollable={true}
      animation={true}
      fullscreen="xxl-down"
      className='createNewModal'
    >
      <div className='modal-content'>
        <div className='modal-header'>
          <div className='modal-title'>
            <h2>Create Folder</h2>
            <h6 className='text-gray-400 fw500'>Organizing your projects using folders is an excellent approach.</h6>
          </div>

          <div
            className='btn btn-icon btn-sm btn-active-light-primary ms-2'
            onClick={() => setCreateFolder(!mainvalue)}
          >
            <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-2x' />
          </div>
        </div>
        <form className='form w-100 scroll-y'
          onSubmit={formik.handleSubmit}
          noValidate
          id='kt_login_signin_form'>
          <div className='modal-body'>



          {formik.status && (
              <div
                className={`mb-lg-15 alert alert-${formik.status.includes('Success') ? 'success' : 'danger'
                  }`}
              >
                <div className='alert-text font-weight-bold'>{formik.status}</div>
              </div>
            )}

            <div className='mt-5'>
              <div className="row align-items-center justify-content-center">
                <div className="col-sm-12 col-lg-10 col-mg-11">
                  <div className='createProjContainer'>
                    <i className="bi-folder-plus svg-icon-2 svg-icon-primary me-0 me-md-2 fs26 projectfieldIcon"></i>
                    <input
                      placeholder="Please enter the name for the folder"
                      {...formik.getFieldProps('name')}
                      className={clsx(
                        'form-control bg-transparent',
                        { 'is-invalid': formik.touched.name && formik.errors.name },
                        {
                          'is-valid': formik.touched.name && !formik.errors.name,
                        }
                      )}
                      type='text'
                      name='name'
                      autoComplete='off'
                    // type="text" placeholder="name" name="name" autoComplete="off" className="form-control bg-transparent"
                    />
                    {formik.touched.name && formik.errors.name && (
                      <div className='fv-plugins-message-container formError'>
                        <span role='alert'>{formik.errors.name}</span>
                      </div>
                    )}

                  </div>
                </div>
                <div className="col-sm-12 col-lg-10 col-mg-11 allCheckedSec">
                  <div className="d-flex align-items-center-between">
                    <div className='py-6 my-6 w bg-gray-100 workSpaceText py-4 px-6'>
                      <label className='form-check form-switch form-switch-sm form-check-custom form-check-solid flex-stack'>
                        <div>
                          <i className="bi-share  me-0 me-md-2 fs18 me-2"></i>
                          <span className='form-check-label text-gray-700 fs-6 fw-bold ms-0 me-2'>
                            Collaborate with team members by sharing
                          </span>
                        </div>
                        <input className='form-check-input' type='checkbox' value='1' defaultChecked />
                      </label>
                    </div>
                  </div>
                </div>

                <div className="col-sm-12 col-lg-10 col-mg-11">
                  <div className="bg-gray-100 workSpaceText mb-10">
                    <div className="d-flex align-items-center py-4 px-6">
                      <i className="bi-building me-0 me-md-2 fs26 me-2"></i>
                      <p className='fs-14 m-0 fs14'>All members, including viewers, within <span className='fw600'>Ruchi Agrawal's workspace</span> have access to this folder.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='modal-footer'>
              <button type='button' className='btn btn-light-primary'
                onClick={() => setCreateFolder(!mainvalue)}
              >
                Go to Home
              </button>
              <button id='submit' type='submit' className='btn btn-primary'
                disabled={formik.isSubmitting || !formik.isValid}

              >
                {!loading && <span className='indicator-label'>Create Folder</span>}
                {loading && (
                  <span className='indicator-progress' style={{ display: 'block' }}>
                    Please wait...
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
        </form>
      </div>
    </Modal>
  )
}

export { CreateFolder }
