import React, {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import {resetPassword} from '../core/_requests'
import {useNavigate, useLocation} from 'react-router-dom'
import { InfoLinkModal } from '../../../../_metronic/partials/modals/terms-plan-contact-modal/InfoLinkModal';

const initialValues = {
  password: '',
  confirmPassword: '',
}

const forgotPasswordSchema = Yup.object().shape({
  password: Yup.string().required('Password is required'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required('Confirm Password is required'),
})

export function ResetPassword() {
  const [isOpenTerms, setIsOpenTerms] = useState(false);
  const [isOpenPlans,setIsOpenPlans] = useState(false);
  const [isOpenContactUs,setIsOpenContactUs] = useState(false);

  const [loading, setLoading] = useState(false)
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const location = useLocation()
  const navigate = useNavigate()

  const [passwordType, setPasswordType] = useState('password')
  const [showPassword, setShowPassword] = useState('password')

  const queryParams = new URLSearchParams(location.search)
  const paramsArray = Array.from(queryParams.entries())
  const numParams = paramsArray.length
  // URL has parameters
  var tokenFromURL = queryParams.get('token')

  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setHasErrors(undefined)

      try {
        const {data: msg} = await resetPassword(tokenFromURL || '', values.confirmPassword)
        if (msg.hassucceeded == true) {
          setStatus(msg.message)
          setSubmitting(false)
          setLoading(false)
          setTimeout(() => {
            navigate('/login')
          }, 1000)
        } else {
          setStatus(msg.message)
          setSubmitting(false)
          setLoading(false)
        }
      } catch (error) {
        console.error(error)
        setStatus('The detail is incorrect')
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  const togglePassword = (e: any) => {
    e.preventDefault()
    if (passwordType === 'password') {
      setPasswordType('text')
      return
    }
    setPasswordType('password')
  }

  const togglePasswordsec = (e: any) => {
    e.preventDefault()
    if (showPassword === 'password') {
      setShowPassword('text')
      return
    }
    setShowPassword('password')
  }

  return (
    <>
      <div className='d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-2 order-lg-1 loginResponse'>
        <div className='d-flex flex-center flex-column flex-lg-row-fluid loginResponse'>
          <div className='w-lg-500px p-10 managePading'>
            <form
              className='form w-100'
              id='kt_password_reset_form'
              onSubmit={formik.handleSubmit}
              noValidate
            >
              <div className='text-center mb-10'>
                <h1 className='text-dark fw-bolder mb-3'>Reset Password ?</h1>
                <div className='text-gray-500 fw-semibold fs-6'>
                  Enter your new password and confirm password.
                </div>
              </div>

              {formik.status && (
                <div className='mb-lg-15 alert alert-success'>
                  <div className='alert-text font-weight-bold'>{formik.status}</div>
                </div>
              )}

              <div className='row allLoginForm'>
                <div className='fv-row mb-8 col-sm-6 col-md-6 col-lg-12'>
                  <div className='resetpassword setpassword'>
                    <p className='mb-2'>New Password</p>
                    <input
                      placeholder='New Password'
                      {...formik.getFieldProps('password')}
                      className={clsx(
                        'form-control bg-transparent',
                        {'is-invalid': formik.touched.password && formik.errors.password},
                        {
                          'is-valid': formik.touched.password && !formik.errors.password,
                        }
                      )}
                      type={passwordType}
                      name='password'
                      autoComplete='off'
                      // type="text" placeholder="Email" name="email" autoComplete="off" className="form-control bg-transparent"
                    />

                    <div className='passbutton '>
                      <button className='btn' onClick={togglePassword}>
                        {passwordType === 'password' ? (
                          <i className='bi bi-eye-slash  '></i>
                        ) : (
                          <i className='bi bi-eye '></i>
                        )}
                      </button>
                    </div>
                  </div>
                  {formik.touched.password && formik.errors.password && (
                    <div className='fv-plugins-message-container'>
                      <span role='alert'>{formik.errors.password}</span>
                    </div>
                  )}
                </div>

                <div className='fv-row mb-8 col-sm-6 col-md-6 col-lg-12'>
                  <div className='resetpassword setpassword'>
                    <p className='mb-2'>Confirm Password</p>
                    <input
                      placeholder='Confirm Password'
                      {...formik.getFieldProps('confirmPassword')}
                      className={clsx(
                        'form-control bg-transparent',
                        {
                          'is-invalid':
                            formik.touched.confirmPassword && formik.errors.confirmPassword,
                        },
                        {
                          'is-valid':
                            formik.touched.confirmPassword && !formik.errors.confirmPassword,
                        }
                      )}
                      type={showPassword}
                      name='confirmPassword'
                      autoComplete='off'
                      // type="text" placeholder="Email" name="confirmPassword" autoComplete="off" className="form-control bg-transparent"
                    />
                    <div className='passbutton'>
                      <button className='btn' onClick={togglePasswordsec}>
                        {showPassword === 'password' ? (
                          <i className='bi bi-eye-slash'></i>
                        ) : (
                          <i className='bi bi-eye'></i>
                        )}
                      </button>
                    </div>
                  </div>
                  {formik.touched.confirmPassword && formik.errors.confirmPassword && (
                    <div className='fv-plugins-message-container'>
                      <span role='alert'>{formik.errors.confirmPassword}</span>
                    </div>
                  )}
                </div>
              </div>

              <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
                <button
                  type='submit'
                  id='kt_password_reset_submit'
                  className='btn btn-lg btn-primary fw-bolder me-4'
                >
                  <span className='indicator-label'>Submit</span>
                  {loading && (
                    <span className='indicator-progress'>
                      Please wait...
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
                <Link to='/auth/login'>
                  <button
                    type='button'
                    id='kt_login_password_reset_form_cancel_button'
                    className='btn btn-lg btn-light-primary fw-bolder'
                    disabled={formik.isSubmitting || !formik.isValid}
                  >
                    Cancel
                  </button>
                </Link>{' '}
              </div>
            </form>
          </div>
        </div>

        <div className='w-lg-500px d-flex flex-stack px-10 mx-auto managePading'>
          <div className='me-10'>
            <button
              className='btn btn-flex btn-link btn-color-gray-700 btn-active-color-primary rotate fs-base'
              data-kt-menu-trigger='click'
              data-kt-menu-placement='bottom-start'
              data-kt-menu-offset='0px, 0px'
            >
              <img
                data-kt-element='current-lang-flag'
                className='w-20px h-20px rounded me-3'
                src='assets/media/flags/united-states.svg'
                alt=''
              />
              <span data-kt-element='current-lang-name' className='me-1'>
                English
              </span>
              <i className='ki-duotone ki-down fs-5 text-muted rotate-180 m-0'></i>
            </button>

            <div
              className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold w-200px py-4 fs-7'
              data-kt-menu='true'
              id='kt_auth_lang_menu'
            >
              <div className='menu-item px-3'>
                <a href='#' className='menu-link d-flex px-5' data-kt-lang='English'>
                  <span className='symbol symbol-20px me-4'>
                    <img
                      data-kt-element='lang-flag'
                      className='rounded-1'
                      src='assets/media/flags/united-states.svg'
                      alt=''
                    />
                  </span>
                  <span data-kt-element='lang-name'>English</span>
                </a>
              </div>

              <div className='menu-item px-3'>
                <a href='#' className='menu-link d-flex px-5' data-kt-lang='Spanish'>
                  <span className='symbol symbol-20px me-4'>
                    <img
                      data-kt-element='lang-flag'
                      className='rounded-1'
                      src='assets/media/flags/spain.svg'
                      alt=''
                    />
                  </span>
                  <span data-kt-element='lang-name'>Spanish</span>
                </a>
              </div>

              <div className='menu-item px-3'>
                <a href='#' className='menu-link d-flex px-5' data-kt-lang='German'>
                  <span className='symbol symbol-20px me-4'>
                    <img
                      data-kt-element='lang-flag'
                      className='rounded-1'
                      src='assets/media/flags/germany.svg'
                      alt=''
                    />
                  </span>
                  <span data-kt-element='lang-name'>German</span>
                </a>
              </div>

              <div className='menu-item px-3'>
                <a href='#' className='menu-link d-flex px-5' data-kt-lang='Japanese'>
                  <span className='symbol symbol-20px me-4'>
                    <img
                      data-kt-element='lang-flag'
                      className='rounded-1'
                      src='assets/media/flags/japan.svg'
                      alt=''
                    />
                  </span>
                  <span data-kt-element='lang-name'>Japanese</span>
                </a>
              </div>

              <div className='menu-item px-3'>
                <a href='#' className='menu-link d-flex px-5' data-kt-lang='French'>
                  <span className='symbol symbol-20px me-4'>
                    <img
                      data-kt-element='lang-flag'
                      className='rounded-1'
                      src='assets/media/flags/france.svg'
                      alt=''
                    />
                  </span>
                  <span data-kt-element='lang-name'>French</span>
                </a>
              </div>
            </div>
          </div>

          <div className='d-flex fw-semibold text-primary fs-base gap-5'>
            <a href='#' onClick={() => setIsOpenTerms(true)}>
              Terms
            </a>
            <a href='#' onClick={() => setIsOpenPlans(true)}>
              Plans
            </a>
            <a href='#'  onClick={() => setIsOpenContactUs(true)}>
              Contact Us
            </a>
          </div>
        </div>
        <InfoLinkModal
        isOpenTerms={isOpenTerms}
        setIsOpenTerms={setIsOpenTerms}
        isOpenPlans={isOpenPlans}
        setIsOpenPlans={setIsOpenPlans}
        isOpenContactUs={isOpenContactUs}
        setIsOpenContactUs={setIsOpenContactUs}
      />
      </div>
      {/* <form
        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
        noValidate
        id='kt_login_password_reset_form'
        onSubmit={formik.handleSubmit}
      >
        <div className='text-center mb-10'>
       
          <h1 className='text-dark mb-3'>Forgot Password ?</h1>
         
          <div className='text-gray-400 fw-bold fs-4'>Enter your email to reset your password.</div>
       
        </div>


        {hasErrors === true && (
          <div className='mb-lg-15 alert alert-danger'>
            <div className='alert-text font-weight-bold'>
              Sorry, looks like there are some errors detected, please try again.
            </div>
          </div>
        )}

        {hasErrors === false && (
          <div className='mb-10 bg-light-info p-8 rounded'>
            <div className='text-info'>Sent password reset. Please check your email</div>
          </div>
        )}
      
        <div className='fv-row mb-10'>
          <label className='form-label fw-bolder text-gray-900 fs-6'>Email</label>
          <input
            type='email'
            placeholder=''
            autoComplete='off'
            {...formik.getFieldProps('email')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {'is-invalid': formik.touched.email && formik.errors.email},
              {
                'is-valid': formik.touched.email && !formik.errors.email,
              }
            )}
          />
          {formik.touched.email && formik.errors.email && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.email}</span>
              </div>
            </div>
          )}
        </div>
    

   
        <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
          <button
            type='submit'
            id='kt_password_reset_submit'
            className='btn btn-lg btn-primary fw-bolder me-4'
          >
            <span className='indicator-label'>Submit</span>
            {loading && (
              <span className='indicator-progress'>
                Please wait...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
          <Link to='/auth/login'>
            <button
              type='button'
              id='kt_login_password_reset_form_cancel_button'
              className='btn btn-lg btn-light-primary fw-bolder'
              disabled={formik.isSubmitting || !formik.isValid}
            >
              Cancel
            </button>
          </Link>{' '}
        </div>
       
      </form> */}
    </>
  )
}
